import React, {useContext} from 'react';
import {OverviewMapContext} from '../../contexts/OverviewMapContext';
import './overview-map.css';

const ExpertAnnotationCard = ({annotation, i}) => {
  const {expertAnnotationImages} = useContext(OverviewMapContext);
  let color;

  switch (annotation.label) {
    case 'trips':
      color = 'red';
      break;
    case 'healthy':
      color = 'grey';
      break;
    default:
      color = 'grey';
  }

  return (
    <div key={i} className='expert-annotation-card'
      style={{backgroundColor: color}}>
      <span style={{fontWeight: 'bold', fontSize: 14}}>{annotation.label.toUpperCase()}</span>
      <div className='expert-annotation-image-container'>
        {expertAnnotationImages ?
        <img src={expertAnnotationImages[annotation._id] || 'https://via.placeholder.com/150'}
          alt='annotation' className='expert-annotation-image'/> :
       <div className='snapshot-image-loader'></div>}
      </div>
      <span style={{padding: 10, textDecoration: 'underline'}}>{annotation._id}</span>
    </div>
  );
};

export default ExpertAnnotationCard;
