import * as React from 'react';
import {Snackbar} from '@material-ui/core';
import {CartContext} from './CartContext';
import {useContext} from 'react';
import {Alert} from '@mui/material';

export default function ShoppingCartDialog() {
  const {showAlreadyInCartDialog, closeDialog} = useContext(CartContext);

  return (
    <div>
      <Snackbar
        open={showAlreadyInCartDialog}
        autoHideDuration={5000}
        onClose={closeDialog}
      >
        <Alert onClose={closeDialog} severity="warning" sx={{width: '100%'}}>
                    Already in cart!
        </Alert>
      </Snackbar>
    </div>
  );
}
