import React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'columns',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
  },
  toggles: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    height: '40px',
  },
}));

const ToggleMapType = ({mapType, handleMapTypeChange}) => {
  const classes = useStyles();
  return (
    <ToggleButtonGroup
      color="primary"
      value={mapType}
      exclusive
      onChange={handleMapTypeChange}
      aria-label="Platform"
      className={classes.toggles}
    >
      <ToggleButton value="snapshots" style={{width: '100%'}}>
            Snapshots
      </ToggleButton>
      <ToggleButton value="annotations" style={{width: '100%'}}>
              Annotations
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ToggleMapType;
