import {useContext, useState} from 'react';
import {Card, CardContent, Button, TextField, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {v4 as uuidv4} from 'uuid';
import {RegularSpacer} from '../BasicComponents/Spacers';
import {UserContext} from '../../contexts/userContext';
import {ApiClient} from '../../Utils/ApiClient';
import ClientPicker from '../UserInput/ClientPicker';


const ImageUploadPage = () => {
  const {t} = useTranslation();
  const [displayText, setDisplayText] = useState('');
  const [batch, setBatch] = useState('');
  const [file, setFile] = useState(null);
  const [currentClient, setCurrentClient] = useState('');
  const [crop, setCrop] = useState('Strelitzia');
  const {user} = useContext(UserContext);
  const [clientErrorMessage, setClientErrorMessage] = useState('');
  const [imageErrorMessage, setImageErrorMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setFile(file);
    setDisplayText('Selected ' + file.name);
  };


  const handleSubmit = async () => {
    setIsSubmitted(true);
    if (!file) {
      setImageErrorMessage('Please select an image file');
    } else {
      setImageErrorMessage('');
    }
    if (!currentClient) {
      setClientErrorMessage('Please select a client');
    } else {
      setClientErrorMessage('');
    }

    if (!file || !currentClient || !batch || !crop) {
      return;
    }
    setSuccessMessage('');
    setErrorMessage('');

    try {
      const snapshotId = uuidv4();
      await uploadImage(snapshotId, file);
      await createSnapshot(snapshotId, batch);
      setBatch('');
      setFile(null);
      setDisplayText('');
      setSuccessMessage('Snapshot uploaded successfully!');
      setIsSubmitted(false);
    } catch (error) {
      setErrorMessage('Error uploading snapshot: ' + error.message);
    }
  };

  const uploadImage = async (snapshotId, file) => {
    console.log(file);
    const imageUrl = snapshotId + '/HighRes.jpg';
    const imageResult = await ApiClient.uploadImage(currentClient, imageUrl, file);
    console.log(imageResult);
  };

  const createSnapshot = async (snapshotId, batch) => {
    const snapshot = {
      '_id': snapshotId,
      'client': currentClient,
      'device': 'WebApp',
      'collection_date': new Date().toISOString(),
      'status': 'created',
      'system_version': '2024-11-18T00:00:00.000Z',
      'author': currentClient,
      'data': {
        'images': {
          'rawHD': snapshotId + '/HighRes.jpg',
        },
        'batch_id': batch,
        'crop': crop,
      },
    };
    console.log(snapshot);
    return ApiClient.createSnapshot(snapshot);
  };

  return (
    <div style={{'width': '70%', 'maxWidth': '750px'}}>
      <Card>
        <CardContent>
          <h2>{t('Upload Snapshot')}</h2>
          <ClientPicker clients={user.clients}
            label='Client*'
            useUserContext={true}
            defaultClient={currentClient}
            onClientChanged={setCurrentClient}
            triggerOnEmpty={true}
            hideCard={true}/>
          {clientErrorMessage && (
            <Typography style={{color: 'red', marginTop: '1rem'}}>
              {clientErrorMessage}
            </Typography>
          )}
          <RegularSpacer/>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={crop}
            onChange={(e) => setCrop(e.target.value)}
            name="crop"
            label={t('Crop')}
            type="text"
            id="crop"
            error={isSubmitted && !crop}
            helperText={isSubmitted && !crop ? 'Crop is required' : ''}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={batch}
            onChange={(e) => setBatch(e.target.value)}
            name="batch id"
            label={t('Batch ID')}
            type="text"
            id="batch"
            error={isSubmitted && !batch}
            helperText={isSubmitted && !batch ? 'Batch ID is required' : ''}
          />
          <RegularSpacer/>
          <input
            accept="image/*"
            type="file"
            capture="environment"
            onChange={handleFileUpload}
            style={{display: 'none'}}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <Button variant="outlined" color="primary" component="span">
            Select image file
            </Button>
            {' ' + displayText}
          </label>
          {imageErrorMessage && (
            <Typography style={{color: 'red', marginTop: '1rem'}}>
              {imageErrorMessage}
            </Typography>
          )}
          <RegularSpacer size={5}/>


          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
          {errorMessage && (
            <Typography style={{color: 'red', marginTop: '1rem'}}>
              {errorMessage}
            </Typography>
          )}
          {successMessage && (
            <Typography style={{color: 'green', marginTop: '1rem'}}>
              {successMessage}
            </Typography>
          )}

          <RegularSpacer/>
        </CardContent>
      </Card>
    </div>
  );
};

export default ImageUploadPage;
