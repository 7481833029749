import React, {useContext} from 'react';
import {Card, CardContent, Typography, InputLabel, Accordion, AccordionDetails,
  AccordionSummary} from '@material-ui/core';
import {ExpandMore} from '@mui/icons-material';
import {makeStyles} from '@material-ui/core/styles';
import {UserContext} from '../../contexts/userContext';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'left',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(2),
  },
  loghsCard: {
    marginTop: theme.spacing(2),
  },
}));

// StatusLog component
/**
 * @param {Object} statusLog - statusLog object
 * @return {React.ReactElement} StatusLog component
 * */

const StatusLog = ({statusLog}) => {
  const classes = useStyles();
  const {currentClient, getUserConfig} = useContext(UserContext);

  if (!statusLog || !currentClient) {
    return <div></div>;
  }

  const hasLane = getUserConfig(true, currentClient).has_lane;
  const containerType = hasLane ? 'Lane' : 'Tray';

  // get the dates from the statusLog object
  const updated = new Date(statusLog.updatedAt);
  const estimated = new Date(statusLog.estimated_upload_complete_datetime);

  // format the date to YYYY-MM-DD HH:MM
  const formatUTCDateTime = (date) => {
    return `${date.getUTCFullYear()}-${('0' +
    (date.getUTCMonth() + 1)).slice(-2)}-${('0' + date.getUTCDate()).slice(-2)} ` +
      `${('0' + date.getUTCHours()).slice(-2)}:${('0' + date.getUTCMinutes()).slice(-2)}`;
  };

  const formatLocalDateTime = (date) => {
    return `${date.getFullYear()}-${('0' +
    (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)} ` +
      `${('0' + date.getHours()).slice(-2)}:${('0' + date.getMinutes()).slice(-2)}`;
  };

  return (
    <div>
      <Card className={classes.container}>
        <CardContent>
          <InputLabel className={classes.label}>General Device Stats</InputLabel>
          <Typography><b>Last Updated (UTC):</b> {formatUTCDateTime(updated) + ' '}
            (Local Time: {formatLocalDateTime(updated)})</Typography>
          <Typography><b>Upload Percentage:</b> {statusLog.upload_percentage.toFixed(2)}</Typography>
          <Typography><b>Total Data:</b> {statusLog.total_data}</Typography>
          <Typography><b>Success Upload:</b> {statusLog.success_upload}</Typography>
          <Typography><b>Failed Upload:</b> {statusLog.failed_upload}</Typography>
          <Typography><b>Upload Rate (sec/upload):</b> {statusLog.upload_rate.toFixed(2)}</Typography>
          <Typography><b>Estimated Upload Complete Datetime (UTC):</b>
            {' ' + formatUTCDateTime(estimated)+ ' '}(Local Time: {formatLocalDateTime(estimated)})</Typography>
        </CardContent>
      </Card>
      <Card className={classes.logsCard}>
        <CardContent>
          <InputLabel className={classes.label}>Logs</InputLabel>
          {Object.keys(statusLog.per_lane_status).map((key) => {
            return (
              <Accordion key={key}>
                <AccordionSummary expandIcon={<ExpandMore/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"><b>{key}</b></AccordionSummary>

                {Object.keys(statusLog.per_lane_status[key]).map((key2) => {
                  return (
                    <Accordion key={key}>
                      <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>{containerType} {key2}</Typography>
                      </AccordionSummary>
                      <AccordionDetails style={{flexDirection: 'column'}}>
                        <Typography><b>Total Captured:</b>&nbsp;
                          {statusLog.per_lane_status[key][key2].total_captured}</Typography>
                        <Typography><b>Total Uploaded:</b>&nbsp;
                          {statusLog.per_lane_status[key][key2].total_uploaded}</Typography>
                        <Typography><b>Total Failed:</b>&nbsp;
                          {statusLog.per_lane_status[key][key2].total_failed}</Typography>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </Accordion>
            );
          })}
        </CardContent>
      </Card>
    </div>
  );
};

export default StatusLog;
