import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import {Button, CardContent, FormControl, Grid, InputLabel, Select, TextField} from '@material-ui/core';
import {RegularSpacer} from '../BasicComponents/Spacers';
import {CenterWrapper} from '../StyledComponents/BasicStyles';
import {useTranslation} from 'react-i18next';
import {DATABASE_DOCUMENTS, getDocuments} from './DocumentService';
import DatabaseDocumentCard from './DatabaseDocumentCard';
import {useLocation} from 'react-router-dom/cjs/react-router-dom';

const possibleTables = Object.values(DATABASE_DOCUMENTS).map((item) => item.name);

function DatabaseDocumentsPage() {
  const [selectedTable, setSelectedTable] = useState(undefined);
  const [query, setQuery] = useState('{}');
  const [data, setData] = useState([]);
  const [visibleData, setVisibleData] = useState([]);
  const [filterTitle, setFilterTitle] = useState('');
  const [invalidQuery, setInvalidQuery] = useState(false);
  const [invalidDocumentTable, setInvalidDocumentTable] = useState(false);
  const {search} = useLocation();
  const searchParams = new URLSearchParams(search);
  const {t} = useTranslation();

  useEffect(() => {
    loadURLParameters();
  }, [search]);

  useEffect(() => {
    if (filterTitle === '') {
      setVisibleData(data);
      return;
    }
    setVisibleData(data.filter((item) => item.title.toLowerCase().includes(filterTitle.toLowerCase())));
  }, [filterTitle, data]);

  const loadURLParameters = async () => {
    const queryInput = searchParams.get('query');
    const selectedTableInput = searchParams.get('selectedTable');
    let newInput = false;
    if (queryInput && queryInput !== query) {
      setQuery(queryInput);
      newInput = true;
    }
    if (selectedTableInput && selectedTableInput !== selectedTable) {
      setSelectedTable(selectedTableInput);
      newInput = true;
    }
    if (queryInput && selectedTableInput && newInput) {
      submitQuery(selectedTableInput, queryInput);
    }
  };

  const setQueryParameters = (documentTable, documentQuery) => {
    searchParams.set('selectedTable', documentTable);
    searchParams.set('query', documentQuery);
    const url = new URL(window.location.href);
    url.search = searchParams.toString();
    window.history.replaceState({}, '', url.toString());
  };

  const submitQuery = async (documentTable, documentQuery) => {
    try {
      JSON.parse(documentQuery);
    } catch (e) {
      setInvalidQuery(true);
      return;
    }
    setInvalidQuery(false);

    if (!documentTable) {
      setInvalidDocumentTable(true);
      return;
    }
    setInvalidDocumentTable(false);

    setInvalidQuery(false);
    setQueryParameters(documentTable, documentQuery);
    const documents = await getDocuments(documentTable, documentQuery);
    setData(documents);
  };


  return (
    <CenterWrapper width={1200} >
      <Grid container spacing={5}>
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <FormControl fullWidth>
                <InputLabel htmlFor="client-native-simple">Table</InputLabel>
                <Select
                  error={invalidDocumentTable}
                  native
                  value={selectedTable}
                  onChange={(event) => setSelectedTable(event.target.value)}
                >
                  <option aria-label="None" value="" />
                  {Object.values(possibleTables).map((item) => (
                    <option key={item} value={item}>{item}</option>
                  ))}
                </Select>
                <RegularSpacer size={2}/>
                <TextField fullWidth label="Query" variant="outlined" error={invalidQuery}
                  value={query} onChange={(e) => setQuery(e.target.value)} multiline />
                <RegularSpacer size={3}/>
                <Button color="primary" variant="contained"
                  onClick={() => submitQuery(selectedTable, query)}>
                  {t('Submit')}
                </Button>
              </FormControl>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} >
          <Card>
            <CardContent>
              <FormControl fullWidth>
                <h3>Filter</h3>
                <TextField fullWidth label="Title" variant="outlined"
                  value={filterTitle} onChange={(e) => setFilterTitle(e.target.value)} />
              </FormControl>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={8}>
          {visibleData.slice(0, 50).map((item) => (
            <DatabaseDocumentCard key={item.id} item={item} />
          ))}
          {visibleData.length > 50 ? 'Only showing the first 50 results..': ''}
        </Grid>
      </Grid>
    </CenterWrapper>
  );
};

export default DatabaseDocumentsPage;
