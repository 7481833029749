import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {withTranslation} from 'react-i18next';

class RedirectDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {open: false, setOpen: false};
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.open !== prevState.open) {
      this.setState({open: this.props.open});
    }
  }

  render() {
    const {t} = this.props;
    return (
      <div>
        <Dialog
          open={this.state.open}
          onClose={this.props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t('Password changed!')}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t('The login screen will now open, use your NEW password to login.')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.handleClose()} color="primary">
                            Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withTranslation()(RedirectDialog);
