import React, {useState, useEffect} from 'react';
import {Footer, Header} from './Components/Layouts';
import {Route} from 'react-router-dom';
import {ThemeProvider} from '@material-ui/core/styles';
import {createTheme} from '@material-ui/core';
import Login from './Components/Login';
import {zhCN} from '@material-ui/core/locale';
import {useHistory, useLocation} from 'react-router-dom/cjs/react-router-dom';
import {amber, red} from '@material-ui/core/colors';
import {ApiClient} from './Utils/ApiClient';
import {makeStyles} from '@material-ui/styles';
import {CartProvider} from './Components/ShoppingCart/CartContext';
import ShoppingCartDialog from './Components/ShoppingCart/ShoppingCartDialog';
import {UserProvider} from './contexts/userContext';
import {useContext} from 'react';
import Routing from './Routing';
import {CredentialsContext} from './contexts/credentialsContext';
import LoggedOutHeader, {ServerUrls} from './Components/Layouts/LoggedOutHeader';

const useStyles = makeStyles((theme) => ({
  app: {
    display: 'flex',
    flexFlow: 'column',
    minHeight: '100%',
    margin: 0,
    background: '#EEEEEE',
  },
  div: {
    flex: '1 1 auto',
  },
  pageWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#23A455',
      contrastText: '#000000',
    },
    secondary: {
      main: '#7c22a3',
      contrastText: '#ffffff',
    },
    failure: {
      main: red[300],
      subtle: red[100],
      hover: red[200],
    },
    disease: {
      main: amber[300],
      subtle: amber[100],
      hover: amber[200],
    },
    green: {
      main: '#23A455',
    },
    blue: {
      main: '#2384a4',
    },
    red: {
      main: '#a42323',
    },
    purple: {
      main: '#9323a4',
    },
    gray: {
      main: '#a3a3a3',
    },
    orange: {
      main: '#ffa500',
      subtle: '#ffcc80',
      hover: '#ff9100',
    },
  },
}, zhCN);

function App() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [adiTheme, setAdiTheme] = useState(defaultTheme);
  const {baseUrl, loggedInUser} = useContext(CredentialsContext);

  useEffect(() => {
    updateThemeColor(baseUrl);
  }, []);

  useEffect(() => {
    ApiClient.setHistory(history);
  }, [history]);

  useEffect(() => {
    updateThemeColor(baseUrl);
  }, [baseUrl]);

  const updateThemeColor = (url) => {
    if (url === ServerUrls.TEST) {
      changePrimaryColor('#2384a4');
    } else if (url === ServerUrls.LOCAL) {
      changePrimaryColor('#a39022');
    } else {
      changePrimaryColor('#23A455');
    }
  };

  const changePrimaryColor = (primaryColor) => {
    const updatedTheme = {...adiTheme};
    updatedTheme.palette.primary.main = primaryColor;
    setAdiTheme(updatedTheme);
  };

  const getWholeApp = () => {
    return (
      <UserProvider user={loggedInUser}>
        <CartProvider>
          <div className={classes.app}>
            <Header/>
            {loggedInUser ?
              <span>
                <div className={classes.pageWrapper}>
                  <Routing/>
                </div>
                <div className={classes.div} />
                <Footer />
              </span> :
                <Route exact path='/login'>
                  <Login />
                </Route>
            }
            <ShoppingCartDialog />
          </div>
        </CartProvider>
      </UserProvider>);
  };

  const getLoginPage = () => {
    if (location.pathname !== '/login') {
      return '';
    }
    return (
      <div className={classes.app}>
        <LoggedOutHeader/>
        <Route exact path='/login'>
          <Login />
        </Route>
      </div>
    );
  };

  const pageReadyToLoad = () => {
    if (loggedInUser === null) {
      return false;
    }
    return true;
  };

  return (
    <ThemeProvider theme={adiTheme}>
      {pageReadyToLoad() ? getWholeApp() : getLoginPage() }
    </ThemeProvider>
  );
}
export default App;
