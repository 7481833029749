import React, {useEffect, useState, useContext} from 'react';
import {UserContext} from '../../contexts/userContext';
import {RootDiv} from '../Images/ImagePageStyles';
import Grid from '@material-ui/core/Grid';
import MapFilters from './MapFilters';
import OverviewMap from './OverviewMap';
import {OverviewMapProvider} from '../../contexts/OverviewMapContext';
import MapFilterChips from './MapFilterChips';
import './overview-map.css';
const OverviewMapPage = () => {
  const [userConfig, setUserConfig] = useState(null);
  const {getUserConfig, currentClient} = useContext(UserContext);

  useEffect(() => {
    if (currentClient) {
      setUserConfig(getUserConfig(currentClient));
    }
  }, [currentClient]);

  return (
    <>
      {userConfig &&
      <OverviewMapProvider userConfig={userConfig}>
        <RootDiv style={{padding: 20}}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <MapFilters/>
            </Grid>
            <Grid item xs={10}>
              <Grid container direction='columns'>
                <Grid item xs={12}>
                  <MapFilterChips/>
                </Grid>
                <Grid item xs={12} id='map-root'>
                  <OverviewMap/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </RootDiv>
      </OverviewMapProvider>
      }
    </>
  );
};

export default OverviewMapPage;
