import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from '@material-ui/styles';
import ReactJson from '@microlink/react-json-view';

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 100,
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));


function SnapshotDetailsCard(props) {
  const classes = useStyles();

  const replacer = (key, value) => {
    {
      if (props.shorten) {
        if (['plant_record_list', 'image_urls', 'images'].includes(key)) return undefined;
        else return value;
      }
      return value;
    }
  };


  return (
    <Card className={classes.card}>
      <CardContent>
        {props.snapshot ? <ReactJson src={JSON.parse(JSON.stringify(props.snapshot, replacer, 2))}
          theme="Hopscotch"/> : ''}
      </CardContent>
    </Card>
  );
}

export default SnapshotDetailsCard;
