import React, {useContext, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import PublicIcon from '@material-ui/icons/Public';
import MenuItem from '@material-ui/core/MenuItem';

import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {useTranslation} from 'react-i18next';
import {CredentialsContext} from '../../contexts/credentialsContext';

import {Link} from 'react-router-dom/cjs/react-router-dom';
import {AdiLogo} from './AdiLogo';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 5,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  urlMenu: {
    margin: 0,
    background: theme.palette.primary.main,
    maxWidth: 200,
    position: 'absolute',
    zIndex: 3,
    right: 0,
    padding: 10,
  },
}));

export const ServerUrls = {
  ACTUAL: 'https://server.adinnovations.nl/api',
  TEST: 'https://test-server.adinnovations.nl/api',
  LOCAL: 'http://localhost:8000/api',
};

const LoggedOutHeader = () => {
  const [languageKey, setLanguageKey] = useState('en');
  const [language, setLanguage] = useState('English');
  const [urlPickerOpen, setUrlPickerOpen] = useState(false);
  const {setAndStoreBaseUrl} = useContext(CredentialsContext);

  const {t} = useTranslation();
  const classes = useStyles();

  const handleClickUrl = (event) => {
    setUrlPickerOpen(true);
  };

  const handleCloseUrl = (url) => {
    setUrlPickerOpen(false);
    setAndStoreBaseUrl(url);
  };

  const handleClose = (event) => {
    setUrlPickerOpen(false);
  };

  const getServerList = () => {
    return (<div className={classes.urlMenu}>
      <ClickAwayListener onClickAway={handleClose}>
        <MenuList autoFocusItem={urlPickerOpen} id="menu-list-grow">
          <MenuItem onClick={() => handleCloseUrl(ServerUrls.ACTUAL)}>{t('Normal Server')}</MenuItem>
          <MenuItem onClick={() => handleCloseUrl(ServerUrls.TEST)} id="testing-server-button">{t('Testing')}</MenuItem>
          <MenuItem onClick={() => handleCloseUrl(ServerUrls.LOCAL)}>{t('Localhost')}</MenuItem>
        </MenuList>
      </ClickAwayListener>
    </div>);
  };


  const clickLanguage = () => {
    const currentLanguage = languageKey;
    let newLanguageKey = 'en';
    let newLanguage = 'English';
    if (currentLanguage === 'en') {
      newLanguageKey = 'nl';
      newLanguage = 'Nederlands';
    }
    setLanguage(newLanguage);
    setLanguageKey(newLanguageKey);
    // this.props.i18n.changeLanguage(newLanguageKey);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" id="logged-out-app-bar">
        <Toolbar>
          <div style={{flexGrow: 1}}>
            <Link to={{pathname: '/login'}}><AdiLogo /></Link>
          </div>
          <Button onClick={clickLanguage}>{language}</Button>
          <PublicIcon edge="start" className={classes.menuButton}
            color="inherit" aria-label="menu" onClick={handleClickUrl} id="switch-server-button"/>
        </Toolbar>
      </AppBar>
      {urlPickerOpen ? getServerList() : null}
    </div>
  );
};

export default LoggedOutHeader;
