import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {UserContext} from '../../contexts/userContext';
import {Card, CardContent, Typography, InputLabel, Select, MenuItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import BatchesMap from './BatchesMap';
import TrayBatchesMap from './TrayBatchesMap';
const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  label: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  dropDown: {
    width: '50%',
  },
}));

export default function BatchesPage() {
  const {currentClient, getUserConfig} = useContext(UserContext);
  const [userConfig, setUserConfig] = useState(UserContext);
  const [selectedGreenhouse, setSelectedGreenhouse] = useState(null);
  const history = useHistory();
  const classes = useStyles();
  useEffect(() => {
    history.push((`/${currentClient || 'admin'}/batches`));
    if (!currentClient) {
      return;
    }
    setSelectedGreenhouse(null);
    setUserConfig(getUserConfig(false, currentClient));
  }, [currentClient]);

  const handleGreenhouseChange = (event) => {
    setSelectedGreenhouse(event.target.value);
  };
  return (
    <Card className={classes.card}>
      <CardContent>
        {currentClient && userConfig && userConfig.greenhouse_stats?.greenhouses?.length > 0 ?
        <div className={classes.mapContainer}>
          <Typography variant="h5" component="h2">
            <b>Greenhouse Batches for {currentClient}</b>
          </Typography>
          <InputLabel id="label-select" className={classes.label}>Select Greenhouse</InputLabel>
          <Select
            value={selectedGreenhouse}
            label="select-greenhouse"
            onChange={handleGreenhouseChange}
            style={{width: '10%'}}
            variant='outlined'
          >
            {userConfig.greenhouse_stats.greenhouses.map((greenhouse, index) =>
              <MenuItem value={greenhouse} key={index}>{greenhouse}</MenuItem>,
            )}
          </Select>
          {selectedGreenhouse && userConfig.has_lane ?
           <BatchesMap greenhouse={selectedGreenhouse} greenhouseStats={userConfig.greenhouse_stats}
             client={currentClient}/> :
          <TrayBatchesMap greenhouse={selectedGreenhouse} greenhouseStats={userConfig.greenhouse_stats}/>
          }
        </div> :
          <Typography variant="h5" component="h2">
            <b>Select a valid client</b>
          </Typography>
        }
      </CardContent>
    </Card>
  );
};
