import {styled} from '@mui/material/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';

export const StyledForm = styled('form')(({theme}) => ({
  ...({
    display: 'flex',
    flexWrap: 'wrap',
  }),
}));

export const CenteredCardContent = styled(CardContent)(({maxWidth, theme}) => ({
  ...({
    maxWidth: maxWidth,
    textAlign: 'center',
  }),
}));

export const SplitCard = styled(Card)(({theme}) => ({
  ...({
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(2),
    backgroundColor: 'white',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    textAlign: 'center',
    width: '40%',
    border: '1px solid ' + theme.palette.success.dark,
    borderRadius: '8px',
    columnGap: '30px',
    boxShadow: '8px 10px 2px 0px rgba(72,196,136,0.17)',
  }),
}));

export const UserInputs = styled('div')(({theme}) => ({
  ...({
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    marginBottom: '40px',
  }),
}));

export const CenterWrapper = styled('div')(({theme, width = 1000}) => ({
  ...({
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    marginBottom: '40px',
    width: width,
  }),
}));

export const TopText = styled(Typography)(({theme}) => ({
  ...({
    alignSelf: 'center',
    marginTop: '30px',
  }),
}));

export const SubmitButton = styled(Button)(({theme}) => ({
  ...({
    border: '2px solid ' + theme.palette.primary.main,
    color: theme.palette.primary.main,
  }),
}));
