import {styled} from '@mui/material/styles';
import Typography from '@material-ui/core/Typography';
import {withTheme} from '@material-ui/core/styles';

export const PrimaryColorText = withTheme(styled(Typography)`
    color: ${(props) => props.theme.palette.primary.main}
`);

export const ErrorText = withTheme(styled(Typography)`
    color: ${(props) => props.theme.palette.failure.main}
`);
