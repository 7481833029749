import './AdiLogo.css';

/* eslint-disable max-len */
export const AdiLogo = (props) => (
  <svg viewBox="-784.093 -322.325 2749.449 754.971" preserveAspectRatio="xMinYMin meet" id='adi-logo-svg'>
    <g transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, -127.15604016649831, 110.79039331645936)">
      <path className="b" d="M -302.462 -296.689 C -344.039 -286.738 -385.025 -274.906 -424.369 -257.478 C -453.952 -244.382 -479.743 -228.434 -501.608 -209.554 L -338.15 -77.615 L -302.462 -296.689 Z"/>
      <path className="b" d="M -2.386 -426.289 C -2.951 -427.391 -4.025 -428.198 -5.344 -429.677 C -47.056 -372.825 -105.817 -344.237 -171.168 -326.971 C -202.042 -318.822 -233.319 -312.179 -264.515 -305.348 L -290.171 -147.968 L 50.917 -278.752 C 41.209 -327.994 23.325 -377.181 -2.386 -426.289 Z"/>
      <polygon className="b" points="-264.838 -18.451 -215.22 -38.216 -207.285 -89.772 -249.966 -124.732 -296.868 -106.741 -305.85 -51.556 -264.838 -18.451"/>
      <path className="b" d="M -560.933 78.097 C -560.933 78.097 -558.352 84.013 -551.71 95.873 L -303.269 -3.121 L -527.291 -183.953 C -560.746 -145.306 -581.184 -97.006 -587.665 -38.486 C -592.291 3.144 -561.903 79.038 -560.933 78.097 Z"/>
      <path className="b" d="M 56.646 -242.31 L -211.185 -139.604 L -1.229 32.351 C -0.879 31.706 -0.503 31.088 -0.153 30.415 C 49.114 -60.27 67.054 -151.33 56.646 -242.31 Z"/>
      <path className="b" d="M -215.247 199.413 C -134.351 176.795 -67.925 132.799 -20.324 63.386 L -174.907 -63.229 L -215.247 199.413 Z"/>
      <path className="b" d="M -532.804 127.258 C -570.615 165.097 -610.203 213.343 -639.247 260.514 C -644.814 269.55 -643.927 276.731 -638.737 284.342 C -627.737 300.398 -613.081 311.101 -595.734 318.281 C -588.93 321.106 -581.401 316.158 -581.131 308.762 C -579.14 255.835 -559.804 217.028 -525.031 181.744 C -513.521 170.072 -495.691 167.383 -481.276 175.155 C -463.526 184.756 -443.249 196.24 -431.335 200.165 C -372.868 219.529 -313.139 219.637 -253.112 208.234 L -253.086 208.234 L -221.567 3.118 L -532.857 127.178 C -532.857 127.178 -532.857 127.231 -532.804 127.258 Z"/>
    </g>
    <g transform="matrix(2.689337015151977, 0, 0, 2.689337015151977, -770.1963730512962, -318.8867282535647)">
      <path className="b" d="M373.55,92.87h-29.97l-4.8,14.19h-20.48l29.06-80.31h22.65l29.06,80.31h-20.71l-4.81-14.19Zm-14.98-44.5l-9.84,29.4h19.79l-9.95-29.4Z"/>
      <path className="b" d="M432.13,42.31c9.38,0,16.24,4.23,19.68,9.95v-9.04h19.56v63.72c0,17.04-9.84,31.57-31.92,31.57-18.99,0-31.34-9.61-33.06-24.82h19.33c1.26,4.8,6.06,7.89,12.81,7.89,7.44,0,13.27-3.89,13.27-14.64v-9.04c-3.55,5.6-10.29,10.07-19.68,10.07-15.67,0-28.14-12.81-28.14-32.95,0-20.13,12.48-32.71,28.15-32.71Zm5.72,17.04c-7.32,0-13.96,5.49-13.96,15.67s6.63,15.9,13.96,15.9,13.96-5.6,13.96-15.79c-.01-10.17-6.53-15.78-13.96-15.78Z"/>
      <path className="b" d="M505.12,107.05h-19.56V43.22h19.56v10.64c4.46-6.75,11.44-11.33,20.02-11.33v20.71h-5.38c-9.27,0-14.64,3.2-14.64,14.19v29.62Z"/>
      <path className="b" d="M532.69,26.06c0-5.95,4.69-10.64,11.67-10.64s11.55,4.69,11.55,10.64-4.69,10.52-11.55,10.52c-6.98,.01-11.67-4.68-11.67-10.52Zm1.83,17.16h19.56v63.83h-19.56V43.22Z"/>
      <path className="b" d="M640.45,66.9c0,23.79-16.47,40.15-42.1,40.15h-30.09V26.74h30.09c25.63,0,42.1,16.25,42.1,40.16Zm-43.36,23.22c14.76,0,23.45-8.46,23.45-23.22s-8.69-23.45-23.45-23.45h-9.27v46.67h9.27Z"/>
      <path className="b" d="M675.69,42.31c9.49,0,16.24,4.35,19.68,9.95v-9.04h19.56v63.83h-19.56v-9.04c-3.55,5.61-10.3,9.95-19.79,9.95-15.56,0-28.03-12.81-28.03-32.95-.01-20.12,12.46-32.7,28.14-32.7Zm5.72,17.04c-7.32,0-13.96,5.49-13.96,15.67s6.63,15.9,13.96,15.9,13.96-5.6,13.96-15.79c-.01-10.17-6.53-15.78-13.96-15.78Z"/>
      <path className="b" d="M732.2,59.46h-7.78v-16.24h7.78v-15.56h19.56v15.56h12.81v16.24h-12.81v25.62c0,3.77,1.6,5.38,5.95,5.38h6.98v16.59h-9.95c-13.27,0-22.54-5.61-22.54-22.19v-25.4Z"/>
      <path className="b" d="M799.81,42.31c9.49,0,16.24,4.35,19.68,9.95v-9.04h19.56v63.83h-19.56v-9.04c-3.55,5.61-10.3,9.95-19.79,9.95-15.56,0-28.03-12.81-28.03-32.95s12.47-32.7,28.14-32.7Zm5.72,17.04c-7.32,0-13.96,5.49-13.96,15.67s6.63,15.9,13.96,15.9,13.96-5.6,13.96-15.79c-.01-10.17-6.53-15.78-13.96-15.78Z"/>
      <path className="b" d="M323.56,131.34h19.56v80.31h-19.56v-80.31Z"/>
      <path className="b" d="M401.35,176.99c0-8.69-4.8-13.5-12.24-13.5s-12.24,4.8-12.24,13.5v34.66h-19.56v-63.83h19.56v8.47c3.89-5.38,10.75-9.15,19.33-9.15,14.76,0,24.6,10.07,24.6,27.23v37.29h-19.45v-34.67Z"/>
      <path className="b" d="M478.46,176.99c0-8.69-4.8-13.5-12.24-13.5s-12.24,4.8-12.24,13.5v34.66h-19.56v-63.83h19.56v8.47c3.89-5.38,10.75-9.15,19.33-9.15,14.76,0,24.59,10.07,24.59,27.23v37.29h-19.45v-34.67h0Z"/>
      <path className="b" d="M540.58,212.56c-18.76,0-32.95-12.58-32.95-32.83s14.53-32.83,33.17-32.83,33.17,12.58,33.17,32.83c.01,20.25-14.63,32.83-33.39,32.83Zm0-16.93c6.98,0,13.5-5.15,13.5-15.9s-6.41-15.9-13.27-15.9-13.27,5.03-13.27,15.9,5.95,15.9,13.04,15.9Z"/>
      <path className="b" d="M599.27,147.82l13.84,45.53,13.84-45.53h20.82l-22.65,63.83h-24.14l-22.65-63.83h20.94Z"/>
      <path className="b" d="M680.26,146.9c9.5,0,16.24,4.35,19.68,9.95v-9.04h19.56v63.83h-19.56v-9.04c-3.55,5.61-10.29,9.95-19.79,9.95-15.56,0-28.03-12.81-28.03-32.95s12.47-32.7,28.14-32.7Zm5.72,17.04c-7.32,0-13.96,5.49-13.96,15.67s6.63,15.9,13.96,15.9,13.96-5.61,13.96-15.79-6.52-15.78-13.96-15.78Z"/>
      <path className="b" d="M736.78,164.06h-7.78v-16.24h7.78v-15.56h19.56v15.56h12.81v16.24h-12.81v25.62c0,3.77,1.6,5.38,5.95,5.38h6.98v16.59h-9.95c-13.27,0-22.54-5.61-22.54-22.19v-25.4Z"/>
      <path className="b" d="M778.3,130.66c0-5.95,4.69-10.64,11.67-10.64s11.55,4.69,11.55,10.64-4.69,10.52-11.55,10.52-11.67-4.69-11.67-10.52Zm1.83,17.16h19.56v63.83h-19.56v-63.83Z"/>
      <path className="b" d="M842.94,212.56c-18.76,0-32.95-12.58-32.95-32.83s14.53-32.83,33.17-32.83,33.17,12.58,33.17,32.83c.01,20.25-14.63,32.83-33.39,32.83Zm0-16.93c6.98,0,13.5-5.15,13.5-15.9s-6.41-15.9-13.27-15.9-13.27,5.03-13.27,15.9,5.94,15.9,13.04,15.9Z"/>
      <path className="b" d="M930.8,176.99c0-8.69-4.8-13.5-12.24-13.5s-12.24,4.8-12.24,13.5v34.66h-19.56v-63.83h19.56v8.47c3.89-5.38,10.75-9.15,19.33-9.15,14.76,0,24.6,10.07,24.6,27.23v37.29h-19.45v-34.67Z"/>
      <path className="b" d="M989.83,212.56c-17.04,0-28.48-9.5-29.4-21.85h19.33c.46,4.46,4.46,7.44,9.84,7.44,5.03,0,7.66-2.29,7.66-5.15,0-10.3-34.78-2.86-34.78-26.31,0-10.87,9.27-19.79,25.97-19.79s25.62,9.15,26.88,21.74h-18.07c-.57-4.35-3.89-7.21-9.38-7.21-4.58,0-7.09,1.83-7.09,4.92,0,10.18,34.55,2.97,34.89,26.77,0,11.09-9.84,19.44-25.85,19.44Z"/>
    </g>
  </svg>
);
