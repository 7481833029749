import React from 'react';
import {Typography} from '@material-ui/core';
import {isPointInBox} from '../../Utils/AnnotationHelpers';
import {dateToStringWithTime} from '../../Utils/DateUtils';
const AnnotationDetails = ({annotation, instance, snapshot}) => {
  const plant = snapshot?.data?.analysis_data?.plant_record_list?.find((plant) =>
    isPointInBox(annotation?.center_coordinate, plant?.center_coordinate, plant?.pixel_dimensions) || []);

  const getFlowers = () => {
    if (!snapshot?.data?.analysis_data?.plant_record_list) {
      return 0;
    }
    return plant?.buds?.length || 0;
  };
  const getAveragePlantHeight = () => {
    if (!snapshot?.data?.analysis_data?.plant_record_list) {
      return 0;
    }
    // iterate through all plants in snapshot and get the average height of all plants
    let totalHeight = 0;
    snapshot?.data?.analysis_data?.plant_record_list.forEach((plant) => {
      totalHeight += plant?.height;
    });
    return ((totalHeight / snapshot?.data?.analysis_data?.plant_record_list.length) * 100).toFixed(2);
  };


  return (
    <div>
      <Typography><b>Snapshot ID:</b> {annotation?.snapshot_id}</Typography>
      <Typography><b>Annotation ID:</b> {annotation?._id}</Typography>
      <Typography><b>Client:</b> {annotation?.client}</Typography>
      <Typography><b>Label:</b> {annotation?.label || 'No label'}</Typography>
      <Typography>
        <b>Type of Chrysanthemum:</b> {instance?.plant_information?.article_group || 'Type not found'}
      </Typography>
      <Typography><b>Driving Speed:</b> {(snapshot?.data?.position_id?.speed * 60).toFixed(2)} m/min</Typography>
      <Typography>
        <b>Plant Age:</b>
        {parseInt((new Date(snapshot.collection_date) -
        new Date(instance?.plant_information?.planting_date)) / 86400000) +
        ' '}
          days
      </Typography>
      <Typography><b>Flowers: </b> {getFlowers()} flowers detected</Typography>
      <Typography><b>Plant Height:</b> {(plant?.height * 100).toFixed(2)} cm</Typography>
      <Typography><b>Average Plant Height: </b> {getAveragePlantHeight()} cm</Typography>
      <Typography><b>Collection Date: </b> {snapshot.collection_date}</Typography>
      <Typography><b>Time of Day: </b> {dateToStringWithTime(new Date(snapshot?.collection_date))}</Typography>
    </div>
  );
};

export default AnnotationDetails;
