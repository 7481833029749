import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/styles';
import 'date-fns';
import {useTranslation} from 'react-i18next';
import React, {useState, useEffect} from 'react';
import InBedPosition from '../../Utils/InBedPosition';
import {TextField} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  locationSelection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  positionCard: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 200,
  },
}));

function LanePositionPicker({defaultInBedPosition, useOptions, options, onLanePositionChanged, setSortSnapshotsBy,
  hideCard = false, useHooks = true, useDistance = true}) {
  const {t} = useTranslation();
  const classes = useStyles();
  const [selectedLane, setSelectedLane] = useState(null);
  const [selectedSubLane, setSelectedSubLane] = useState(null);
  const [hook, setHook] = useState(null);
  const [distance, setDistance] = useState(null);

  useEffect(() => {
    setSelectedLane(defaultInBedPosition?.lane);
    setSelectedSubLane(defaultInBedPosition?.subLane);
  }, [defaultInBedPosition]);

  useEffect(() => {
    if (!setSortSnapshotsBy) {
      return;
    }
    setSortSnapshotsBy('distance');
  }, [selectedLane, selectedSubLane]);

  useEffect(() => {
    const inBedPosition = new InBedPosition(
        selectedLane,
        selectedSubLane,
        hook,
        distance,
    );
    onLanePositionChanged(inBedPosition);
  }, [selectedLane, selectedSubLane, hook, distance]);

  const getLanePicker = () => {
    if (!useOptions?.lane) {
      return <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        value={selectedLane}
        onChange={(e) => setSelectedLane(e.target.value)}
        id="LaneField"
        label="Lane"
        name="Lane" />;
    }
    if (options === null) {
      return;
    }
    return (
      <FormControl className={classes.positionCard}>
        <InputLabel shrink={selectedLane !== null && selectedLane !== ''}
        >{t('Lane')}</InputLabel>
        <Select
          native
          value={selectedLane}
          onChange={(e) => setSelectedLane(e.target.value)}
          inputProps={{
            name: 'location',
            id: 'location-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          {Object.keys(options).map((item) =>
            <option key={item} value={item}>{item}</option>,
          )}
        </Select>
      </FormControl>
    );
  };

  const sortSubLanes = (locationValues) => { // sorts negative sublanes for Retine
    const negativeSublanes = locationValues.every((i) => i.startsWith('-'));
    if (negativeSublanes === false) {
      return locationValues;
    }
    let sublaneCharacters = locationValues.map((s) => s.slice(1));
    sublaneCharacters.sort();
    sublaneCharacters = sublaneCharacters.map((s) => '-' + s);
    return sublaneCharacters;
  };

  const getSubLanePicker = () => {
    if (selectedLane === null) {
      return;
    }
    if (!useOptions?.subLane) {
      return <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        value={selectedSubLane}
        onChange={(e) => setSelectedSubLane(e.target.value)}
        id="SubLaneField"
        label="SubLane"
        name="SubLane" />;
    }
    if (options === null || options === undefined || options[selectedLane] === undefined) {
      return;
    }
    const values = sortSubLanes([...options[selectedLane]]);
    return (
      <FormControl className={classes.positionCard}>
        <InputLabel>{t('Sub-Lane')}</InputLabel>
        <Select
          native
          value={selectedSubLane}
          onChange={(e) => setSelectedSubLane(e.target.value)}
          inputProps={{
            name: 'location',
            id: 'location-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          {values.map((item) =>
            <option key={item} value={item}>{item}</option>,
          )}
        </Select>
      </FormControl>
    );
  };

  const getContent = () => {
    return <>
      {selectedLane !== '' || options ? getLanePicker() : ''}
      {selectedSubLane || selectedLane ? getSubLanePicker() : ''}
      {useHooks && selectedLane && selectedSubLane ?
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={hook}
                  onChange={(event) => setHook(event.target.value)}
                  id="hookField"
                  label="Hook"
                  name="Hook" /> : ''}
      {useDistance && selectedLane && selectedSubLane && hook ?
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  value={distance}
                  onChange={(event) => setDistance(event.target.value)}
                  id="distanceField"
                  label="Distance"
                  name="Distance" /> :
                ''}
    </>;
  };

  return (
        hideCard ? getContent() :
            <Card className={classes.locationSelection}>
              <CardContent>
                {getContent()}
              </CardContent>
            </Card>
  );
}
export default LanePositionPicker;
