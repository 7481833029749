import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import clientConfig from '../../Config/clients.json';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  clientCard: {
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const ClientPicker = ({clients, defaultClient, label, onClientChanged, hideCard = false, triggerOnEmpty=false}) => {
  const [selectedClient, setSelectedClient] = useState(defaultClient);
  const classes = useStyles();

  useEffect(() => {
    if (!selectedClient && !triggerOnEmpty) {
      return;
    }
    onClientChanged(selectedClient);
  }, [selectedClient]);

  useEffect(() => {
    if (!defaultClient) {
      return;
    }
    setSelectedClient(defaultClient);
  }, [defaultClient]);

  const handleClientChange = (event) => {
    const client = event.target.value;
    setSelectedClient(client);
  };

  const allClients = clients.length === 0 ? Object.values(clientConfig.names) : clients;
  // sort list of clients alphabetically
  allClients.sort();

  const getClientPicker = () => {
    return <FormControl className={classes.clientCard} id='client-picker'>
      {label && <InputLabel htmlFor="client-native-simple">{label}</InputLabel>}
      <Select
        native
        value={selectedClient}
        onChange={handleClientChange}
        inputProps={{name: 'client', id: 'client-native-simple'}}
      >
        <option aria-label="None" value="" />
        {allClients.map((item) => (
          <option key={item} value={item}>{item}</option>
        ))}
      </Select>
    </FormControl>;
  };

  return (
        hideCard ?
            getClientPicker() :
            <Card className={classes.card} >
              <CardContent>
                {getClientPicker()}
              </CardContent>
            </Card >

  );
};

export default ClientPicker;
