import i18next from 'i18next';

export const getMorelImageName = (snapshot, imageName) => {
  let name = 'N°';
  const compartments = snapshot?.data?.analysis_data?.compartments;
  if (!compartments) {
    throw new Error('Compartments not found in snapshot');
  }
  const batchNames = Object.values(compartments).map((compartment) => compartment.name);
  // append batchnames to name with a dash
  name += batchNames.join('-');
  name += '-' + snapshot?.data?.batch_id;
  name += ' ' + getMorelSpecificTime(new Date(snapshot.collection_date));
  if (imageName == 'croppedImg.jpg') {
    name += ' results';
  } else if (imageName == 'resultImg.jpg') {
    name += ' analyses';
  } else {
    throw new Error('Image name not recognized');
  }
  return name + '.jpg';
};

const getMorelSpecificTime = (dateTime) => {
  // format to return it in 30 September 2024 10_14_33
  const day = dateTime.getDate();
  const month = dateTime.getMonth();
  const monthNames = i18next.t('AllMonths', {returnObjects: true});
  const formattedMonth = monthNames[month];
  const year = dateTime.getFullYear();
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const seconds = dateTime.getSeconds();
  const formattedTime = `${hours.toString().padStart(2, '0')}_${
    minutes.toString().padStart(2, '0')}_${seconds.toString().padStart(2, '0')}`;
  return `${day} ${formattedMonth} ${year} ${formattedTime}`;
};
