import React from 'react';
import {Button, Card, CardContent, FormControl, TextField} from '@material-ui/core';
import {ApiClient} from '../../Utils/ApiClient';
import {RegularSpacer} from '../BasicComponents/Spacers';
import {getDatePlusInterval} from '../../Utils/DateUtils';
import {DateRangePickerComponent} from '../../Utils/DatePickers';
import {useContext} from 'react';
import {UserContext} from '../../contexts/userContext';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import BatchCard from './BatchCard';

const LaneBatchesPage = () => {
  const {t} = useTranslation();
  const {currentClient} = useContext(UserContext);
  const [startDate, setStartDate] = useState(() => getDatePlusInterval(-1, true));
  const [endDate, setEndDate] = useState(() => getDatePlusInterval(0, true));
  const [lane, setLane] = useState('');
  const [subLane, setSubLane] = useState('');
  const [batches, setBatches] = useState([]);

  const loadPossibleBatches = async () => {
    const result = await ApiClient.getLaneBatches(currentClient, startDate, endDate, lane, subLane);
    if (result) {
      setBatches(result);
    }
  };

  const uploadBatches = async () => {
    let previousBatch = null;
    // todo make this an input field
    const hardCodedMargin = 0.2;
    const hardCodedErpType = 'Deliflor_ERP';
    for (const batch of batches) {
      if (previousBatch === null) {
        previousBatch = batch;
        continue;
      }
      const startDistance = previousBatch[1] + hardCodedMargin;
      const endDistance = batch[1] - hardCodedMargin;
      await ApiClient.insertLaneBatchInformationDeliflor(
          previousBatch[0], currentClient, lane, subLane, startDistance, endDistance, hardCodedErpType);
      previousBatch = batch;
    }
  };

  const handleChangeDateStart = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(0, 0, 0, 0);
    setStartDate(dateObject.toISOString());
  };

  const handleChangeDateEnd = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(23, 59, 59, 999);
    setEndDate(dateObject.toISOString());
  };

  return (
    <div style={{'width': '70%'}}>
      <Card>
        <CardContent>
          <h1>Check Batches</h1>
        </CardContent>
      </Card>
      <RegularSpacer size={1} />
      <Card>
        <CardContent>
          <FormControl fullWidth>
            <DateRangePickerComponent
              id="dateFrom"
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              changeStartDate={(event) => handleChangeDateStart(event)}
              changeEndDate={(event) => handleChangeDateEnd(event)}
            />
          </FormControl>
          <RegularSpacer size={1} />
          <FormControl fullWidth >
            <TextField label="Lane" type="text" value={lane}
              onChange={(e) => setLane(e.target.value)}
              inputProps={{min: 0, style: {textAlign: 'center'}}} />
          </FormControl>
          <FormControl fullWidth >
            <TextField label="SubLane" type="text" value={subLane}
              onChange={(e) => setSubLane(e.target.value)}
              inputProps={{min: 0, style: {textAlign: 'center'}}} />
          </FormControl>
          <RegularSpacer size={2} />
          <Button variant="contained" color="primary" onClick={loadPossibleBatches}>
            {t('Load Batches')}
          </Button>
        </CardContent>
      </Card>
      <RegularSpacer size={4}/>
      {batches?.length > 0 ?
      <div>
        <Card>
          <CardContent>
            {batches.map((batch) => <BatchCard key={batch[0]} locationId={batch[0]} distance={batch[1]} />)}
          </CardContent>
        </Card>
        <RegularSpacer size={2} />
        <Button variant="contained" color="primary" onClick={uploadBatches}>
          {t('Confirm these batches and their location.')}
        </Button>
      </div> :
       ''}
    </div>
  );
};

export default LaneBatchesPage;
