
export const ValueType = {
  AVERAGE: 'average',
  MEDIAN: 'median',
  MIN: 'min',
  MAX: 'max',
  RANGE_MIN_MAX: 'range_min_max',
  RANGE_1_STD_MEDIAN: 'range_1_std_median',
  RANGE_1_STD_AVERAGE: 'range_1_std_average',
  COUNT: 'count',
  MERGE_ARRAYS: 'merge_arrays',
  HEIGHT_PERCENTILES: 'height_percentiles',
};

export const todayAsISODate = (endOfTheDay = false) => {
  const dateObject = new Date();
  if (endOfTheDay) {
    dateObject.setHours(23, 59, 59, 999);
  } else {
    dateObject.setHours(0, 0, 0, 0);
  }
  return dateObject.toISOString();
};

export class DataFormatter {
  static transformationEnumToFunction = {
    [ValueType.AVERAGE]: DataFormatter.getAverage,
    [ValueType.MEDIAN]: DataFormatter.getMedian,
    [ValueType.RANGE_MIN_MAX]: DataFormatter.getMinMaxRange,
    [ValueType.MIN]: DataFormatter.getMin,
    [ValueType.MAX]: DataFormatter.getMax,
    [ValueType.RANGE_1_STD_MEDIAN]: (values) => DataFormatter.getMinMaxRangeBasedOnSTD(values, true),
    [ValueType.RANGE_1_STD_AVERAGE]: (values) => DataFormatter.getMinMaxRangeBasedOnSTD(values, false),
    [ValueType.COUNT]: DataFormatter.getCount,
    [ValueType.MERGE_ARRAYS]: DataFormatter.mergeArrays,
    [ValueType.HEIGHT_PERCENTILES]: (values, options) => DataFormatter.heightPercentiles(values, options),
  };

  static formatData = (snapshots, graphSettings) => {
    if (snapshots.length === 0) {
      return [];
    }
    snapshots = DataFormatter.filterInvalidSnapshots(snapshots);
    return DataFormatter.mergeSnapshotsSameDayAndFormat(snapshots, graphSettings);
  };

  static mergeSnapshotsSameDayAndFormat = (snapshots, graphSettings) => {
    const groupedSnapshots = {};
    snapshots.forEach((snapshot) => {
      let dayString = snapshot.collection_date;
      if (graphSettings[0].mergeSameDay) {
        dayString = new Date(snapshot.collection_date).toDateString();
      }
      if (!(dayString in groupedSnapshots)) {
        groupedSnapshots[dayString] = [];
      }
      groupedSnapshots[dayString].push(snapshot);
    });
    const mergedSnapshots = [];
    Object.values(groupedSnapshots).forEach((snapshots) => {
      try {
        const mergedSnapshot = DataFormatter.mergeSnapshotsIntoOne(snapshots, graphSettings);
        mergedSnapshots.push(mergedSnapshot);
      } catch (e) {
        console.log(e);
      }
    });
    return mergedSnapshots;
  };

  static mergeSnapshotsIntoOne = (snapshots, graphSettings) => {
    const mergedSnapshot = {};
    mergedSnapshot['data'] = {'analysis_data': {}};
    mergedSnapshot['collection_date'] = DataFormatter.getAverageDateISO(snapshots);
    graphSettings.forEach((graphSetting) => {
      const fullTarget = graphSetting.target;
      let target = fullTarget;
      // When the target is nested in plant_record_list, we need to split it up to get the correct target value.
      if (fullTarget.includes('plant_record_list')) {
        target = fullTarget.split('plant_record_list')[0] + 'plant_record_list';
      }
      let targetValues = snapshots.map((s) => DataFormatter.getTargetValue(s, target));
      targetValues = DataFormatter.mapPlants(fullTarget, targetValues);
      // Flower properties are another level deeper so transformData happens twice, first the lowest level.
      // For example the 'average' of the 'median' flower. First calculate median then take the average of that.
      if (graphSetting.valueTypes.length > 1) {
        if (graphSetting.valueTypes[graphSetting.valueTypes.length - 1] === ValueType.MERGE_ARRAYS) {
          targetValues = DataFormatter.mergeArrays(targetValues);
        } else {
          targetValues = targetValues.map((v) => DataFormatter.transformData(v, graphSetting.valueTypes[1]));
        }
        targetValues = targetValues.filter(Boolean);
      }
      if (graphSetting.filterUndefined) {
        targetValues = targetValues.filter(Boolean);
      }
      const options = graphSetting?.valueTypesOptions;
      targetValues = DataFormatter.transformData(targetValues, graphSetting.valueTypes[0], options);
      const result = DataFormatter.roundToThreeDecimals(targetValues);
      if (result.length === 0) {
        throw new Error('Invalid data');
      }
      mergedSnapshot[graphSetting.outputName] = result;
    });
    return mergedSnapshot;
  };

  static transformData = (values, valueType, options) => {
    const transformFunction = DataFormatter.transformationEnumToFunction[valueType];
    return options !== undefined ? transformFunction(values, options) : transformFunction(values);
  };

  static mapPlants = (target, targetValues) => {
    let hasBuds = false;
    if (!target.includes('plant_record_list')) {
      return targetValues;
    }
    let subTarget = target.split('.plant_record_list.')[1];
    if (subTarget.includes('buds.')) {
      hasBuds = true;
      subTarget = target.split('.buds.')[1];
    }
    let newTargetValues = [];
    for (let i = 0; i < targetValues.length; i++) {
      let subTargetsValues;
      if (hasBuds) {
        subTargetsValues = targetValues[i].map((plant) => plant['buds'].map((bud) => bud[subTarget]));
      } else {
        subTargetsValues = targetValues[i].map((plant) => plant[subTarget]);
      }
      newTargetValues = newTargetValues.concat(subTargetsValues);
    }
    return newTargetValues;
  };

  static formatSnapshots(snapshots, target) {
    for (let i = 0; i < snapshots.length; i++) {
      const targetDate = DataFormatter.getTargetValue(snapshots[i], target);
      snapshots[i]['chartValue'] = DataFormatter.roundToThreeDecimals(targetDate);
    }
    return snapshots;
  };

  static filterInvalidSnapshots(snapshots) {
    snapshots = snapshots.filter((s) => s.data.analysis_data);
    return snapshots;
  }

  static getTargetValue(snapshot, target) {
    const targetSplit = target.split('.');
    return targetSplit.reduce((o, i) => o[i], snapshot);
  }

  static getMin(values) {
    if (values.length === 0) {
      return 0;
    }
    return Math.min(...values);
  }


  static getMax(values) {
    if (values.length === 0) {
      return 0;
    }
    return Math.max(...values);
  }

  static getMinMaxRange(values) {
    if (values.length === 0) {
      return 0;
    }
    return [Math.min(...values), Math.max(...values)];
  }

  static getMinMaxRangeBasedOnSTD(values, useMedian) {
    if (values.length === 0) return 0;
    let center = DataFormatter.getAverage(values);
    if (useMedian) {
      center = DataFormatter.getMedian(values);
    }
    const allValuesBelowCenter = values.filter((v) => v <= center);
    const allValuesAboveCenter = values.filter((v) => v >= center);
    const lowerStd = DataFormatter.getStandardDeviation(allValuesBelowCenter);
    const upperStd = DataFormatter.getStandardDeviation(allValuesAboveCenter);
    return [Math.max(0, center - lowerStd), center + upperStd];
  }

  static getStandardDeviation(values) {
    const n = values.length;
    if (n < 2) return 0;
    const mean = values.reduce((a, b) => a + b) / n;
    return Math.sqrt(values.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / (n-1));
  }

  static getAverage(values) {
    if (!values || values.length === 0) return 0;
    return values.reduce((a, b) => a + b, 0) / values.length;
  }

  static getMedian(values) {
    if (values.length === 0) return 0;
    values.sort((a, b) => a - b);
    const half = Math.floor(values.length / 2);
    if (values.length % 2) {
      return values[half];
    } else {
      return (values[half - 1] + values[half]) / 2.0;
    }
  }

  static getCount(values) {
    return values.length;
  }

  static mergeArrays(values) {
    return [].concat(...values);
  }

  static heightPercentiles(heights, options) {
    heights = heights.filter((h) => Math.abs(h.percentile - options.percentile) < 0.01 );
    return heights.map((h) => h.value)[0];
  }

  static getAverageDateISO(snapshots) {
    const times = snapshots.map((s) => new Date(s.collection_date).getTime());
    const averageTime = new Date(DataFormatter.getAverage(times));
    return averageTime.toISOString();
  }

  static roundToThreeDecimals(values) {
    if (Array.isArray(values)) {
      return values.map((x) => Math.round(x * 1000) / 1000);
    } else {
      return Math.round(values * 1000) / 1000;
    }
  }
}
