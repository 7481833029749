import React, {useContext} from 'react';
import {Card} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import './overview-map.css';
import {Heatmap} from './Heatmap';
import {HeaderText} from '../Images/ImagePageStyles';
// import svg
import {ReactComponent as OverviewMapSvg} from './icons/noConnection.svg';
import {OverviewMapContext} from '../../contexts/OverviewMapContext';
import ToggleMapType from './ToggleMapType';
const useStyles = makeStyles((theme) => ({
  card: {
    height: '1100px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'auto',
  },
}));

// This component displays the overview map, which shows the current state of the lanes.
const OverviewMap = () => {
  const classes = useStyles();
  const {startingDate, lanes, mapType, setMapType, greenhouseStats} = useContext(OverviewMapContext);

  const handleMapTypeChange = (event, newMapType) => {
    if (newMapType !== null) {
      setMapType(newMapType);
    }
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '70%'}} id='map-container'>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <HeaderText variant="h5" style={{padding: 10, fontWeight: 'bold'}}>
              OVERVIEW MAP
        </HeaderText>
        <ToggleMapType mapType={mapType} handleMapTypeChange={handleMapTypeChange}/>
        <HeaderText variant="h5" style={{padding: 10, fontWeight: 'bold'}}>
          {startingDate.toDateString()}
        </HeaderText>
      </div>
      <Card className={classes.card} >
        <div className='no-data-found'>
          <OverviewMapSvg/>
          <h1 >No snapshots found.</h1>
        </div>
        {lanes && lanes.length > 0 && greenhouseStats && <Heatmap/>}
      </Card>
    </div>
  );
};

export default OverviewMap;
