import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

const styles = (theme) => ({
  buttons: {
    color: theme.palette.primary.dark,
  },
  resultsCard: {
    padding: '10px',
    marginTop: theme.spacing(1),
  },
});

class ErrorsMoreDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      statisticsClicked: false,
    };
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleStatisticsButton = this.handleStatisticsButton.bind(this);
  }

  handleButtonClick() {
    this.setState({clicked: !this.state.clicked, statisticsClicked: false});
  }

  handleStatisticsButton() {
    this.setState({statisticsClicked: !this.state.statisticsClicked, clicked: false});
  }

  generateStatistics(data) {
    const errors = {};
    for (let i = 0; i < data.length; i++) {
      const errorMessage = data[i].error_message;
      if (errors[errorMessage] === undefined) {
        errors[errorMessage] = 1;
      } else {
        errors[errorMessage] += 1;
      }
    }
    return errors;
  }

  render() {
    const {classes} = this.props;
    return (
      <>
        <Button onClick={this.handleButtonClick} className={classes.buttons}>Snapshot-by-snapshot</Button>
        <Button onClick={this.handleStatisticsButton} className={classes.buttons}>Statistics</Button>
        {this.state.clicked ?
                <>
                  {this.props.data.map((item) => <Typography key={item.snapshot_id}>
                    {item.snapshot_id} failed with error: <b>{item.error_message}</b>
                  </Typography>)}
                </> :
                ''}
        {this.state.statisticsClicked ?
                <>
                  {Object.entries(this.generateStatistics(this.props.data)).map(
                      ([key, value]) => <Typography key={key}>{value}: {key}</Typography>)}
                </> :
                ''}

      </>
    );
  }
}

export default (withStyles(styles, {withTheme: true})(ErrorsMoreDetails));
