import React, {useState, useRef, useContext} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import {useTranslation} from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import {ApiClient} from '../../Utils/ApiClient';
import {styled, useTheme} from '@mui/material/styles';
import {CenteredCardContent, UserInputs} from '../StyledComponents/BasicStyles';
import {useEffect} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {dateToStringWithoutTime} from '../../Utils/DateUtils';
import {CardContent, Grid} from '@material-ui/core';
import SimpleFilter from '../Images/SimpleFilter';
import {UserContext} from '../../contexts/userContext';
import {useHistory} from 'react-router-dom';
const Input = styled('input')(({theme}) => ({
  alignSelf: 'center',
  marginBottom: theme.spacing(2),
}));

const SuccessText = styled(Typography)(({theme}) => ({
  ...({
    marginTop: theme.spacing(1),
  }),
}));

function HarvestLondonDataUpload() {
  const {t} = useTranslation();
  const theme = useTheme();
  const inputRef = useRef();
  const [selectedFile, setSelectedFile] = useState();
  const [isUploaded, setIsUploaded] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [failedUpload, setFailedUpload] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [isTruncated, setIsTruncated] = useState(false);
  const {currentClient} = useContext(UserContext);
  const history = useHistory();
  const truncateNumber = 20;
  const wrapperWidth = 1200;

  useEffect(() => {
    if (! currentClient || currentClient !== 'HarvestLondonWellington') {
      return invalidClient();
    }
    getData();
  }, []);

  useEffect(() => {
    history.push(`/${currentClient || 'admin'}/batch-info`);
    if (! currentClient || currentClient !== 'HarvestLondonWellington') {
      return invalidClient();
    }
    getData();
  }, [currentClient]);

  useEffect(() => {
    filterDataOnText();
  }, [filterText, data]);

  const invalidClient = () => {
    return (
      <Card>
        <CardContent>
          <h2>Invalid Client</h2>
          <p>
          Selected client has no access to this page.
          </p>
        </CardContent>
      </Card>
    );
  };

  const filterDataOnText = () => {
    let visibleData = data;
    if (filterText !== '') {
      visibleData = data.filter((entry) => entry.tray_position.includes(filterText));
    }
    if (visibleData.length > truncateNumber) {
      visibleData = visibleData.slice(0, truncateNumber);
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
    setFilteredData(visibleData);
  };

  const removeEntryFromData = (id) => {
    const newData = data.filter((entry) => entry._id !== id);
    setData(newData);
  };

  const removeEntry = (id) => {
    ApiClient.deleteHarvestLondonTrayInformation(id).then((response) => {
      if (response === undefined || response?.status !== 200) {
        return;
      }
      removeEntryFromData(id);
    });
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmission = () => {
    const formData = new FormData();

    formData.append('File', selectedFile);

    ApiClient.uploadHarvestLondonDataSheet(formData)
        .then((result) => {
          if (result.status !== 201) {
            setSelectedFile();
            setFailedUpload(true);
            setIsUploaded(false);
            setStatusMessage(t('Upload failed.'));
            if (result.status === 400) {
              setStatusMessage('Data already exists in the database');
            } else if (result.status === 200) {
              setStatusMessage('Data was partially inserted in the database.');
            }
          } else {
            setSelectedFile();
            setIsUploaded(true);
          }
          getData();
          inputRef.current.value = '';
        })
        .catch((error) => {
          console.log('Error', error);
        });
  };

  const getData = () => {
    ApiClient.getHarvestLondonTrayInformation().then((response) => {
      if (response.status !== 200) {
        return;
      }
      setData(response.data.data);
      setFilteredData(response.data.data);
    });
  };

  const getExampleTable = () => {
    return (<div>
      <table>
        <tr>
          <th>Site: External Reference</th>
          <th>Grow Batch ID</th>
          <th>Seed: Plant (Common Name)</th>
          <th>Seed: Plant (Variety Name)</th>
          <th>Tray Reservation ID</th>
          <th>Tray Position: Tray Position Location</th>
          <th>Expected Start Date (Due Date)</th>
          <th>Expected End Date</th>
          <th>Actual Start Date</th>
          <th>Actual End Date (Date Completed)</th>
        </tr>
        <tr>
          <th>Wellington_Road</th>
          <th>a020C000004vpTS</th>
          <th>Basil</th>
          <th>Salvo</th>
          <th>a090C000003HeVj</th>
          <th>R1 L1 ZA B1 TP4</th>
          <th>27/10/2023</th>
          <th>30/11/2023</th>
          <th>26/10/2023</th>
          <th>30/11/2023</th>
        </tr>
        <tr>
          <th>..</th>
          <th>..</th>
          <th>..</th>
          <th>..</th>
          <th>..</th>
          <th>..</th>
          <th>..</th>
          <th>..</th>
          <th>..</th>
          <th>..</th>
        </tr>
      </table>
    </div>);
  };
  return (
    <>
      {currentClient !== 'HarvestLondonWellington' ? invalidClient() :
      (
        <UserInputs>
          <Card>
            <CenteredCardContent maxWidth={wrapperWidth}>
              <h2 >{t('Upload Sheet (psv)')}</h2>
              <br />
              {getExampleTable()}
              <br />
              <div>
                <Input style={{color: theme.palette.primary.main}} ref={inputRef}
                  type="file" name="file" accept=".psv" onChange={changeHandler} />
                <br />
                <Button size="small" color="primary" onClick={handleSubmission}>
                  <Typography>{t('Upload')}</Typography>
                </Button>
                {isUploaded ? <SuccessText theme={theme} >{t('Successful Upload')}</SuccessText> : <></>}
                {failedUpload ? <SuccessText theme={theme} >{statusMessage}</SuccessText> : <></>}
              </div>
            </CenteredCardContent>
          </Card>
          <Card style={{marginTop: theme.spacing(5), marginBottom: theme.spacing(1)}}>
            <CenteredCardContent maxWidth={wrapperWidth}>
              <h2>Batches in the database</h2>
              <Typography>
                {'This is a list of all batches '}
                {'that are currently in the database.'}
              </Typography>
            </CenteredCardContent>
          </Card>
          <SimpleFilter
            triggerMethod={(v) => setFilterText(v)}
            label={'Tray Position'} />
          <Grid container justifyContent="center" style={{width: wrapperWidth, marginLeft: theme.spacing(-1)}}>
            {filteredData.map((entry) =>
              <Grid item key={entry._id}>
                <Card
                  style={{
                    marginBottom: theme.spacing(1),
                    marginLeft: theme.spacing(1),
                    position: 'relative',
                    textAlign: 'left',
                    width: 350,
                  }}>
                  <CardContent>
                    <Typography><b>Tray Number: </b>{entry.tray_number}</Typography>
                    <Typography><b>Tray Position: </b>{entry.tray_position}</Typography>
                    <Typography><b>Batch: </b>{entry.batch}</Typography>
                    <Typography><b>Common Name: </b>{entry.plant_common_name}</Typography>
                    <Typography><b>Variety Name: </b>{entry.plant_variety_name}</Typography>
                    <Typography><b>Expected Start Date:
                    </b>{dateToStringWithoutTime(new Date(entry.expected_start_date))}
                    </Typography>
                    <Typography><b>Expected End Date: </b>{dateToStringWithoutTime(new Date(entry.expected_end_date))}
                    </Typography>
                    <Typography><b>Actual Start Date: </b>{dateToStringWithoutTime(new Date(entry.actual_start_date))}
                    </Typography>
                    <Typography><b>Actual End Date: </b>{dateToStringWithoutTime(new Date(entry.actual_end_date))}
                    </Typography>
                    <DeleteIcon
                      style={{position: 'absolute', bottom: 10, right: 10, color: 'red'}}
                      onClick={() => removeEntry(entry._id)} />
                  </CardContent>
                </Card>
              </Grid>,
            )}
            {isTruncated ? <Card
              style={{
                marginBottom: theme.spacing(1),
                marginLeft: theme.spacing(1),
                position: 'relative',
                textAlign: 'left',
                width: 250,
                height: 120,
              }}>
              <CardContent>
                <Typography>...</Typography>
              </CardContent>
            </Card> : <></>}
          </Grid>
        </UserInputs >
      )}
    </>

  );
}

export default HarvestLondonDataUpload;
