import React, {useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {getDatePlusInterval} from '../../Utils/DateUtils';
import {Button, Card, CardContent, FormControl, TextField} from '@material-ui/core';
import {DateRangePickerComponent} from '../../Utils/DatePickers';
import {useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import {ApiClient} from '../../Utils/ApiClient';
import {useContext} from 'react';
import {UserContext} from '../../contexts/userContext';
import {useHistory} from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = createUseStyles(
    {
      onlyMarginBottom: {
        marginBottom: 10,
      },
    },
);

const DeleteInstancesPage = () => {
  const [startDate, setStartDate] = useState(() => getDatePlusInterval(-7));
  const [endDate, setEndDate] = useState(() => getDatePlusInterval(0, true));
  const [instances, setInstances] = useState([]);
  const [visibleInstances, setVisibleInstances] = useState([]);
  const [hideRemoveAllButton, setHideRemoveAllButton] = useState(false);
  const [lane, setLane] = useState('');
  const history = useHistory();
  const classes = useStyles();
  const {currentClient} = useContext(UserContext);

  useEffect(() => {
    history.push((`/${currentClient || 'admin'}/delete-instances`));
  }, [currentClient]);
  useEffect(() => {
    if (!currentClient) {
      return;
    }
    fetchInstances();
  }, [currentClient, startDate, endDate]);

  useEffect(() => {
    if (lane === '') {
      setVisibleInstances(instances);
    } else {
      setVisibleInstances(instances.filter((instance) => instance?.data?.lane === lane));
    }
  }, [lane, instances]);

  const handleChangeDateStart = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(0, 0, 0, 0);
    setStartDate(dateObject.toISOString());
  };

  const handleChangeDateEnd = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(23, 59, 59, 999);
    setEndDate(dateObject.toISOString());
  };

  const fetchInstances = async () => {
    const instances = await ApiClient.findInstances(startDate, endDate, currentClient);
    setInstances(instances);
  };

  const handleDeleteInstance = async (id) => {
    await ApiClient.deleteInstance(id);
    fetchInstances();
  };

  const removeAllVisibleInstances = async () => {
    setHideRemoveAllButton(true);
    const promises = visibleInstances.map((instance) => ApiClient.deleteInstance(instance._id));
    await Promise.all(promises);
    fetchInstances();
    setHideRemoveAllButton(false);
  };

  return (
    <div style={{'width': '70%'}}>
      <Card>
        <CardContent>
          <FormControl fullWidth className={classes.onlyMarginBottom}>
            <DateRangePickerComponent
              id="dateFrom"
              defaultStartDate={startDate}
              defaultEndDate={endDate}
              changeStartDate={(event) => handleChangeDateStart(event)}
              changeEndDate={(event) => handleChangeDateEnd(event)}
            />
          </FormControl>
          <FormControl fullWidth >
            <TextField label="Lane" type="text" value={lane}
              onChange={(e) => setLane(e.target.value)}
              inputProps={{min: 0, style: {textAlign: 'center'}}} />
          </FormControl>
        </CardContent>
      </Card>
      {hideRemoveAllButton ? '' :
        <Button variant="contained" color="primary" onClick={removeAllVisibleInstances}>
          Remove all instances
        </Button>
      }
      <div style={{maxHeight: '600px', overflowY: 'scroll'}}>
        <ul>
          {visibleInstances.map((instance) => (
            <Card key={instance._id} className={classes.onlyMarginBottom}>
              <CardContent>
                <pre>{JSON.stringify(instance, null, 2)}</pre>
                <DeleteIcon
                  style={{color: 'red'}}
                  onClick={() => handleDeleteInstance(instance._id)} />
              </CardContent>
            </Card>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DeleteInstancesPage;
