import i18next from 'i18next';
import {ApiClient} from './ApiClient';
import {possibleOperations} from '../Components/ShoppingCart/ShoppingCartPage';
import {getMorelImageName} from './MorelSpecificFormatting';
import JSZip from 'jszip';
import mimeDb from 'mime-db';

class SnapshotOperationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'SnapshotOperationError';
  }
}

export const getReadibleDates = (dateObject, includeTime = false) => {
  const monthNames = i18next.t('AllMonths', {returnObjects: true});
  const month = monthNames[dateObject.getMonth()];
  const day = String(dateObject.getDate()).padStart(2, '0');
  const year = dateObject.getFullYear();

  let output = day + ' ' + month + ' ' + year;
  if (includeTime) {
    output += ' ' + dateObject.toTimeString();
  }
  return output;
};

export const getReadibleLaneInfo = (lane, subLane, hook, distance, includeLane) => {
  if (includeLane) {
    return 'Lane ' + lane + ' sub-lane ' + subLane + ' hook ' + hook + ' distance ' + distance;
  }
  return 'Hook ' + hook + ' distance ' + distance;
};

// takes a date object and returns a string in the format HH:MM
const formatDateTime = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  return `${formattedTime}`;
};

export const getSnapshotTitle = (item, includeLane = true, includeDate = false, showPlantCounts = false,
    plantConfidence = 0) => {
  let name = 'Unhandled snapshot type';
  const titleType = getSnapshotType(item);
  switch (titleType) {
    case 'dates':
      includeDate = !showPlantCounts ? true : false;
      name = '';
      break;
    case 'batch':
      name = getBatchFromSnapshot(item);
      break;
    case 'hook_distance':
      name = getReadibleLaneInfo(item.data.lane, item.data.sub_lane, item.data.position_id.hook,
          item.data.position_id.distance_previous_hook_rounded, includeLane);
      break;
    case 'location_id':
      name = item.data.location_id + ' (' + formatDateTime(new Date(item.collection_date)) + ')';
      break;
  }
  if (includeDate) {
    if (name !== '') {
      name += ' | ';
    }
    name += getReadibleDates(new Date(item.collection_date), true);
  }
  if (showPlantCounts) {
    if (name !== '') {
      name += ' | ';
    }
    const count = getPlantCount(item.data?.analysis_data?.plant_record_list, plantConfidence);
    name += `${count || 'No'} Plants Detected`;
  }
  return name;
};

export const getPlantCount = (plantRecordList, plantConfidence) => {
  let count = 0;
  for (const plant of plantRecordList) {
    if (plant?.detection_confidence > plantConfidence) {
      count++;
    }
  }
  return count;
};

export const operationRequiresInput = (operationType) => {
  const operations = [possibleOperations.ChangeStatus, possibleOperations.ChangeLane,
    possibleOperations.ChangeSubLane, possibleOperations.ChangeCrop, possibleOperations.DownloadImages];
  return operations.includes(operationType);
};

class DownloadError extends Error {
  constructor(message) {
    super(message);
    this.name = 'DownloadError';
  }
}

// Function to download a file
export const downloadFile = async (url) => {
  try {
    const response = await fetch(url);
    if (response.status !== 200) {
      throw new DownloadError(`Unable to download file. HTTP status: ${response.status}`);
    }
    return response.blob();
  } catch (error) {
    throw new DownloadError(`Unable to download file. Error: ${error.message}`);
  }
};

export const getSnapshotType = (snapshot) => {
  if (snapshot?.data?.position_id?.hook) {
    return 'hook_distance';
  }
  if (snapshot?.data?.batch_ids || snapshot?.data?.batch_id && snapshot?.data?.batch_id !== 'batchid1') {
    return 'batch';
  }
  if (snapshot?.data?.location_id) {
    return 'location_id';
  }
  return 'dates';
};

const getImage = async (snapshot, imagePath, imageNameType = 'snapshot_id') => {
  const url = await ApiClient.downloadImage(snapshot.client, imagePath);
  console.log('Downloading image: ' + url);
  let imageName = snapshot._id + '_' + imagePath.split('/').pop();
  if (imageNameType === 'human_readable') {
    imageName = getSnapshotTitle(snapshot, true, true).replace('| ', '');
    imageName += ' ' + imagePath.split('/').pop();
  } else if (imageNameType === 'morel_specific') {
    imageName = getMorelImageName(snapshot, imagePath.split('/').pop());
  }
  const blob = await downloadFile(url);
  if (blob === undefined) {
    throw new DownloadError(`Unable to download file.`);
  }
  return {'name': imageName, 'blob': blob};
};

export const performOperationOnSnapshot = async (snapshot, operationType, value, userConfig) => {
  switch (operationType) {
    case possibleOperations.Delete:
      return ApiClient.changeStatus(snapshot._id, 'deleted');
    case possibleOperations.ChangeStatus:
      return ApiClient.changeStatus(snapshot._id, value);
    case possibleOperations.ChangeLane:
      return ApiClient.changeLane(snapshot._id, value);
    case possibleOperations.ChangeSubLane:
      return ApiClient.changeSubLane(snapshot._id, value);
    case possibleOperations.ChangeCrop:
      return ApiClient.changeCrop(snapshot._id, value);
    case possibleOperations.ChangeProjectId:
      return ApiClient.changeProjectId(snapshot._id, value);
    case possibleOperations.ChangeAnalysisRecipeId:
      return ApiClient.changeAnalysisRecipeId(snapshot._id, value);
    case possibleOperations.ScheduleForAnalysis:
      await ApiClient.changeStatus(snapshot._id, 'created');
      await ApiClient.scheduleOneJob(snapshot._id);
      return;
    case possibleOperations.ScheduleForAnalysisInstance:
      const snapshotInstance = await ApiClient.findMatchingInstance(snapshot._id);
      if (snapshotInstance) {
        for (const snapshotId of snapshotInstance.snapshots) {
          await ApiClient.changeStatus(snapshotId, 'created');
          await ApiClient.scheduleOneJob(snapshotId);
        }
        return;
      }
      return performOperationOnSnapshot(snapshot, possibleOperations.ScheduleForAnalysis, value, userConfig);
    case possibleOperations.DownloadImages:
      const imagePath = snapshot['data']['images'][value];
      if (!imagePath) {
        return;
      }
      try {
        const imageNameType = userConfig.shopping_cart?.download_name ?? 'snapshot_id';
        console.log(imageNameType);
        const result = await getImage(snapshot, imagePath, imageNameType);
        return result;
      } catch (e) {
        console.log(e);
        return;
      }
    case possibleOperations.DownloadEntireSnapshot:
      const result = [];
      for (const imagePath of Object.values(snapshot['data']['images'])) {
        if (imagePath === undefined || imagePath === null) {
          continue;
        }
        try {
          const image = await getImage(snapshot, imagePath);
          image['name'] = snapshot._id + '/' + image['name'];
          result.push(image);
        } catch (e) {
          if (e.name === 'DownloadError') {
            continue;
          }
          throw e;
        }
      }
      const snapshotJson = JSON.stringify(snapshot);
      const snapshotBlob = new Blob([snapshotJson], {type: 'application/json'});
      result.push({'name': snapshot._id + '/' + 'snapshot.json', 'blob': snapshotBlob});
      return result;
    case possibleOperations.RemoveInstance:
      let instanceIds = [];
      const instance = await ApiClient.findMatchingInstance(snapshot._id);
      if (instance) {
        instanceIds = [instance._id];
      } else {
        instanceIds = await ApiClient.findAllMatchingInstances(snapshot);
      }
      for (const instanceId of instanceIds) {
        await ApiClient.removeInstanceById(instanceId);
      }
      return;
    case possibleOperations.CreateInstance:
      return ApiClient.createInstance(snapshot._id);
    case possibleOperations.MatchInstance:
      return ApiClient.matchSnapshotToExistingInstance(snapshot._id);
    case possibleOperations.CopySnapshotToClient:
      return ApiClient.copySnapshot(snapshot._id, value);
    case possibleOperations.ExportSnapshotIds:
      return snapshot._id;
    case possibleOperations.ExportData:
      return snapshot;
  }
};

export async function zipBlobs(filesArray) {
  try {
    const zip = new JSZip();
    for (let i = 0; i < filesArray.length; i++) {
      const {name, blob} = filesArray[i];
      const blobData = await blob.arrayBuffer();
      zip.file(name, blobData);
    }
    return await zip.generateAsync({type: 'blob'});
  } catch (error) {
    throw new SnapshotOperationError(`Error zipping blobs ${error}`);
  }
}

const makeZipAndDownload = async (filesArray, name) => {
  const downloadLink = document.createElement('a');
  const zip = await zipBlobs(filesArray);
  downloadLink.href = URL.createObjectURL(zip);
  downloadLink.download = name;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  setTimeout(() => {
    URL.revokeObjectURL(downloadLink.href);
    document.body.removeChild(downloadLink);
  }, 100);
};

const downloadBlob = (blob, name) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = name;
  document.body.appendChild(downloadLink);
  downloadLink.click();
};

export const aggregateOperationOnResults = async (results, operationType) => {
  switch (operationType) {
    case possibleOperations.DownloadImages:
      await makeZipAndDownload(results, 'images.zip');
      return;
    case possibleOperations.DownloadEntireSnapshot:
      await makeZipAndDownload(results, 'snapshots.zip');
      return;
    case possibleOperations.ExportSnapshotIds:
      const snapshotIds = results.join('\n');
      const blob = new Blob([snapshotIds], {type: 'text/plain'});
      downloadBlob(blob, 'snapshotIds.txt');
      return;
    case possibleOperations.ExportData:
      const result = await ApiClient.getErpExport(results);
      const mime = mimeDb[result.data.type];
      const fileExtensions = mime.extensions[0];
      downloadBlob(result.data, 'export' + '.' + fileExtensions);
      return;
  }
};

export const getBatchFromSnapshot = (snapshot) => {
  if (snapshot.data?.batch_id) {
    return snapshot.data.batch_id;
  }
  if (snapshot.data?.batch_ids) {
    const batchIds = snapshot.data.batch_ids.map((batch) => batch.batch_id);
    return batchIds.join(', ');
  }
  return '';
};
