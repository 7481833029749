import axios from 'axios';

export class APIClientError extends Error {
  constructor(message) {
    super(message);
    this.name = 'APIClientError';
  }
}

export class ApiClient {
  static client;
  static baseUrl;
  static history;

  static initialize(baseUrl, credentials = '', history = null) {
    this.baseUrl = baseUrl;
    this.defaultHeaders = {
      'Authorization': credentials,
      'Content-Type': 'application/json',
    };
    this.client = axios.create({
      baseURL: baseUrl,
      headers: this.defaultHeaders,
    });
    this.history = history;
  }

  static setHistory(history) {
    this.history = history;
  }

  static setBaseUrl(url) {
    this.client.defaults.baseURL = url;
  }

  static setCredentials(credentials) {
    this.defaultHeaders.Authorization = credentials;
    this.client.defaults.headers.Authorization = credentials;
  }

  static buildMongoQuery(client, startDate, endDate, status) {
    return [
      {
        '$match': this.buildMatchFilter(client, startDate, endDate, status),
      },
    ];
  }

  static buildMatchFilter(client, startDate, endDate, status, onlyDiseased = false) {
    const matchFilter = {
      'collection_date': {
        '$gte': startDate,
        '$lt': endDate,
      },
    };

    if (client) {
      matchFilter['client'] = client;
    }

    if (onlyDiseased) {
      matchFilter['data.analysis_data.plant_record_list'] = {'$elemMatch': {'healthy': 0}};
    }
    if (status != null && status !== 'any') {
      matchFilter['status'] = status;
    }
    return matchFilter;
  }

  static async findCurrentJobs() {
    const oneWeekBack = new Date();
    oneWeekBack.setDate(oneWeekBack.getDate() - 7);
    const result = await this._fetch('POST', '/jobs/find', {
      'updatedAt': {'$gte': oneWeekBack},
    });
    return result?.data?.data ? result?.data?.data : [];
  }

  static async cancelJob(name) {
    const result = await this._fetch('DELETE', '/jobs/deleteManyByName', {'name': name});
    return result?.data?.data;
  }

  static async findInstanceById(instanceId) {
    const result = await this._fetch('POST', '/instances/findOneById', {
      '_id': instanceId,
    });
    const instance = result?.data?.data;
    return instance ? instance[0] : null;
  }

  static async findMatchingInstance(snapshotId) {
    if (!snapshotId) {
      return null;
    }
    try {
      const result = await this._fetch('GET', '/instances/findMatch', null, null, {'id': snapshotId});
      if (result?.status === 200) {
        return result?.data?.data;
      }
      return null;
    } catch (e) {
      // do not show server error in the console if the request fails
      return null;
    }
  }

  static async findAllMatchingInstances(snapshot) {
    const body = {'client': snapshot.client, 'snapshots': snapshot._id};
    const instances = await this._fetch('POST', '/instances/find', body, null);
    const result = [];
    if (instances?.status === 200) {
      for (const instance of instances?.data?.data) {
        if (instance.snapshots.includes(snapshot._id)) {
          result.push(instance._id);
        }
      }
    }
    return result;
  }


  static async removeInstanceById(instanceId) {
    const result = await this._fetch('POST', '/instances/removeOneById', {
      '_id': instanceId,
    });
    return result?.data?.data;
  }

  static async createInstance(snapshotId) {
    const result = await this._fetch('POST', '/instances/create', {}, null, {
      'snapshot_id': snapshotId,
    });
    return result?.data?.data;
  }

  // Snapshots
  static async findSnapshotById(snapshotId) {
    const result = await this._fetch('POST', '/snapshots/findOneById', {
      '_id': snapshotId,
    });
    const snapshots = result?.data?.data;
    return snapshots ? snapshots[0] : null;
  }

  static async aggregateSnapshotsByClient(client, startDate, endDate, status = null) {
    const mongoQuery = this.buildMongoQuery(client, startDate, endDate, status);
    const response = await this._fetch('POST', '/snapshots/aggregate', [
      mongoQuery,
    ]);
    return response?.data?.data ?? [];
  }

  static async findDaysWithDisease(client, startDate, status) {
    const today = new Date();
    const matchFilter = this.buildMatchFilter(client, startDate, today, status, true);
    const query = [
      {
        '$match': matchFilter,
      },
      {
        '$group': {
          '_id': 'null',
          'distinctDates': {
            '$addToSet': {
              '$dateToString': {
                'date': '$collection_date', 'format': '%Y-%m-%d',
              },
            },
          },
        },
      },
    ];
    const response = await this._fetch('POST', '/snapshots/aggregate', query);
    if (response['data']['success'] === false) {
      return new Set();
    }
    return new Set(response?.data?.data[0]?.distinctDates) ?? new Set();
  }

  static async copySnapshot(snapshotId, client) {
    const query = {
      '_id': snapshotId,
      'client': client,
    };
    const response = await this._fetch('POST', '/snapshots/makeCopy', query);
    return response?.data?.data ?? null;
  }

  static async findDaysWithUploads(client, status, onlyDiseased = false, monthsToCheck = 8) {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth() - monthsToCheck, 1);
    if (onlyDiseased) {
      return this.findDaysWithDisease(client, startDate, status);
    } else {
      const dayCounts = await this.findCounts(client, startDate, today, 1, status);
      const days = new Set(dayCounts.map(({day}) => new Date(day).toISOString().slice(0, 10)));
      return days;
    }
  }

  static async findSnapshotsByClient(startDate, endDate, status = null,
      otherFilters = null, includeAnalysisData = false, client=null) {
    let query = this.buildMatchFilter(client, startDate, endDate, status);
    if (otherFilters) {
      if (typeof otherFilters === 'string') {
        try {
          otherFilters = JSON.parse(otherFilters);
        } catch (e) {
          console.log(e);
          return [];
        }
      }
      if (Object.keys(otherFilters).length > 0) {
        query = Object.assign({}, query, otherFilters);
      }
    }
    if (!includeAnalysisData) {
      query = [query, {'data.analysis_data': 0}];
    } else {
      query = [query];
    }
    try {
      const response = await this._fetch('POST', '/snapshots/find', query);
      return response?.data?.data ?? [];
    } catch (e) {
      return [];
    }
  }

  static async findOverviewMapData(client, startDate, endDate, includeAnnotations = false) {
    const data = {
      'client': client,
      'collection_date': {'$gte': startDate, '$lte': endDate},
      'include_annotations': includeAnnotations,
    };
    const response = await this._fetch('POST', '/overview-map', data);
    return response?.data?.data ?? [];
  }

  static async findSnapshotsByBatchId(batchId, client) {
    const query = {
      'client': client,
      'data.batch_id': batchId,
    };
    const response = await this._fetch('POST', '/snapshots/find', query);
    return response?.data?.data ?? [];
  }

  static async findSnapshotsById(ids, client) {
    const query = {
      '_id': {'$in': ids},
    };
    const response = await this._fetch('POST', '/snapshots/find', query);
    return response?.data?.data ?? [];
  }

  static async findInstances(startDate, endDate, client) {
    const query = {
      'client': client,
      'created': {
        '$gte': startDate,
        '$lt': endDate,
      },
    };
    const response = await this._fetch('POST', '/instances/find', query);
    return response?.data?.data ?? [];
  }

  static async deleteInstance(id) {
    const response = await this._fetch('POST', '/instances/removeOneById', {'_id': id});
    return response?.data?.data ?? null;
  }

  static async matchSnapshotToExistingInstance(snapshotId) {
    const instanceId = await this.findMatchingInstance(snapshotId);
    if (!instanceId) {
      return null;
    }
    const response = await this._fetch('POST', '/instances/linkSnapshot',
        {'_id': instanceId, 'snapshot_id': snapshotId});
    return response?.data?.data ?? null;
  }

  static async createSnapshot(snapshot) {
    const response = await this._fetch('POST', '/snapshots/insertOne', snapshot);
    if (response?.status !== 200) {
      throw new APIClientError(response?.data?.message);
    }
    const snapshotResult = response?.data?.data;
    if (snapshotResult) {
      return snapshotResult;
    } else {
      throw new APIClientError(response?.data?.message);
    }
  }

  static async changeLane(snapshotId, newLane) {
    const query = {
      '_id': snapshotId,
      'data.lane': newLane,
    };
    const response = await this._fetch('POST', '/snapshots/updateOne', query);
    return response?.data?.success ?? false;
  }

  static async changeSubLane(snapshotId, newSubLane) {
    const query = {
      '_id': snapshotId,
      'data.sub_lane': newSubLane,
    };
    const response = await this._fetch('POST', '/snapshots/updateOne', query);
    return response?.data?.success ?? false;
  }

  static async changeCrop(snapshotId, newCrop) {
    const query = {
      '_id': snapshotId,
      'data.crop': newCrop,
    };
    const response = await this._fetch('POST', '/snapshots/updateOne', query);
    return response?.data?.success ?? false;
  }

  static async changeProjectId(snapshotId, newProjectId) {
    const query = {
      '_id': snapshotId,
      'project': newProjectId,
    };
    const response = await this._fetch('POST', '/snapshots/updateOne', query);
    return response?.data?.success ?? false;
  }

  static async changeAnalysisRecipeId(snapshotId, newRecipeId) {
    const query = {
      '_id': snapshotId,
      'data.analysis_pipeline_name': newRecipeId,
    };
    const response = await this._fetch('POST', '/snapshots/updateOne', query);
    return response?.data?.success ?? false;
  }

  static async changeStatus(snapshotId, newStatus) {
    const query = {
      '_id': snapshotId,
      'status': newStatus,
    };
    const response = await this._fetch('POST', '/snapshots/updateOne', query);
    return response?.data?.success ?? false;
  }

  static async uploadMorelDataSheet(csvFile) {
    const response = await this._fetch('POST', '/morel/trayInformation', {
      sheet: csvFile.get('File'),
    }, {
      'content-type': 'multipart/form-data',
    });
    return response;
  }

  static async getMorelTrayInformation(startingDate) {
    const extraHeader = {'planting-date': startingDate.toISOString()};
    return await this._fetch('GET', '/morel/trayInformation', {}, extraHeader);
  }

  static async deleteMorelTrayInformation(id) {
    return await this._fetch('DELETE', '/morel/trayInformation', {}, {'id': id});
  }

  static async uploadHarvestLondonDataSheet(csvFile) {
    const response = await this._fetch('POST', '/harvest-london/trayInformation', {
      sheet: csvFile.get('File'),
    }, {
      'content-type': 'multipart/form-data',
    });
    return response;
  }

  static async getHarvestLondonTrayInformation() {
    return await this._fetch('GET', '/harvest-london/trayInformation', {});
  }

  static async deleteHarvestLondonTrayInformation(id) {
    return await this._fetch('DELETE', '/harvest-london/trayInformation', {'_id': id});
  }

  // Images
  static async downloadImage(client, fileName) {
    const response = await this._fetch('POST', '/images/download', {
      filename: fileName,
      client: client,
    });
    return response?.data?.data ?? '';
  }

  static async uploadImage(client, fileName, fileBlob) {
    const response = await this._fetch('POST', '/images/upload', {
      'client': client,
      'filename': fileName,
    });
    const uploadUrlSignedS3 = response?.data?.data ?? '';
    const uploadResponse = await axios(
        {
          method: 'put',
          url: uploadUrlSignedS3,
          data: fileBlob,
          responseType: 'arraybuffer',
        });
    return uploadResponse;
  }

  static async sendDailyReport(selectedClient, fromDate, toDate, email, device) {
    const response = await this._fetch('POST', '/daily-reports/send', {
      beginDateTime: fromDate,
      endDateTime: toDate,
      clientName: selectedClient,
      clientDevice: device,
      clientEmails: email,
    });
    return response;
  }

  // Comments
  static async findAllCommentsForClient(client) {
    const response = await this._fetch('POST', '/comments/find', {
      'snapshot_client': client,
    });
    return response?.data?.data ?? [];
  }

  static async scheduleOneJob(snapshotId) {
    const response = await this._fetch('POST', '/jobs/single', {
      '_id': snapshotId,
    });
    return response;
  }

  static async uploadJob(jobInput) {
    let query = {};
    if (jobInput.secondDate == null || jobInput.secondDate === '') {
      query = {
        'client': jobInput.client,
        'collection_date': jobInput.collection_date,
        'name': jobInput.name,
      };
    } else {
      query = {
        'client': jobInput.client,
        'start_date': jobInput.collection_date,
        'end_date': jobInput.secondDate,
        'name': jobInput.name,
      };
    }
    if (jobInput.status !== null && jobInput.status !== 'any') {
      query['status'] = jobInput.status;
    } else {
      query['status'] = {'$ne': 'deleted'};
    }
    if (jobInput.priority !== null) {
      query['priority'] = jobInput.priority;
    }
    if (jobInput.customProject !== null) {
      query['custom_project'] = jobInput.customProject;
    }
    if (jobInput.inBedPosition !== null) {
      const inBedPosition = jobInput.inBedPosition;
      if (inBedPosition.lane) {
        query['data.lane'] = inBedPosition.lane;
      }
      if (inBedPosition.sublane) {
        query['data.sub_lane'] = inBedPosition.subLane;
      }
      if (inBedPosition.hook) {
        query['data.position_id.hook'] = parseInt(inBedPosition.hook);
      }
      if (inBedPosition.distance) {
        query['data.position_id.distance_previous_hook_rounded'] = parseFloat(inBedPosition.distance);
      }
    }
    const response = await this._fetch('POST', '/jobs/scheduleBatch', query);
    if (response.status == 200) {
      return null;
    }
    return response?.data?.message ?? 'Unknown error';
  }

  static async findCommentsBySnapshotId(id) {
    const response = await this._fetch('POST', '/comments/find', {
      'snapshot_id': id,
    });
    return response?.data?.data ?? [];
  }

  static async insertComment(message, snapshotId, notifyAdi) {
    return await this._fetch('POST', '/comments/insertOne', {
      'message': message,
      'snapshot_id': snapshotId,
      'notify_adi': notifyAdi,
    });
  }

  // Annotations old
  static async findAnnotationsBySnapshotIds(snapshotIds) {
    const response = await this._fetch('POST', '/annotations/find', {
      'snapshot_id': {'$in': snapshotIds},
    });
    return response?.data?.data ?? [];
  }

  static async insertAnnotation(annotationData) {
    const response = await this._fetch('POST', '/annotations/insertOne', annotationData);
    return response?.data ?? [];
  }

  static async removeAnnotationById(id) {
    return await this._fetch('POST', '/annotations/removeOneById', {
      '_id': id,
    });
  }

  // Annotations new
  static async findAnnotations(client, query) {
    if (!client || !query) {
      return;
    };
    const clientQuery = {'client': client, ...query};
    const response = await this._fetch('GET', '/plantAnnotation', null, null, clientQuery);
    return response?.data?.data ?? [];
  }

  static async removePlantAnnotation(annotation) {
    return await this._fetch('DELETE', '/plantAnnotation', {}, null, {'id': annotation._id});
  }

  static async setAnnotationLabel(annotation, label) {
    return this._fetch('POST', '/plantAnnotation', {
      '_id': annotation._id,
      'label': label,
    });
  }

  static async generatePlantAnnotation(snapshotId, plantIds) {
    return this._fetch('POST', '/generatePlantAnnotation', {
      'snapshot_id': snapshotId,
      'plant_ids': plantIds,
    });
  }

  static async getImageUrlAnnotation(annotation) {
    return await this.downloadImage(annotation.client, annotation.image_location);
  }

  static async getHarvestLondonBatches() {
    return await this._fetch('GET', '/harvestLondonBatch');
  }

  static async sendHarvestLondonCrop(fromDate, selectedCrop, batch) {
    return await this._fetch('POST', '/harvestLondonBatch', {
      'first_day_visible_to_camera': fromDate,
      'batch': batch,
      'crop': selectedCrop,
    });
  }

  static async pingRoot(url) {
    const pingClient = axios.create({
      baseURL: url,
    });
    return pingClient.request({
      method: 'GET',
      url: '/',
    });
  }

  // Users
  /**
     * Tries to get the current user with the stored credentials
     */
  static async getLoggedInUser() {
    const credentials = this.client.defaults.headers.Authorization;
    return this.loginWithCredentials(
        credentials,
    );
  }

  static async loginWithUsernameAndPassword(username, password) {
    const credentials = 'Basic ' + btoa(username + ':' + password);
    return this.loginWithCredentials(credentials);
  }

  static async loginWithToken(token, addBearer = true) {
    if (addBearer) {
      token = 'Bearer ' + token;
    }
    return this.loginWithCredentials(token);
  }

  static async loginWithCredentials(credentials) {
    const result = await this._fetch('POST', '/users/login', {},
        {
          'Authorization': credentials,
        },
    );
    return result?.data;
  }

  static async changePassword(newPassword) {
    const result = await this._fetch('POST', '/users/changePassword', {
      newPassword: newPassword,
    });
    return result?.data?.success;
  }

  static _fetch(method, route, data = {}, headers = {}, params = {}, responseType = 'json') {
    headers = Object.assign({}, this.defaultHeaders, headers);
    const history = this.history;
    return this.client.request({
      method: method,
      url: route,
      data: data,
      headers: headers,
      params: params,
      responseType: responseType,
    },
    ).catch(function(error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.status === 401 && history) {
          history.push('/login');
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
        if (error.request.status === 401 && history) {
          history.push('/login');
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      return error.response;
    });
  }


  static async findCounts(client, startDay, endDay, minCount, status = null) {
    const query = {
      'client': client,
      'day': {
        '$gte': startDay,
        '$lt': endDay,
      },
      'count': {'$gte': minCount},
      'isAll': false,
    };
    if (status !== null) {
      query['status'] = status;
    }
    const response = await this._fetch('POST', '/counts/find', query);
    return response?.data?.data ?? [];
  }

  static async getSystemLogs(fromDate, toDate, client) {
    const query = {
      'createdAt': {'$gte': fromDate.toISOString(), '$lte': toDate.toISOString()},
      'client': client,
    };
    const response = await this._fetch('POST', '/logs/find', query);
    return response?.data?.data ?? [];
  }

  static async getSystemLogDownloadUrl(id) {
    const result = await this._fetch('GET', '/logs/getDownloadUrl', null, {'id': id});
    return result?.data?.data?.url ?? null;
  }

  static async getJobs(startDate, endDate, status = null) {
    const query = {
      'updatedAt': {
        '$gte': startDate,
        '$lt': endDate,
      },
    };
    if (status) {
      query['status'] = status;
    }
    const response = await this._fetch('POST', '/jobs/find', query);
    return response?.data?.data ?? [];
  }

  static async getErpExport(snapshots) {
    const snapshotIds = [];
    snapshots.forEach((snapshot) => {
      snapshotIds.push(snapshot._id);
    });
    const result = await this._fetch('POST', '/erp/export-data', {'snapshot_ids': snapshotIds}, null, null, 'blob');
    return result;
  }

  static async getGeneric(route, query) {
    const response = await this._fetch('GET', route, null, null, query);
    return response?.data?.data ?? [];
  }

  static async getLaneBatches(client, startDate, endDate, lane, subLane) {
    const response = await this._fetch('GET', '/lane-batches', null, null, {
      'client': client,
      'startDate': startDate,
      'endDate': endDate,
      'lane': lane,
      'subLane': subLane,
    });
    console.log(response);
    return response?.data?.data ?? [];
  }

  static async insertLaneBatchInformationDeliflor(batch, client, lane, subLane, startDistance, endDistance, erpType) {
    const response = await this._fetch('POST', '/laneBatchInformation/insert', {
      'batch': batch,
      'client': client,
      'lane': lane,
      'sub_lane': subLane,
      'start_distance': startDistance,
      'end_distance': endDistance,
      'erp_type': erpType,
    });
    return response?.data?.data ?? [];
  }

  static async getLaneBatchInformation(batch, generalLocationId) {
    const response = await this._fetch('GET', '/laneBatchInformation', null, null, {
      'batch': batch,
      'general_location_id': generalLocationId,
    });
    return response?.data?.data ?? [];
  }

  static async getLaneInformationForSnapshot(snapshot) {
    if (!snapshot) {
      return null;
    }
    try {
      const headers = {
        'Authorization': this.defaultHeaders.Authorization,
      };
      const response = await axios.get(this.baseUrl + '/laneBatchInformation', {
        headers: headers,
        data: snapshot,
      });
      return response?.data?.data ?? [];
    } catch (e) {
      return null;
    }
  }

  // Get Status Logs
  static async findStatusLogs() {
    const result = await this._fetch('GET', '/status-logs/find');
    const statusLogs = result?.data?.data;
    return statusLogs ? statusLogs : null;
  }

  // Get Batches
  static async findBatches(greenhouse) {
    const result = await this._fetch('GET', `/v2/batches?greenhouse_id=${greenhouse}`);
    const batches = result?.data?.batches;
    return batches ? batches : null;
  }

  // Get Ground Calibrations
  static async findGroundCalibrations(client, lane, sublane) {
    const result = await this._fetch('GET', `/v2/ground-calibrations?client=${client}&lane=${lane}&sublane=${sublane}`);
    const calibrations = result?.data?.ground_calibrations;
    return calibrations ? calibrations : null;
  }
}
