
import {add, differenceInCalendarDays, format} from 'date-fns';

export class DateAxis {
  static getStartAndEndDate = (dates) => {
    dates.sort((a, b) => (new Date(a) - new Date(b)));
    const startDate = new Date(dates[0]);
    const endDate = new Date(dates[dates.length - 1]);
    return {startDate, endDate};
  };

  static dateFormatter = (date, short) => {
    if (short) {
      return format(new Date(date), 'dd/MMM');
    }
    return format(new Date(date), 'dd-MM-yyyy HH:mm');
  };

  // https://codesandbox.io/s/recharts-area-chart-with-date-axis-6o55k?file=/src/DateArea.js:2101-2111
  static getTicks = (startDate, endDate, num) => {
    const diffDays = differenceInCalendarDays(endDate, startDate);
    const current = startDate;
    const velocity = Math.round(diffDays / (num - 1));
    const ticks = [startDate.getTime()];
    for (let i = 1; i < num - 1; i++) {
      ticks.push(add(current, {days: i * velocity}).getTime());
    }
    ticks.push(endDate.getTime());
    return ticks;
  };


  // https://codesandbox.io/s/recharts-area-chart-with-date-axis-6o55k?file=/src/DateArea.js:2101-2111
  static fillTicksData = (_ticks, data) => {
    const newTicks = [..._ticks];
    const filled = [];
    let currentTick = newTicks.shift();
    for (const it of data) {
      if (newTicks.length && it.time === currentTick) {
        currentTick = newTicks.shift();
        filled.push(it);
      } else {
        filled.push(it);
      }
    }
    return filled;
  };
}
