import {Grid} from '@mui/material';
import Typography from '@material-ui/core/Typography';
import {rgb2hsv, getAverageColor} from '../../Utils/ColorUtils.js';
import {DataFormatter} from '../Charts/DataFormatter';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export const DisplayMetric = {
  CHRYSANTHEMUM_DETAILS: 'chrysanthemum_details',
  FLOWER_UNIFORMITY: 'flower_uniformity',
  PLANT_UNIFORMITY: 'plant_uniformity',
  SEED_RATIO: 'seed_ratio',
  USABLE_RATIO: 'usable_ratio',
  CLASSES_COUNT: 'classes_count',
  TOTAL_COUNT: 'total_count',
  CLASSES_COUNT_REVERSED: 'classes_count_reversed',
  CLASSES_COUNT_DISEASED: 'classes_count_diseased',
  EXPECTED_COUNT: 'expected_count',
  STD_COMPARTMENTS: 'std_compartments',
  COMPARTMENTS: 'compartments',
  MOREL_COMPARTMENTS: 'morelCompartments',
  USABLES: 'usables',
  GROUND_COVERAGE: 'ground_coverage',
  EMERGING_LEAF_POINT: 'emerging_leaf_point',
  LEAF_SPAN: 'leaf_span',
  USABLES_SIMPLE: 'usables_simple',
  USABLE_RATIO_ADVANCED: 'usable_ratio_advanced',
  GERMINATION_RATIO_ADVANCED: 'germination_ratio_advanced',
  COLORS: 'colors',
  BREAK: 'br',
  TRAY_INFO: 'tray_info',
  MARKERS: 'markers',
  PLANTS_PER_SQUARE_METER: 'plants_per_square_meter',
};

export class SimpleCalculations {
  static calculateAverageCurvature(plantList) {
    let totalCurvature = 0;
    let numberConsidered = 0;
    for (let i = 0; i < plantList.length; i++) {
      if (plantList[i]['emerging_leaf_point_height'] !== undefined) {
        totalCurvature += Math.abs(plantList[i]['emerging_leaf_point_height'] - plantList[i]['height']);
        numberConsidered += 1;
      }
    }
    return totalCurvature / numberConsidered;
  }

  static calculateAverageSpan(plantList) {
    let averageSpan = 0;
    let numberConsidered = 0;
    for (let i = 0; i < plantList.length; i++) {
      if (plantList[i]['spans'] !== undefined) {
        for (let j = 0; j < plantList[i]['spans'].length; j++) {
          averageSpan += plantList[i]['spans'][j];
          numberConsidered += 1;
        }
      }
    }
    return averageSpan / numberConsidered;
  }

  static calculateAverageBudSize(plant) {
    if (plant?.buds.length === 0 || plant?.buds === undefined) {
      return 0;
    }
    let averageSize = 0;
    for (let i = 0; i < plant.buds.length; i++) {
      averageSize += plant.buds[i].diameter;
    }
    return Number((averageSize / plant.buds.length / 10).toFixed(2));
  }

  static calculateFlowersPerPlant(analysisData) {
    const plantList = analysisData.plant_record_list;
    let totalFlowers = 0;
    for (let i = 0; i < plantList.length; i++) {
      const plantRecord = plantList[i];
      if (plantRecord.buds != null) {
        totalFlowers += plantRecord.buds.length;
      }
    }
    const flowerPerPlant = totalFlowers / plantList.length;
    return Math.round(flowerPerPlant * 10) / 10;
  }

  static calculateTotalPlantedCells(analysisData) {
    let total = 0;
    for (const compartment of Object.values(analysisData.compartments)) {
      total += compartment.totalCellCount;
    }
    return total;
  }

  static calculateUsableRatio(analysisData) {
    const ratio = (analysisData.countClass2 + analysisData.countClass3) / analysisData.totalCellCount;
    return Math.round(ratio * 100) + '%';
  }

  static calculateGerminationRatio(analysisData) {
    const ratio = (analysisData.countClass1 + analysisData.countClass2 +
      analysisData.countClass3) / analysisData.totalCellCount;
    return Math.round(ratio * 100) + '%';
  }

  static calculateUsableRate(analysisData) {
    return (analysisData.countClass2 + analysisData.countClass3) + ' / ' + analysisData.totalCellCount;
  }

  static calculateGerminationRate(analysisData) {
    return (analysisData.countClass1 + analysisData.countClass2 +
      analysisData.countClass3) + ' / ' + analysisData.totalCellCount;
  }

  static calculateSeedRatio(analysisData) {
    const s = analysisData['seedsPerCell'];
    const p = analysisData['plantsPerCell'];
    return p + '/' + s;
  }

  static calculateStandardDeviation(arr) {
    const mean = arr.reduce((acc, curr) => {
      return acc + curr;
    }, 0) / arr.length;
    arr = arr.map((k) => {
      return (k - mean) ** 2;
    });
    const sum = arr.reduce((acc, curr) => acc + curr, 0);
    return Math.round(Math.sqrt(sum / arr.length) * 100) / 100;
  }

  static calculateAveragePlantDimension(plant) {
    if (plant === undefined) {
      return;
    }
    return Math.round((plant.dimensions[0] + plant.dimensions[1]) / 2 * 100);
  }
}

export class DisplayDivs {
  static getUsablesDictionary(analysisData, t) {
    if (!ContainsData.usablesDictionarySet(analysisData)) {
      return null;
    }

    const topNames = Object.keys(analysisData.usables_dictionary);
    const percentages = topNames.map((n) => Math.round(n.split('_')[1]));
    percentages.sort();
    const readibleClassNames = percentages.map((p) => 'Top ' + p.toString() + '%');
    let content = '';
    for (let i = 0; i < percentages.length; i++) {
      if (content !== '') {
        content += '\n';
      }
      const v = Math.round(analysisData.usables_dictionary['top_' + percentages[i].toString()]);
      content += readibleClassNames[i] + ': ' + Math.round(100 * v / analysisData.totalCellCount) +
        '% (' + v + ' / ' + analysisData.totalCellCount + ') ';
    }
    return <Grid item xs={6}>
      <Typography variant="body2" color="textSecondary"><b>{t('Assumed ratio perfect plants') + ':'}</b></Typography>
      <Typography variant="body2" color="textSecondary" style={{whiteSpace: 'pre-line'}}>{content}</Typography>
    </Grid >;
  }

  static getSTDCompartments(analysisData, t) {
    if (!ContainsData.tilesSet(analysisData)) {
      return null;
    }

    const nameClasses = ['0', '1', '2', '3'];
    const compartmentPercentages = [];
    const compartmentAbsolutes = [];
    const totalCellCount = analysisData.totalCellCount;
    const compartmentCellCount = (totalCellCount / 4);
    for (let i = 0; i < nameClasses.length; i++) {
      const className = nameClasses[i];
      const v = Math.round(analysisData.tiles[className]);
      compartmentPercentages.push(v / compartmentCellCount * 100);
      compartmentAbsolutes.push(v);
    }

    const percentageStdDeviation = SimpleCalculations.calculateStandardDeviation(compartmentPercentages);
    const absoluteStdDeviation = SimpleCalculations.calculateStandardDeviation(compartmentAbsolutes);
    return <Grid item xs={12}>
      <Typography color="textSecondary">
        {t('Standard Deviation') + ' (' + t('Percentage') + ')' + ': ' + percentageStdDeviation}
      </Typography>
      <Typography color="textSecondary">
        {t('Standard Deviation') + ' (' + t('Absolute') + ')' + ': ' + absoluteStdDeviation}
      </Typography>
    </Grid>;
  }

  static getCompartments(analysisData, t) {
    if (!ContainsData.tilesSet(analysisData)) {
      return null;
    }
    const nameClasses = ['0', '1', '2', '3'];
    const readibleClassNames = [t('Top Left'), t('Top Right'), t('Bottom Left'), t('Bottom Right')];
    let content = '';
    const compartmentValues = [];
    const totalCellCount = analysisData.totalCellCount;
    const compartmentCellCount = (totalCellCount / 4);
    for (let i = 0; i < nameClasses.length; i++) {
      const className = nameClasses[i];
      if (content !== '') {
        content += '\n';
      }
      const v = Math.round(analysisData.tiles[className]);
      content += readibleClassNames[i] + ': ' + Math.round(100 * v / compartmentCellCount) +
        '% (' + v + ' / ' + compartmentCellCount + ') ';
      compartmentValues.push(v / compartmentCellCount);
    }
    return <Grid item xs={6}>
      <Typography variant="body2" color="textSecondary"><b>{t('Compartments') + ':'}</b></Typography>
      <Typography variant="body2" color="textSecondary" style={{whiteSpace: 'pre-line'}}>{content}</Typography>
    </Grid >;
  }

  static returnBreak() {
    return <Grid item xs={12}><br /></Grid>;
  }

  static getMorelCompartments(analysisData, t) {
    if (!ContainsData.compartmentsSet(analysisData)) {
      return null;
    }
    return <Grid item xs={12}>{Object.entries(analysisData.compartments).map(
        ([key, info]) => DisplayDivs.morelCompartmentInfo(info, t))}</Grid>;
  }

  static morelCompartmentInfo(item, t) {
    const usables = item.countClass2 + item.countClass3;
    const total = item.totalCellCount;
    const ratio = Math.round(100 * usables / total);
    let absoluteValues = t('Class 1') + ': ' + item.countClass1 + ', ' + t('Class 2') + ': ' +
      item.countClass2 + ', ' + t('Class 3') + ': ' + item.countClass3;
    if (item?.countDiseased) {
      absoluteValues = t('Deviators') + ': ' + item.countDiseased + ', ' + absoluteValues;
    }
    const totalValues = ratio + '% (' + usables + ' / ' + total + ')';
    return <Grid item xs={12}>
      <Typography variant="body2" color="textSecondary"><b>{t('Compartment') + ': '}{item.name}</b></Typography>
      <Typography variant="body2" color="textSecondary">{absoluteValues}</Typography>
      <Typography variant="body2" color="textSecondary">{totalValues}</Typography>
    </Grid>;
  }

  static getClassesCountDiseased(analysisData, t, data) {
    let diseased = false;
    if (data.crop === 'Cyclamen roots') {
      diseased = true;
    }
    return DisplayDivs.getClassesCount(analysisData, t, diseased);
  }

  static getTotalCount(analysisData, t) {
    const classNames = ['countClass1', 'countClass2', 'countClass3'];
    for (let i = 0; i < classNames.length; i++) {
      if (!ContainsData.checkNested(analysisData, classNames[i])) {
        return null;
      }
    }
    const totalCount = (analysisData.countClass1 + analysisData.countClass2 + analysisData.countClass3);
    return (
      <Typography color="textSecondary">
        {t('Total count') + ': ' + totalCount}
      </Typography>
    );
  }

  static getClassesCount(analysisData, t, diseased = false, reverseOrder = false) {
    let nameClasses = ['countClass1', 'countClass2', 'countClass3'];
    let readibleClassNames = [t('Class 1'), t('Class 2'), t('Class 3')];
    if (diseased === true) {
      nameClasses = ['countDiseased', 'countClass1', 'countClass2', 'countClass3'];
      readibleClassNames = [t('Mold'), t('Class 1'), t('Class 2'), t('Class 3')];
    }
    if (reverseOrder) {
      nameClasses = nameClasses.reverse();
      readibleClassNames = readibleClassNames.reverse();
    }
    let content = '';
    for (let i = 0; i < nameClasses.length; i++) {
      const className = nameClasses[i];
      if (ContainsData.checkNested(analysisData, className)) {
        content += readibleClassNames[i] + ': ' + Math.round(analysisData[className]) + ' ';
      }
    }
    return <Grid item xs={12}><Typography color="textSecondary">{content}</Typography></Grid>;
  }

  static getClassesCountReversed(analysisData, t) {
    return DisplayDivs.getClassesCount(analysisData, t, false, true);
  }

  static getExpected(analysisData, t) {
    if (!ContainsData.checkNested(analysisData, 'expected_count')) {
      return null;
    }
    return <Grid item xs={12}><Typography
      color="textSecondary">{'Expected Count: ' + Math.round(analysisData.expected_count)}</Typography></Grid>;
  }

  static getPlantUniformity(analysisData, t) {
    if (!ContainsData.plantHeightUniformitySet(analysisData)) {
      return null;
    }
    return <Grid item xs={12}><Typography color="textSecondary">
      Plant height uniformity: {analysisData.plant_height_uniformity.classification} / 5</Typography></Grid>;
  }

  static getFlowerUniformity(analysisData, t) {
    if (!ContainsData.plantFlowerUniformitySet(analysisData)) {
      return null;
    }
    return <Grid item xs={12}><Typography color="textSecondary">
      Plant flower uniformity: {analysisData.plant_flower_uniformity.classification} / 5</Typography></Grid>;
  }

  static getSeedRatio(analysisData, t) {
    if (!ContainsData.seedRatioSet(analysisData)) {
      return null;
    }

    return <Grid item xs={12}>
      <Typography
        color="textSecondary">{t('Plant/seed ratio per cell') + ': ' +
          SimpleCalculations.calculateSeedRatio(analysisData)}
      </Typography>
    </Grid>;
  }

  static getUsableRatio(analysisData, t) {
    if (!ContainsData.classCountsSet(analysisData)) {
      return null;
    }

    return <Grid item xs={12}><Typography color="textSecondary">
      {t('Usable ratio') + ': ' + (analysisData.countClass2 + analysisData.countClass3) +
        ' / ' + SimpleCalculations.calculateTotalPlantedCells(analysisData)}
    </Typography></Grid>;
  }

  static getGroundCoverage(analysisData, t) {
    if (!ContainsData.groundCoverageSet(analysisData)) {
      return null;
    }

    return <Grid item xs={12}><Typography
      color="textSecondary">{t('Ground coverage') + ': ' + analysisData.ground_coverage.toFixed(2)}</Typography></Grid>;
  }

  static getPlantsPerSquareMeter(analysisData, t) {
    if (!ContainsData.plant_surface_area_set(analysisData)) {
      return null;
    }
    if (!ContainsData.plantsSet(analysisData)) {
      return null;
    }
    return <Typography color="textSecondary">
      {t('Plants per square meter') + ': ' + (analysisData.plant_record_list.length /
        analysisData.plant_surface_area_in_picture).toFixed(0)}
    </Typography>;
  }


  static getEmergingLeafPoints(analysisData, t) {
    if (!ContainsData.emergingLeafPointSet(analysisData)) {
      return null;
    }

    return <Grid item xs={12}><Typography
      color="textSecondary">{t('Average curvature') + ': ' +
        SimpleCalculations.calculateAverageCurvature(analysisData.plant_record_list).toFixed(4)}
    </Typography></Grid>;
  }

  static getLeafSpan(analysisData, t) {
    if (!ContainsData.leafSpanSet(analysisData)) {
      return null;
    }

    return <Grid item xs={12}><Typography
      color="textSecondary">{t('Average span') + ': ' +
        SimpleCalculations.calculateAverageSpan(analysisData.plant_record_list).toFixed(4)}
    </Typography></Grid>;
  }

  static getUsablesSimple(analysisData, t) {
    if (!ContainsData.classCountsSet(analysisData)) {
      return null;
    }

    return <Grid item xs={12}><Typography
      color="textSecondary">{t('Usable ratio') + ': ' +
        (analysisData.countClass2 + analysisData.countClass3) + ' / ' + analysisData.totalCellCount}
    </Typography></Grid>;
  }

  static getUsableRatioAdvanced(analysisData, t) {
    if (!ContainsData.germinationRatioSet(analysisData)) {
      return null;
    }

    return <Grid item xs={12}><Typography
      color="textSecondary">{t('Usable') + ': ' + SimpleCalculations.calculateUsableRatio(analysisData) + ' (' +
        SimpleCalculations.calculateUsableRate(analysisData) + ')'}</Typography></Grid>;
  }

  static getGerminationRatioAdvanced(analysisData, t) {
    if (!ContainsData.germinationRatioSet(analysisData)) {
      return null;
    }

    return <Grid item xs={12}><Typography
      color="textSecondary">{t('Germination') + ': ' + SimpleCalculations.calculateGerminationRatio(analysisData) +
        ' (' + SimpleCalculations.calculateGerminationRate(analysisData) + ')'}</Typography></Grid>;
  }

  static getColors(analysisData, t) {
    const plants = analysisData?.plant_record_list;
    if (!plants) {
      return 'no data';
    }
    const rgbColors = [];
    for (let i = 0; i < plants.length; i++) {
      if (plants[i].colors?.length > 0) {
        rgbColors.push(plants[i].colors[0]);
      }
    }
    if (rgbColors.length === 0) {
      return 'no color data';
    };
    const averageColor = getAverageColor(rgbColors);
    const hsvColor = rgb2hsv(averageColor[0], averageColor[1], averageColor[2]);
    return <div>
      <Typography color="textSecondary">Plant colors:</Typography>
      <Grid item xs={12}>
        {rgbColors.map((color, index) => <div key={index} style={{
          backgroundColor: 'rgb(' + color[0] + ',' + color[1] + ',' + color[2] + ')',
          width: '10px',
          height: '30px',
          display: 'inline-block',
        }}></div>)}
      </Grid>
      <Typography color="textSecondary">Average color:</Typography>
      <div style={{
        backgroundColor: 'rgb(' + averageColor[0] + ',' + averageColor[1] + ',' + averageColor[2] + ')',
        width: '50px',
        height: '50px',
        display: 'inline-block',
      }}></div>
      <Typography color="textSecondary">rgb({averageColor[0]},{averageColor[1]},{averageColor[2]})</Typography>
      <Typography color="textSecondary">hsv({Math.round(hsvColor[0])}°,{Math.round(hsvColor[1])}%,
        {Math.round(hsvColor[2])}%)</Typography>
    </div>;
  }

  static getTrayInfo = (analysisData) => {
    if (!ContainsData.trayInfoSet(analysisData)) {
      return null;
    }

    const trayPercentageCoverage = (analysisData.tray_coverage * 100).toFixed(0);
    const trayHeightInCm = (analysisData.tray_height * 100).toFixed(1);
    const percentileHeights = analysisData.tray_percentile_heights;
    // sort the percentiles based on the percentile with the lowest value first
    percentileHeights.sort((a, b) => a.percentile - b.percentile);
    return <div>
      <Typography variant="body2" color="textSecondary" style={{fontWeight: 'bold'}}>
        Tray Information:
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {'Tray coverage' + ': ' + trayPercentageCoverage + '%'}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {'Tray height' + ': ' + trayHeightInCm + ' cm'}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Tray Percentile Heights:
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 300}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Percentile</TableCell>
              <TableCell align="right">Height (cm)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {percentileHeights.map((percentileHeight) => (
              <TableRow
                key={percentileHeight.percentile}
              >
                <TableCell align="right">{(percentileHeight.percentile * 100).toFixed(0)}%</TableCell>
                <TableCell align="right">{(percentileHeight.value * 100).toFixed(1)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>;
  };

  static getChrysanthemumDetails(analysisData, t) {
    if (!ContainsData.plantsSet(analysisData)) {
      return null;
    }

    return <Grid item xs={12}>
      <Typography
        color="textSecondary"><b>General Plant Details:</b></Typography>
      <Typography color="textSecondary">
        {analysisData.plant_record_list.length + ' ' + t('Plants')}
      </Typography>
      <Typography color="textSecondary">
        {SimpleCalculations.calculateFlowersPerPlant(analysisData) + ' ' + t('Flowers per plant')}
      </Typography>
    </Grid>;
  }

  static getMarkers(analysisData) {
    if (!ContainsData.markers(analysisData)) {
      return null;
    }
    const result = [];
    const markers = analysisData['markers'];
    for (let i = 0; i < markers.length; i++) {
      const distance = DataFormatter.roundToThreeDecimals(markers[i].marker_to_sensor_distance);
      const xCoordinate = DataFormatter.roundToThreeDecimals(markers[i].lane_coordinate.x);
      const yCoordinate = DataFormatter.roundToThreeDecimals(markers[i].lane_coordinate.y);
      result.push(
          <div>
            <Typography key={i} color="textSecondary"><b>Marker: {markers[i].marker_id}</b></Typography>
            <Typography key={i} color="textSecondary">
            Bed position: horizontal {xCoordinate}m, vertical {yCoordinate}m
            </Typography>
            <Typography key={i} color="textSecondary">Distance from camera: {distance}m</Typography>
            <br />
          </div>,
      );
    }
    return <div>{result}</div>;
  }
}


export class ContainsData {
  // Check if the data is set

  static checkNested(obj, level, ...rest) {
    if (obj === undefined || obj === null) return false;
    if (rest.length === 0 && obj.hasOwnProperty(level)) return true;
    return ContainsData.checkNested(obj[level], ...rest);
  }

  static germinationRatioSet(analysisData) {
    if (ContainsData.checkNested(analysisData, 'totalCellCount')) {
      return true;
    }
    return false;
  }

  static classCountsSet(analysisData) {
    const fieldsToCheck = ['countClass2', 'countClass3', 'totalCellCount'];
    for (let i = 0; i < fieldsToCheck.length; i++) {
      if (!ContainsData.checkNested(analysisData, fieldsToCheck[i])) {
        return false;
      }
    }
    return true;
  }

  static usablesSet(analysisData) {
    if (ContainsData.checkNested(analysisData, 'usables')) {
      return true;
    }
    return false;
  }

  static trayInfoSet(analysisData) {
    if (ContainsData.checkNested(analysisData, 'tray_coverage') &&
      ContainsData.checkNested(analysisData, 'tray_height') &&
      ContainsData.checkNested(analysisData, 'tray_percentile_heights')) {
      return true;
    }
    return false;
  }

  static compartmentsSet(analysisData) {
    if (ContainsData.checkNested(analysisData, 'compartments')) {
      return true;
    }
    return false;
  }

  static tilesSet(analysisData) {
    if (ContainsData.checkNested(analysisData, 'tiles')) {
      if (ContainsData.checkNested(analysisData, 'totalCellCount')) {
        return true;
      }
    }
    return false;
  }

  static usablesDictionarySet(analysisData) {
    if (ContainsData.checkNested(analysisData, 'usables_dictionary')) {
      if (ContainsData.checkNested(analysisData, 'totalCellCount')) {
        return true;
      }
    }
    return false;
  }

  static seedRatioSet(analysisData) {
    if (ContainsData.checkNested(analysisData, 'seedsPerCell')) {
      if (ContainsData.checkNested(analysisData, 'plantsPerCell')) {
        return true;
      }
    }
    return false;
  }

  static plantsSet(analysisData) {
    return ContainsData.checkNested(analysisData, 'plant_record_list');
  }

  static plantFlowerUniformitySet(analysisData) {
    return ContainsData.checkNested(analysisData, 'plant_flower_uniformity');
  }

  static plantHeightUniformitySet(analysisData) {
    return ContainsData.checkNested(analysisData, 'plant_flower_uniformity');
  }


  static groundCoverageSet(analysisData) {
    if (ContainsData.checkNested(analysisData, 'ground_coverage')) {
      if (analysisData.ground_coverage !== null) {
        return true;
      }
    }
    return false;
  }

  static plant_surface_area_set(analysisData) {
    if (ContainsData.checkNested(analysisData, 'plant_surface_area_in_picture')) {
      return true;
    }
    return false;
  }

  static emergingLeafPointSet(analysisData) {
    if (ContainsData.checkNested(analysisData, 'plant_record_list')) {
      const plants = analysisData.plant_record_list;
      for (let i = 0; i < plants.length; i++) {
        if (plants[i]['emerging_leaf_point_height'] !== undefined) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  static leafSpanSet(analysisData) {
    if (ContainsData.checkNested(analysisData, 'plant_record_list')) {
      const plants = analysisData.plant_record_list;
      for (let i = 0; i < plants.length; i++) {
        if (plants[i]['spans'] !== undefined) {
          return true;
        }
      }
      return false;
    }
    return false;
  }

  static markers(analysisData) {
    return ContainsData.checkNested(analysisData, 'markers');
  }
}

export const displayMetricFunction = {
  [DisplayMetric.CHRYSANTHEMUM_DETAILS]: DisplayDivs.getChrysanthemumDetails,
  [DisplayMetric.FLOWER_UNIFORMITY]: DisplayDivs.getFlowerUniformity,
  [DisplayMetric.PLANT_UNIFORMITY]: DisplayDivs.getPlantUniformity,
  [DisplayMetric.SEED_RATIO]: DisplayDivs.getSeedRatio,
  [DisplayMetric.EXPECTED_COUNT]: DisplayDivs.getExpected,
  [DisplayMetric.STD_COMPARTMENTS]: DisplayDivs.getSTDCompartments,
  [DisplayMetric.COMPARTMENTS]: DisplayDivs.getCompartments,
  [DisplayMetric.MOREL_COMPARTMENTS]: DisplayDivs.getMorelCompartments,
  [DisplayMetric.GROUND_COVERAGE]: DisplayDivs.getGroundCoverage,
  [DisplayMetric.EMERGING_LEAF_POINT]: DisplayDivs.getEmergingLeafPoints,
  [DisplayMetric.LEAF_SPAN]: DisplayDivs.getLeafSpan,
  [DisplayMetric.TOTAL_COUNT]: DisplayDivs.getTotalCount,
  [DisplayMetric.CLASSES_COUNT]: DisplayDivs.getClassesCount,
  [DisplayMetric.CLASSES_COUNT_REVERSED]: DisplayDivs.getClassesCountReversed,
  [DisplayMetric.CLASSES_COUNT_DISEASED]: DisplayDivs.getClassesCountDiseased,
  [DisplayMetric.USABLES]: DisplayDivs.getUsablesDictionary,
  [DisplayMetric.USABLES_SIMPLE]: DisplayDivs.getUsablesSimple,
  [DisplayMetric.USABLE_RATIO]: DisplayDivs.getUsableRatio,
  [DisplayMetric.USABLE_RATIO_ADVANCED]: DisplayDivs.getUsableRatioAdvanced,
  [DisplayMetric.GERMINATION_RATIO_ADVANCED]: DisplayDivs.getGerminationRatioAdvanced,
  [DisplayMetric.COLORS]: DisplayDivs.getColors,
  [DisplayMetric.BREAK]: DisplayDivs.returnBreak,
  [DisplayMetric.TRAY_INFO]: DisplayDivs.getTrayInfo,
  [DisplayMetric.MARKERS]: DisplayDivs.getMarkers,
  [DisplayMetric.PLANTS_PER_SQUARE_METER]: DisplayDivs.getPlantsPerSquareMeter,
};
