import React, {useState, useContext, useEffect} from 'react';
import 'date-fns';
import {DatePickerComponent} from '../../Utils/DatePickers';
import {Card, CardContent, InputLabel, Select, MenuItem, Slider,
  TextField, FormGroup, FormControlLabel, Switch} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import HeatmapLegend from './HeatmapLegend';
import {UserContext} from '../../contexts/userContext';
import {ApiClient} from '../../Utils/ApiClient';
import {OverviewMapContext} from '../../contexts/OverviewMapContext';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
    maxHeight: '90vh',
    overflow: 'scroll',
    overflowX: 'hidden',
  },
  form: {
    marginTop: theme.spacing(2),
  },
  keyLabel: {
    border: 'solid 1px grey',
    borderRadius: '3px',
    marginTop: theme.spacing(1),
    width: '90%',
    boxSizing: 'border-box',
    padding: theme.spacing(1),
    height: 40,
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  inputLabel: {
    marginBottom: theme.spacing(2),
  },
}));

const confidenceMarks = [
  {value: 0, label: '0%'},
  {value: .05, label: ''},
  {value: .1, label: ''},
  {value: .15, label: ''},
  {value: .2, label: ''},
  {value: .25, label: '25%'},
  {value: .3, label: ''},
  {value: .35, label: ''},
  {value: .4, label: ''},
  {value: .45, label: ''},
  {value: .5, label: '50%'},
  {value: .55, label: ''},
  {value: .6, label: ''},
  {value: .65, label: ''},
  {value: .7, label: ''},
  {value: .75, label: '75%'},
  {value: .8, label: ''},
  {value: .85, label: ''},
  {value: .9, label: ''},
  {value: .95, label: ''},
  {value: 1, label: '100%'},
];

const systemMarks = [
  {value: 0, label: '0'},
  {value: 2.5, label: ''},
  {value: 5, label: ''},
  {value: 7.5, label: ''},
  {value: 10, label: ''},
  {value: 12.5, label: ''},
  {value: 15, label: ''},
  {value: 17.5, label: ''},
  {value: 20, label: ''},
  {value: 22.5, label: ''},
  {value: 25, label: ''},
  {value: 27.5, label: ''},
  {value: 30, label: '30'},
  {value: 32.5, label: ''},
  {value: 35, label: ''},
  {value: 37.5, label: ''},
  {value: 40, label: ''},
  {value: 42.5, label: ''},
  {value: 45, label: ''},
  {value: 47.5, label: ''},
  {value: 50, label: ''},
  {value: 52.5, label: ''},
  {value: 55, label: ''},
  {value: 57.5, label: ''},
  {value: 60, label: '60'},
];

// This component displays the filters for the map view.
const MapFilters = () => {
  const {currentClient, isADIUser} = useContext(UserContext);
  const [highlightDates, setHighlightDates] = useState(new Set());
  const {startingDate, setStartingDate,
    snapshotLabels, annotationLabels, isDataFetched, mapType,
    snapshotFilters, setSnapshotFilters, annotationFilters,
    setAnnotationFilters, endingDate, setEndingDate, showLaneBatchInfo, setShowLaneBatchInfo, showExpertAnnotations,
    setShowExpertAnnotations, expertAnnotations, laneBatchInfoFound,
    setLaneBatchInfoFound} = useContext(OverviewMapContext);
  const classes = useStyles();

  const markDaysWithUploads = async () => {
    setHighlightDates(new Set());
    ApiClient.findDaysWithUploads(currentClient, null).then((days) => {
      setHighlightDates(days);
    });
  };

  useEffect(() => {
    if (currentClient && highlightDates.size === 0) {
      markDaysWithUploads();
    }
  }, [currentClient]);

  if (!snapshotLabels || !annotationLabels) {
    return null;
  }

  const handleLaneInfoToggle = (event) => {
    setLaneBatchInfoFound(false);
    setShowLaneBatchInfo(event.target.checked);
  };

  const handleExpertAnnotationsToggle = (event) => {
    setShowExpertAnnotations(event.target.checked);
  };

  const handleChangeStartingDate = (date) => {
    setStartingDate(new Date(date));
  };

  const handleChangeEndingDate = (date) => {
    setEndingDate(new Date(date));
  };

  const handleLabelChange = (event) => {
    setAnnotationFilters({...annotationFilters, label: event.target.value});
  };

  const handleStatusChange = (event) => {
    setSnapshotFilters({...snapshotFilters, status: event.target.value});
  };

  const handleSprayStatusChange = (event) => {
    setSnapshotFilters({...snapshotFilters, isSpraying: event.target.value});
  };

  const handleConfidenceChange = (event, newValue) => {
    setAnnotationFilters({...annotationFilters, minConfidenceLevel: newValue});
  };

  const handleSystemSpeedChange = (event, newValue) => {
    setSnapshotFilters({...snapshotFilters, maxSystemSpeed: newValue});
  };

  // get labels and colors from labels data
  const data = mapType === 'snapshots' ? snapshotLabels : annotationLabels;
  let labels = Object.keys(data);
  const colors = {};
  for (let i = 0; i < labels.length; i++) {
    colors[data[labels[i]].value] = data[labels[i]].color;
  }
  // get label values from labels data
  labels = labels.map((label) => data[label].value);
  // add all option
  labels.unshift('all');
  return (
    <div>
      <Card className={classes.card} id='root-map-container'>
        <CardContent>
          <InputLabel id="filter-by" style={{fontSize: 22, fontWeight: 'bold'}}>FILTER BY</InputLabel>
        </CardContent>
        <Divider />
        <CardContent>
          <InputLabel id="label-select" className={classes.inputLabel}>
            {mapType.charAt(0).toUpperCase() + mapType.slice(1)} Date
          </InputLabel>
          <form className={classes.form} noValidate>
            <DatePickerComponent
              id="starting-date"
              label='Starting Date'
              changeDate={handleChangeStartingDate}
              startingDate={startingDate}
              highlightDates={highlightDates}
            />
          </form>
          {isADIUser &&
          <form className={classes.form} noValidate>
            <DatePickerComponent
              id="ending-date"
              label='Ending Date'
              changeDate={handleChangeEndingDate}
              startingDate={endingDate}
              highlightDates={highlightDates}
            />
          </form>}
        </CardContent>
        <Divider />
        {isADIUser && isDataFetched && mapType === 'snapshots' &&
        <CardContent>
          <InputLabel id="toggle-lane-info" className={classes.inputLabel}>Lane Batch Information</InputLabel>
          <FormGroup style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <FormControlLabel control={<Switch onChange={handleLaneInfoToggle}/>} label="Show Lane Batch Info." />
          </FormGroup>
          {showLaneBatchInfo && !laneBatchInfoFound &&
          <Box sx={{width: '100%'}}>
            <LinearProgress />
          </Box>}

        </CardContent>
        }
        {isADIUser && isDataFetched && mapType === 'annotations' &&
        <CardContent>
          <InputLabel id="toggle-expert-annotations" className={classes.inputLabel}>IPM Expert Annotations</InputLabel>
          <FormGroup style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <FormControlLabel control={<Switch onChange={handleExpertAnnotationsToggle}/>}
              label="Show expert annotations" />
          </FormGroup>
          {showExpertAnnotations && !expertAnnotations &&
          <Box sx={{width: '100%'}}>
            <LinearProgress />
          </Box>}


        </CardContent>
        }
        <Divider />
        {mapType === 'annotations' ?
          <CardContent>
            <InputLabel id="label-select" className={classes.inputLabel}>Annotation Label</InputLabel>
            <Select
              value={annotationFilters.label}
              label="filter-by-label"
              onChange={handleLabelChange}
              style={{width: '100%', maxWidth: '250px'}}
              variant='outlined'
            >
              {labels.map((label, index) =>
                <MenuItem value={label} key={index}>{label}</MenuItem>,
              )}
            </Select>
          </CardContent> :
          <CardContent>
            <InputLabel id="status-select" className={classes.inputLabel}>Snapshot Status</InputLabel>
            <Select
              value={snapshotFilters.status}
              label="filter-by-status"
              onChange={handleStatusChange}
              style={{width: '100%', maxWidth: '250px'}}
              variant='outlined'
            >
              {labels.map((label, index) =>
                <MenuItem value={label} key={index}>{label}</MenuItem>,
              )}
            </Select>
          </CardContent>
        }
        <Divider />

        <CardContent>
          <InputLabel id="status-select" className={classes.inputLabel}>Time Of Day (latest)</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Select Time of Day"

              value={snapshotFilters.timeOfDay}
              onChange={(newValue) => {
                setSnapshotFilters({...snapshotFilters, timeOfDay: newValue});
              }}
              renderInput={(params) => <TextField {...params} variant='outlined'/>}
              className='time-picker'
            />
          </LocalizationProvider>
        </CardContent>
        <Divider />

        <CardContent>
          <InputLabel id="label-select" className={classes.inputLabel}>Spraying Status</InputLabel>
          <Select
            value={snapshotFilters.isSpraying}
            label="filter-by-spray-status"
            onChange={handleSprayStatusChange}
            style={{width: '100%', maxWidth: '250px'}}
            variant='outlined'
          >
            <MenuItem value={'all'}>All</MenuItem>,
            <MenuItem value={'Spraying'}>Spraying</MenuItem>,
            <MenuItem value={'Not Spraying'}>Not Spraying</MenuItem>,

          </Select>
        </CardContent>

        <Divider />

        {mapType === 'annotations' && annotationFilters.label !== 'all' &&
          <CardContent>
            <InputLabel id="confidence-select" className={classes.inputLabel}>Min. Confidence Level</InputLabel>
            <Slider aria-label="Confidence" min={0.0} max={1.0} step={0.05} style={{width: '95%', marginTop: '20px'}}
              value={annotationFilters.minConfidenceLevel} onChangeCommitted={handleConfidenceChange}
              marks={confidenceMarks} valueLabelDisplay="auto"
              valueLabelFormat={`${parseInt(annotationFilters.minConfidenceLevel*100)}%`} />
          </CardContent>
        }

        <CardContent>
          <InputLabel id="system-speed-select" className={classes.inputLabel}>Max. System Speed (m/min)</InputLabel>
          <Slider aria-label="System-Speed" min={0.0} max={60.0} step={0.05} style={{width: '95%', marginTop: '20px'}}
            value={snapshotFilters.maxSystemSpeed} onChangeCommitted={handleSystemSpeedChange}
            marks={systemMarks} valueLabelDisplay="auto"
            valueLabelFormat={snapshotFilters.maxSystemSpeed} />
        </CardContent>

        <Divider />

        {annotationFilters.label !== 'all' && mapType === 'annotations' &&
        <CardContent >
          <InputLabel id="label-select" className={classes.inputLabel}>
            Heatmap Values For {annotationFilters.label.charAt(0).toUpperCase() + annotationFilters.label.slice(1)}
          </InputLabel>
          <HeatmapLegend maxValue={30} color={colors[annotationFilters.label] || 'blue'} />
        </CardContent>}
      </Card>
    </div>
  );
};

export default MapFilters;
