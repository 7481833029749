import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {AdiLogo} from './AdiLogo';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CartWidget from '../ShoppingCart/CartWidget';
import ClientPicker from '../UserInput/ClientPicker';
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useContext} from 'react';
import {CredentialsContext} from '../../contexts/credentialsContext';
import {UserContext} from '../../contexts/userContext';
import i18n from '../../i18n';
import {Link} from 'react-router-dom/cjs/react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 5,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menuWrapper: {
    margin: 0,
    background: theme.palette.primary.main,
    maxWidth: 200,
    position: 'absolute',
    zIndex: 3,
    left: 0,
    padding: 10,
  },
  clientPickerWrapper: {
    marginTop: -10,
  },
}));

const Header = () => {
  const [languageKey, setLanguageKey] = useState('en');
  const [language, setLanguage] = useState('English');
  const [menuOpen, setMenuOpen] = useState(false);
  const {credentials} = useContext(CredentialsContext);
  const {setCurrentClient, currentClient, user, hasClientPicker} = useContext(UserContext);

  const history = useHistory();
  const {t} = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (languageKey) {
      i18n.changeLanguage(languageKey);
    }
  }, [languageKey]);

  const handleClick = (event) => {
    if (!credentials || credentials === '') {
      return;
    }
    setMenuOpen(true);
  };

  const handleClose = (event) => {
    const client = currentClient || 'admin';
    setMenuOpen(false);
    if (event === 'home') {
      history.push(`/${client || 'admin'}`);
    }
    // todo replace this by dynamic code
    if (event === 'images') {
      history.push(`/${client || 'admin'}/images`);
    }
    if (event === 'account') {
      history.push(`/${client || 'admin'}/account`);
    }
  };

  const getAccountButton = () => {
    if ((typeof credentials !== 'undefined') && credentials !== '') {
      return <Button onClick={() => history.push(`/${currentClient || 'admin'}/account`)}
        color="inherit"><AccountCircleIcon /></Button>;
    } else {
      return <Button onClick={() => history.push('/login')} color="inherit">Login</Button>;
    }
  };

  const getMenu = () => {
    return (<div className={classes.menuWrapper}>
      <ClickAwayListener onClickAway={handleClose}>
        <MenuList autoFocusItem={menuOpen} id="menu-list-grow">
          <MenuItem onClick={() => handleClose('home')}>{t('Home')}</MenuItem>
          <MenuItem onClick={() => handleClose('images')}>{t('Images')}</MenuItem>
          <MenuItem onClick={() => handleClose('account')}>{t('Account')}</MenuItem>
        </MenuList>
      </ClickAwayListener>
    </div>);
  };


  const clickLanguage = () => {
    const currentLanguage = languageKey;
    let newLanguageKey = 'en';
    let newLanguage = 'English';
    if (currentLanguage === 'en') {
      newLanguageKey = 'nl';
      newLanguage = 'Nederlands';
    }
    setLanguage(newLanguage);
    setLanguageKey(newLanguageKey);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <MenuIcon edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
            onClick={handleClick} />
          <div style={{flexGrow: 1}}>
            <Link to={{pathname: `/${currentClient || 'admin'}`}}>
              <AdiLogo/>
            </Link>
          </div>
          {hasClientPicker?
          <div className={classes.clientPickerWrapper}>
            <ClientPicker
              clients={user.clients}
              useUserContext={true}
              defaultClient={currentClient}
              onClientChanged={setCurrentClient}
              triggerOnEmpty={true}
              hideCard={true}/>
          </div> : ''}
          <Button onClick={clickLanguage}>{language}</Button>
          <CartWidget />
          {getAccountButton()}
        </Toolbar>
      </AppBar>
      {menuOpen ? getMenu() : null}

    </div>
  );
};

export default Header;
