import React, {useRef, useEffect, useState} from 'react';

// Component to crop an image based on the plant's pixel dimensions and center coordinate
const ImageCropper = ({imageUrl, plant}) => {
  const [isMounted, setIsMounted] = useState(true);

  const canvasRef = useRef(null);

  const cropHeight = Math.round(plant.pixel_dimensions[1]);
  const cropWidth = Math.round(plant.pixel_dimensions[0]);
  const centerX = Math.round(plant.center_coordinate[0]);
  const centerY = Math.round(plant.center_coordinate[1]);


  useEffect(() => {
    return () => {
      setIsMounted(false); // Component will unmount
    };
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      if (!isMounted) return;
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        console.error('Failed to get canvas context');
        return;
      }
      canvas.width = cropWidth;
      canvas.height = cropHeight;

      // Calculating the starting point to draw the image
      const startX = centerX - (cropWidth / 2);
      const startY = centerY - (cropHeight / 2);

      // Drawing the cropped area
      ctx.drawImage(img, startX, startY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
    };
    img.onerror = (error) => {
      console.error('Error loading the image', error);
    };
  }, [imageUrl, cropHeight, cropWidth, centerX, centerY]);

  return <canvas ref={canvasRef} style={{width: '100%', border: '1px solid #ccc', height: '100%'}}></canvas>;
};

export default ImageCropper;
