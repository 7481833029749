
import * as React from 'react';
import Button from '@material-ui/core/Button';
import YesOrNoDialog from '../BasicComponents/YesOrNoDialog';
import {ApiClient} from '../../Utils/ApiClient';

export default function CopySnapshot({targetClient, snapshot}) {
  const [showDialog, setShowDialog] = React.useState(false);
  const [showButton, setShowButton] = React.useState(true);

  const handleClickCopySnapshot = () => {
    setShowDialog(true);
  };

  const handleDialogAnswer = async (answer) => {
    setShowDialog(false);
    if (answer === true) {
      await ApiClient.copySnapshot(snapshot._id, targetClient);
      setShowButton(false);
    }
  };

  return (
    <div>
      {showButton ? <Button size="small" color="primary" variant="outlined" onClick={handleClickCopySnapshot}>
                Copy Snapshot to {targetClient}
      </Button> : ''}
      <div>
        <YesOrNoDialog title="Copy this snapshot" message="Are you sure want to copy this snapshot?"
          showDialog={showDialog} handleDialogAnswer={handleDialogAnswer} />
      </div>
    </div>);
}
