

import React, {useEffect, useState} from 'react';
import {Line, XAxis, YAxis, CartesianGrid, Area, Tooltip, ComposedChart, Label, ReferenceLine} from 'recharts';
import {DateAxis} from './DateAxis';

function SimpleChart(props) {
  const [filledData, setFilledData] = useState([]);
  const [domain, setDomain] = useState([]);
  const [ticks, setTicks] = useState([]);

  useEffect(() => {
    const dates = props.data.map((v) => v.collection_date);
    if (dates.length === 0) {
      return;
    }
    const {startDate, endDate} = DateAxis.getStartAndEndDate(dates);
    setDomain([(dataMin) => dataMin, () => endDate.getTime()]);
    props.data.forEach((d) => {
      d.time = new Date(d.collection_date).getTime();
    });

    const ticks = DateAxis.getTicks(startDate, endDate, Math.min(5, dates.length));
    const data = DateAxis.fillTicksData(ticks, props.data);
    setTicks(ticks);
    setFilledData(data);
  }, [props.data]);

  const formatter = (value, name, props) => {
    const names = name.split('.');
    name = names[names.length - 1];
    if (Array.isArray(value)) {
      value = value[0] + ' - ' + value[1];
    }
    return [value, name, props];
  };

  const getLinesAndAreas = (graphSettings) => {
    const lines = graphSettings.map((setting) => {
      let stroke = '#8884d8';
      if (setting.stroke !== undefined) {
        stroke = setting.stroke;
      }
      if (setting.graphType === 'line') {
        return <Line key={setting.outputName} dataKey={setting.outputName} stroke={stroke} />;
      } else if (setting.graphType === 'area') {
        return <Area key={setting.outputName} dataKey={setting.outputName} stroke={stroke} fill="#8884d8" />;
      } else {
        return <span key={setting.outputName} />;
      }
    });
    return lines;
  };

  const getVerticalMarkers = (verticalMarkers) => {
    if (verticalMarkers === undefined) {
      return;
    }
    return verticalMarkers.map((verticalMarker) =>
      <ReferenceLine key={verticalMarker} x={new Date(verticalMarker).getTime()} stroke="orange" />);
  };

  const getAllCharts = (data, graphSettings) => {
    return <ComposedChart
      width={700}
      height={300}
      data={data}
      margin={{
        top: 20, right: 20, bottom: 20, left: 20,
      }}>
      <XAxis
        hasTick
        dataKey="time"
        scale="time"
        tickFormatter={(tick) => DateAxis.dateFormatter(tick, true)}
        type="number"
        domain={domain}
        ticks={ticks}
        height={40}
        tickMargin={15}
      >
        <Label value={'Date'} position="bottom" />
      </XAxis>
      <YAxis dataKey={graphSettings[0].outputName} domain={graphSettings[0].domain} >
        <Label angle={-90} position="left" style={{'textAnchor': 'middle'}} value={graphSettings[0].yLabel} />
      </YAxis>
      <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
      {getVerticalMarkers(props.verticalMarkers)}
      {getLinesAndAreas(graphSettings)}
      <Tooltip formatter={formatter} labelFormatter={(value) => DateAxis.dateFormatter(value, false)} />
    </ComposedChart>;
  };

  const getChart = (filledData, graphSettings) => {
    if (typeof graphSettings === 'undefined' || Object.keys(graphSettings).length === 0) {
      return <div></div>;
    }
    return getAllCharts(filledData, graphSettings);
  };

  return getChart(filledData, props.graphSettings);
}


export default SimpleChart;
