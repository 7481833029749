import {useMemo, useRef, useEffect, useContext} from 'react';
import * as d3 from 'd3';
import './overview-map.css';
const MARGIN = {top: 0, right: 50, bottom: 30, left: 50};
import Popup from 'reactjs-popup';
import './popup.css';
import {OverviewMapContext} from '../../contexts/OverviewMapContext';

import PlantCard from './PlantCard';
import SnapshotsCard from './SnapshotsCard';
import SnapshotMapKeyLabels from './SnapshotMapKeyLabels';
import AnnotationMapKeyLabels from './AnnotationsMapKeyLabels';
import LaneLoading from './LaneLoading';
import MapCellSymbol from './MapCellSymbol';
import LaneLabel from './LaneLabel';
import TriangleIndicator from './TriangleIndicator';
import ExpertAnnotationCard from './ExpertAnnotationCard';
const annotationLetters = {
  'healthy': 'H',
  'trips': 'T',
  'caterpillar': 'C',
  'lice': 'L',
  'erwinia': 'E',
  'discarded': 'D',
  'other': 'O',
  'split_branches': 'S',
};

export const Heatmap = () => {
  const {lanes, isDataFetched, getCellColor, getSnapshotImages,
    setCurrentSnapshotImage, getCurrentSnapshotImage, mapType,
    greenhouseStats, annotationFilters, expertAnnotations, showExpertAnnotations,
    fetchExpertAnnotationImages, setExpertAnnotationImages} = useContext(OverviewMapContext);
  const width = document.getElementById('map-container').offsetWidth;
  const height = 400;

  const svgRef = useRef();
  const zoomGRef = useRef();

  // Calculate the width and height of the bounds
  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;

  // Get all unique x and y groups
  const allYGroups = useMemo(() => [...new Set(lanes[0].cells.map((d) => d.y))], [lanes[0].cells]);
  const allXGroups = useMemo(() => [...new Set(lanes[0].cells.map((d) => d.x))], [lanes[0].cells]);

  // Create scales for x and y axis based on the unique x and y groups
  const xScale = useMemo(() => {
    return d3.scaleBand().range([0, boundsWidth]).domain(allXGroups).padding(0.01);
  }, [boundsWidth, allXGroups]);

  const yScale = useMemo(() => {
    return d3.scaleBand().range([0, boundsHeight]).domain(allYGroups).padding(0.01);
  }, [boundsWidth, allYGroups]);

  // Get the min and max values of the cells
  const [min, max] = d3.extent(lanes[0].cells.map((d) => d.value));
  if (!min || !max) {
    return null;
  }

  // Define the zoom behavior
  const zoom = d3.zoom()
      .scaleExtent([.1, 10]) // Allow zooming from 10% to 1000%
      .translateExtent([[-Infinity, -Infinity], [Infinity, Infinity]]) // Allow panning to any extent
      .on('zoom', ({transform}) => {
        d3.select(zoomGRef.current).attr('transform', transform); // Apply the zoom transformation to the group
      });

  const laneHeight = greenhouseStats.sublanes.length * yScale.bandwidth(); // Height of a single lane
  const laneSpacing = 50; // Additional spacing between lanes
  const defaultZoomLevel = 0.8;
  const laneHeightWithSpacing = laneHeight + laneSpacing; // Height of a single lane with spacing

  useEffect(() => {
    // Calculate translateY to keep the last lane at the bottom when the data is being fetched
    const svgHeight = svgRef.current.getBoundingClientRect().height;

    if (!isDataFetched || svgHeight < 700) {
      const totalLaneHeight = lanes.length * laneHeightWithSpacing;
      const viewportHeight = 700;

      // Calculate translateY to keep the first lane at the top
      const translateY = (viewportHeight - totalLaneHeight);

      const translateX = (width - (boundsWidth * defaultZoomLevel)) / 2;

      d3.select(svgRef.current)
          .call(zoom)
          .attr('height', viewportHeight)
          .call(zoom.transform, d3.zoomIdentity.scale(defaultZoomLevel).translate(translateX, translateY))
          .on('dblclick.zoom', null);
    }
  }, [zoom, lanes]);


  // Get the plant list for each snapshot and return a list of plant cards to display the plants
  const getSnapshotPlantList = (snapshot) => {
    if (!snapshot) {
      return null;
    }
    const plantRecordList = snapshot?.data?.analysis_data?.plant_record_list;
    if (!plantRecordList || plantRecordList.length === 0) {
      return null;
    }
    // return divs with plant names
    return plantRecordList.map((plant, i) => (
      <PlantCard plant={plant} key={i}/>
    ));
  };

  const getSnapshotExpertAnnotations = (snapshot, key) => {
    if (!snapshot || !expertAnnotations || !showExpertAnnotations) {
      return null;
    }
    const snapshotExpertAnnotations = expertAnnotations[snapshot._id];
    if (!snapshotExpertAnnotations) {
      return null;
    }
    return (
      <Popup
        key={key}
        trigger={
          <button className='show-expert-annotations-list-button'>show expert annotations</button>
        }
        position="left center"
        nested
        onOpen={() => {
          fetchExpertAnnotationImages(snapshotExpertAnnotations);
        }}
        onClose={() => {
          setExpertAnnotationImages(null);
        }}
      >
        {snapshotExpertAnnotations.map((annotation, i) => (
          <ExpertAnnotationCard annotation={annotation} key={i}/>
        ))}
      </Popup>
    );
  };


  // Get the snapshots for each cell and return a list of snapshot cards to display the snapshots
  const getSnapshotsCard = (snapshots) => {
    if (snapshots.length === 0) {
      return <div>No Data Found</div>;
    }
    return (
      <div className='popup-card' id='#root-map-container'>
        <p style={{textAlign: 'center', fontWeight: 'bold', margin: 5}}>Snapshots</p>
        {snapshots.map((snapshot, i) => (
          <div key={i} className='snapshot-card-container'>
            <SnapshotsCard snapshot={snapshot} color={getCellColor([snapshot]).color}/>
            <Popup
              key={i}
              trigger={
                <button className='show-plant-list-button'>show plant list</button>
              }
              position="left center"
              nested
              onOpen={() => {
                getCurrentSnapshotImage(snapshot);
              }}
              onClose={() => {
                setCurrentSnapshotImage(null);
              }}
            >
              {getSnapshotPlantList(snapshot)}
            </Popup>
            {getSnapshotExpertAnnotations(snapshot, i)}
          </div>

        ))}
      </div>
    );
  };

  // Render the lanes and cells
  const renderLanes = lanes.map((lane, laneIndex) => {
    // Calculate the y offset for each lane
    const yOffset = laneIndex * (laneHeight + laneSpacing);
    // Render the cells for each lane
    const cells = lane.cells.map((d, i) => {
      const cellColor = d.color ? d.color : 'white';
      let cellAnnotationColor = d.annotationColor ? d.annotationColor : 'white';
      const cellAnnotationLetter = d.heighestLabel ? annotationLetters[d.heighestLabel] : '';
      const labelCounts = d.labelCounts ? d.labelCounts : null;
      const cellExpertAnnotations = [];
      if (expertAnnotations) {
        d.snapshots.forEach((snapshot) => {
          const snapshotExpertAnnotations = expertAnnotations[snapshot._id];
          if (snapshotExpertAnnotations) {
            cellExpertAnnotations.push(snapshotExpertAnnotations);
          }
        });
      }
      if (mapType === 'annotations' && annotationFilters.label !== 'all') {
        cellAnnotationColor = labelCounts && labelCounts[annotationFilters.label] ?
        labelCounts[annotationFilters.label].color : 'white';
      }
      const cellTopCenterCoordinates = {
        x: xScale(d.x) + (xScale.bandwidth() / 2),
        y: yScale(d.y) + yOffset,
      };
      return (
        <g key={`${laneIndex}-${i}`}>
          <Popup trigger={
            <g>
              {
                cellExpertAnnotations.length > 0 && showExpertAnnotations && mapType === 'annotations' &&
                <TriangleIndicator cellTopCenter={cellTopCenterCoordinates}/>
              }
              <rect
                key={`${laneIndex}-${i}-rect`}
                x={xScale(d.x)}
                y={yScale(d.y) + yOffset}
                width={xScale.bandwidth()}
                height={yScale.bandwidth()}
                rx={5}
                stroke={'black'}
                onClick={() => console.log(`Clicked on cell ${d.x}, ${d.y}`)}
                className={d.color ? 'overview-map-cell' : 'overview-map-cell-empty'}
                fill={mapType === 'annotations' ? cellAnnotationColor : cellColor}
              />
              <MapCellSymbol d={d} laneIndex={laneIndex} i={i} xScale={xScale}
                yScale={yScale} yOffset={yOffset} mapType={mapType} annotationLetter={cellAnnotationLetter}
                annotationFilters={annotationFilters}/>
            </g>

          } position="right center" nested on={['click']} onOpen={()=>{
            {/* Invoke fetching snapshot images for the current cell */}
            getSnapshotImages(d.snapshots);
          }}>
            {/* Invoke displaying snapshot cards for each snapshot in the current cell */}
            {getSnapshotsCard(d.snapshots)}
          </Popup>
          {/* Label for each row */}
          <g>
            <text
              key={`${laneIndex}-${i}-text`}
              x={-15}
              y={yScale(d.y) + yOffset + yScale.bandwidth() / 2} // Center label vertically within the cell
              textAnchor="end"
              dominantBaseline="middle"
              fontSize={22}
            >
              {d.y} {/* 'y' holds the label for each row */}
            </text>
          </g>
        </g>
      );
    });

    // Render the label for each lane and specify the label color based on if laneBatchInfo is present
    const labelY = yOffset;
    const laneLabel = (<LaneLabel laneIndex={laneIndex} lane={lane} boundsWidth={boundsWidth}
      xScale={xScale} labelY={labelY} laneHeight={laneHeight}/>
    );

    const laneWidth = boundsWidth;
    const laneRectX = 0;
    const laneRectY = yOffset;

    // Render a loading animation for the last lane if the data is not fetched
    const laneRect = laneIndex === lanes.length - 1 && !isDataFetched ? (
      <LaneLoading laneWidth={laneWidth} laneHeight={laneHeight} laneRectX={laneRectX} laneRectY={laneRectY} />
    ) : null;


    // Render labels for columns only on top of the first row of each lane
    const columnLabels = (
      allXGroups.map((name, i) => (
        <text
          key={`column-label-${i}`}
          x={xScale(name) + xScale.bandwidth() / 2}
          y={yOffset - 10} // Adjust y position to place the labels above the cells
          textAnchor="middle"
          dominantBaseline="baseline" // Align with the top of the cell
          fontSize={18}
        >
          {name} {/* 'name' holds the label for each column */}
        </text>
      ))
    );

    return {cells, laneLabel, columnLabels, laneRect};
  });

  const showMapKeys = () => {
    return (
      mapType === 'annotations' ? <AnnotationMapKeyLabels width={width}/> :
      <SnapshotMapKeyLabels width={width}/>
    );
  };


  // Combine all column labels from the first lane
  const firstLaneColumnLabels = renderLanes[0].columnLabels;

  return (
    <div className='greenhouse-background' onMouseEnter={showMapKeys}>
      <svg ref={svgRef} width={width}>
        <filter id='shadow' colorInterpolationFilters="sRGB">
          <feDropShadow dx="2" dy="2" stdDeviation="3" floodOpacity="0.2"/>
        </filter>
        <g ref={zoomGRef} transform={`translate(${MARGIN.left},${MARGIN.top})`}>
          {firstLaneColumnLabels}S
          {renderLanes.flatMap((lane) => [...lane.cells, lane.laneLabel, lane.laneRect, lane.columnLabels])}
          {isDataFetched ? <text x={boundsWidth / 2} y={lanes.length * laneHeightWithSpacing + 50}
            textAnchor="middle" dominantBaseline="middle" fontSize={22} style={{fontWeight: 'bold'}}>
            All Snapshots Fetched!</text> : null}
        </g>
        {showMapKeys ? showMapKeys() : null}
      </svg>
    </div>
  );
};
