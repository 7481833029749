export function inverseDistanceWeighting(x, y, points, power = 2) {
  let numerator = 0;
  let denominator = 0;

  points.forEach((point) => {
    const distance = Math.sqrt((x - point.lat) ** 2 + (y - point.lon) ** 2);
    if (distance > 0) {
      const weight = 1 / (distance ** power);
      numerator += weight * point.ground_to_pipe_distance;
      denominator += weight;
    } else {
      numerator = point.ground_to_pipe_distance;
      denominator = 1;
      return;
    }
  });

  return (numerator / denominator).toFixed(3);
}

export function convertLaneCalibrationsToPoints(calibrations) {
  const points = {};
  Object.keys(calibrations).forEach((sublane) => {
    if (!points[sublane]) {
      points[sublane] = [];
    };
    calibrations[sublane].forEach((calibration) => {
      points[sublane].push({
        // eslint-disable-next-line max-len
        lat: 0.8, // this is hardcoded for now until the x value is fixed in the backend
        lon: calibration.coordinates.y,
        ground_to_pipe_distance: calibration.ground_to_pipe_distance,
      });
    });
  });
  return points;
}
