import React from 'react';
import {Card, CardContent, useTheme, CircularProgress} from '@material-ui/core';
const LineChart = ({targetDateDayData, currentClient, lastDayData, getLineChart, isLineGraphLoading}) => {
  const theme = useTheme();


  return (
    <Card style={{
      margin: theme.spacing(1),
    }}>
      <CardContent>
        {isLineGraphLoading ? (
            <div style={{
              height: '40vh',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <div>
              <h2>Daily Upload Speed&nbsp;
                {currentClient ? '(' + currentClient + ')' : '(All Clients) '}
                {'('+targetDateDayData?.toDateString()+')'}&nbsp;
              </h2>
              {lastDayData && getLineChart(lastDayData)}
            </div>
          )}

      </CardContent>
    </Card>
  );

  return null;
};

export default LineChart;
