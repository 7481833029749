import React from 'react';
import Typography from '@material-ui/core/Typography';

const footerStyle = {
  padding: 20,
  textAlign: 'center',
};
const Footer = () => {
  return <div>
    <footer style={footerStyle}>
      <Typography variant="h6">
                ADI | Bridging Biology & Technology
      </Typography>
    </footer>
  </div>;
};

export default Footer;
