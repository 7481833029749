import React, {useEffect} from 'react';
import {styled} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import {useTranslation} from 'react-i18next';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {PickersDay} from '@mui/x-date-pickers/PickersDay';
import getWeek from 'date-fns/getWeek';
import {makeStyles} from '@material-ui/styles';
import {setDefaultOptions} from 'date-fns';


setDefaultOptions({weekStartsOn: 1});

// styles for the week number
const useStyles = makeStyles((theme) => ({
  dayCellContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  weekNumber: {
    fontSize: '0.75rem',
    color: 'gray',
    position: 'absolute',
    top: '25%',
    left: '55%',
    transform: 'translateY(300%)',
    rotate: '90deg',
  },
}));


const CustomPickersDay = styled(PickersDay)(({theme}) => ({
  ...({
    'borderRadius': '50%',
    'backgroundColor': theme.palette.success.light,
    'color': theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.success.main,
    },
  }),
}));

export function DatePickerComponent({highlightDates, changeDate, label = null, startingDate = null}) {
  const {t} = useTranslation();
  const [date, setDate] = React.useState(new Date());
  const [highlightDays, setHighlightDays] = React.useState(new Set());
  const [datepickerLabel, setDatepickerLabel] = React.useState(t('Pick date of interest'));
  const classes = useStyles();

  useEffect(() => {
    if (highlightDates !== undefined) {
      setHighlightDays(highlightDates);
    }
  }, [highlightDates]);

  useEffect(() => {
    if (label) {
      setDatepickerLabel(label);
    }
  }, [label]);

  useEffect(() => {
    if (startingDate) {
      setDate(startingDate);
    }
  }, [startingDate]);

  function handleChangeDate(newDate) {
    if (!isNaN(newDate) && newDate != null && newDate.getFullYear() >= 2000) {
      newDate.setHours(0, 0, 0, 0);
      setDate(newDate);
      const isoDateString = newDate.toISOString();
      changeDate(isoDateString);
    }
  }

  const renderSingleDay = (date, selectedDates, pickersDayProps) => {
    const actualDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    const dateStringISO = actualDate.toISOString().split('T')[0];

    let dayComponent = <PickersDay {...pickersDayProps} />;
    if (highlightDays && highlightDays.has(dateStringISO)) {
      dayComponent = <CustomPickersDay {...pickersDayProps} />;
    }
    // Show week number on mondays
    if (date.getDay() === 1) {
      const weekNumber = getWeek(date);
      return (
        <div className={classes.dayCellContainer}>
          <span className={classes.weekNumber}>W{weekNumber}</span>
          {dayComponent}

        </div>
      );
    }
    return dayComponent;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={datepickerLabel}
        value={date}
        onChange={(newDate) => {
          handleChangeDate(newDate);
        }}
        renderInput={(params) => <TextField {...params} id='date-picker'/>}
        renderDay={renderSingleDay}
        inputFormat="dd-MM-yyyy"
        // https://date-fns.org/v2.25.0/docs/format date format cheatsheet
      />
    </LocalizationProvider>
  );
}


const ColumnContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

export function DateRangePickerComponent(
    {highlightDates, changeStartDate, changeEndDate, defaultStartDate, defaultEndDate}) {
  const {t} = useTranslation();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ColumnContainer>
        <DatePickerComponent changeDate={changeStartDate} highlightDates={highlightDates}
          label={t('Start date')} startingDate={defaultStartDate} />
        <DatePickerComponent changeDate={changeEndDate} highlightDates={highlightDates}
          label={t('End date')} startingDate={defaultEndDate}/>
      </ColumnContainer>
    </LocalizationProvider>
  );
};
