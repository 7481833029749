import React, {useState, useEffect} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/styles';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import {checkVersionSafely} from '../Snapshots/SnapshotUtils';
import {UserContext} from '../../contexts/userContext';
import {ApiClient} from '../../Utils/ApiClient';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  media: {
    width: '100%',
  },
  card: {
    minHeight: 100,
    padding: 50,
  },
  list: {
    padding: 0,
  },
  downloadButton: {
    marginTop: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  downloadButtonContainer: {
    textAlign: 'center',
  },
}));


const imageTypes = ['rawHD', 'rawSD', 'cropped', 'processed'];

function SnapshotCard(props) {
  const classes = useStyles();
  const {t} = useTranslation();

  const [currentImageUrl, setCurrentImageUrl] = useState(undefined);
  const [snapshot, setSnapshot] = useState('');
  const [currentImageType, setCurrentImageType] = useState('rawHD');

  useEffect(() => {
    // Reset the state when new data is loaded
    if (props.snapshot !== snapshot) {
      setCurrentImageUrl(undefined);
      setSnapshot(props.snapshot);
      retrieveImageUrls(props.snapshot);
    }
  }, [props.snapshot, snapshot]);


  const retrieveImageUrls = (snapshot) => {
    if (!snapshot.data.hasOwnProperty('images')) {
      return;
    }
    const images = snapshot['data']['images'];
    for (const key in images) {
      if (!images.hasOwnProperty(key)) {
        continue;
      }
      const image = images[key];

      ApiClient.downloadImage(snapshot.client, image).then((url) => {
        const currentSnapshot = snapshot;
        if (!currentSnapshot.image_urls) {
          currentSnapshot.image_urls = {};
        }
        currentSnapshot['image_urls'][key] = url;
        setSnapshot(currentSnapshot);
      });
    }
  };

  const handleClick = (imageType) => {
    setCurrentImageType(imageType);
    const key = imageType;
    const imageUrl = key in snapshot['image_urls'] ? snapshot['image_urls'][key] : '';
    setCurrentImageUrl(imageUrl);
  };

  const downloadImage = async () => {
    if (!currentImageUrl || !snapshot) {
      return;
    }
    const fileName = snapshot._id + '_' + currentImageType + '.jpg';
    try {
      const url = await ApiClient.downloadImage(snapshot.client, snapshot.data.images[currentImageType]);
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.body.removeChild(link);
    } catch (e) {
      console.log(e);
    }
  };

  return (
        snapshot ?
            <Card key={snapshot._id}>
              <img src={currentImageUrl} alt={'Selected'}
                className={classes.media} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                </Typography>
                <UserContext.Consumer>
                  {(user) => (
                            (user.level === 'admin' || user.level === 'team') && checkVersionSafely(snapshot) ?
                                <>
                                  <Typography>
                                    Version: {snapshot.data.analysis_data.version}
                                  </Typography>
                                  <Typography>
                                    Version date: {snapshot.data.analysis_data.version_date.substring(0, 9)}
                                  </Typography>
                                </> :
                                ''
                  )}
                </UserContext.Consumer>
              </CardContent>
              <CardActions>
                {imageTypes.map((imageType, index) => {
                  return (
                    <Button size="small" color="primary" key={index}
                      variant='outlined'
                      onClick={() => handleClick(imageType)} style={{padding: 5}}>
                      {t(imageType.toLowerCase() + '_image')}
                    </Button>);
                })}
                {currentImageUrl &&
                  <Button size="small" color="primary"
                    variant='contained'
                    onClick={downloadImage}>
                  DOWNLOAD IMAGE
                  </Button>
                }
              </CardActions>
            </Card> :
            ''
  );
}

export default SnapshotCard;
