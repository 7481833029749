import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {ApiClient} from '../../Utils/ApiClient';
import {Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Link, useParams} from 'react-router-dom';
import AnnotationDetails from './AnnotationDetails';
import {Accordion, AccordionSummary, Button} from '@material-ui/core';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
  },
  link: {
    'color': theme.palette.primary.contrastText,
    '&:link': {
      textDecoration: 'none',
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(1),
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
}));


function SnapshotInfoCard({snapshotId, selectedClient, annotation}) {
  const [snapshot, setSnapshot] = useState(null);
  const [instance, setInstance] = useState(null);
  const classes = useStyles();
  const {t} = useTranslation();
  const params = useParams();

  useEffect(() => {
    loadSnapshot(snapshotId);
    loadInstance(snapshotId);
  }, [snapshotId]);

  const loadSnapshot = async (snapshotId) => {
    if (!snapshotId) {
      return;
    }
    const result = await ApiClient.findSnapshotById(snapshotId);
    setSnapshot(result);
  };

  const loadInstance = async (snapshotId) => {
    if (!snapshotId) {
      return;
    }
    const result = await ApiClient.findMatchingInstance(snapshotId);
    setInstance(result);
  };

  return (
    snapshot && instance ?
    <div className={classes.card}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <b>Snapshot & Annotation Details</b>
        </AccordionSummary>
        <div className={classes.details}>
          <Typography><b>Lane: </b>{snapshot?.data?.lane ?? ''}</Typography>
          <Typography><b>SubLane: </b>{snapshot?.data?.sub_lane ?? ''}</Typography>
          <Typography><b>Hook: </b>{snapshot?.data?.position_id?.hook ?? ''}</Typography>
          <AnnotationDetails annotation={annotation} instance={instance} snapshot={snapshot}/>
        </div>
      </Accordion>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Button variant="contained" className={classes.button}>
          <Link className={classes.link}
            to={{pathname: `/${selectedClient ? selectedClient : params.client_name}/images/${snapshot?._id}`}}>
            {t('Go to full image')}
          </Link>
        </Button>
      </div>
    </div> : ''
  );
}

export default SnapshotInfoCard;
