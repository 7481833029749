
export const getUniqueLocations = (snapshots) => {
  if (snapshots === undefined || snapshots.length === 0) {
    return null;
  }
  let containsAtLeastOneLocation = false;
  const uniqueLocations = {};
  for (const snapshot of snapshots) {
    const lane = snapshot?.data?.lane;
    const subLane = snapshot?.data?.sub_lane;
    if (lane === undefined || subLane === undefined) {
      continue;
    }
    if (uniqueLocations[lane] === undefined) {
      uniqueLocations[lane] = new Set();
    }
    uniqueLocations[lane].add(subLane);
    containsAtLeastOneLocation = true;
  }
  if (!containsAtLeastOneLocation) {
    return null;
  }
  return uniqueLocations;
};
