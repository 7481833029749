import React, {useEffect, useState, useContext} from 'react';
import {useLocation} from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import {isPointInBox} from '../../Utils/AnnotationHelpers';
import {dateToStringWithTime} from '../../Utils/DateUtils';
import {ApiClient} from '../../Utils/ApiClient';
import {UserContext} from '../../contexts/userContext';
import AnnotationTable from './AnnotationTable';

const AnnotationsExportPage = () => {
  const location = useLocation();
  const [updatedAnnotations, setUpdatedAnnotations] = useState(null);
  const [progress, setProgress] = useState(0);
  const [labels, setLabels] = useState(null);
  const {getUserConfig} = useContext(UserContext);

  if (!location.state) {
    return null;
  }
  useEffect(() => {
    if (!location.state) {
      return;
    }
    updateAnnotations(location.state.annotations);
  }, []);

  useEffect(() => {
    if (!updatedAnnotations) {
      return;
    }
    const annotationLabels = getUserConfig(true).annotation_labels;
    const formattedLabels = {};
    Object.keys(annotationLabels).forEach((key) => {
      const label = annotationLabels[key];
      formattedLabels[label.value] = label;
    });
    setLabels(formattedLabels);
    setProgress(100);
  }, [updatedAnnotations]);

  const getSnapshot = async (snapshotId) => {
    if (!snapshotId) {
      return;
    }
    const result = await ApiClient.findSnapshotById(snapshotId);
    return result;
  };

  const getInstance = async (snapshotId) => {
    if (!snapshotId) {
      return;
    }
    const result = await ApiClient.findMatchingInstance(snapshotId);
    return result;
  };

  const appendAnnotationData = (annotation, snapshot, instance, plant) => {
    return {
      ...annotation,
      label: annotation?.label || 'No label',
      systemLabel: plant?.disease_classifications && plant.disease_classifications.length > 0 ?
        plant.disease_classifications[0].label : '',
      budsCount: plant?.buds.length,
      collection_date: snapshot?.collection_date, // date of snapshot
      time_of_day: dateToStringWithTime(new Date(snapshot?.collection_date)), // time of day in Amsterdam time
      type: instance?.plant_information?.article_group, // type of chrysanthemum
      lane: snapshot?.data?.lane,
      subLane: snapshot?.data?.sub_lane,
      hook: snapshot?.data?.position_id?.hook,
      speed: (snapshot?.data?.position_id?.speed * 60).toFixed(2), // speed converted to meters per minute
      plantAge: parseInt((new Date(snapshot.collection_date) -
      new Date(instance?.plant_information?.planting_date)) / 86400000),
      plantId: plant?.plant_id,
      plantHeight: (plant?.height * 100).toFixed(2), // plant height converted to centimeters
      isSpraying: snapshot?.data?.spray_status?.is_spraying,
      plantWidth: plant?.dimensions && plant.dimensions.length > 0 ?
      (plant?.dimensions[0] * 100).toFixed(2) : '', // plant width converted to centimeters
      plantLength: plant?.dimensions && plant.dimensions.length > 0 ?
      (plant?.dimensions[1] * 100).toFixed(2) : '', // plant length converted to centimeters
      plantLocationX: annotation.center_coordinate[0], // plant location x coordinate
      plantLocationY: annotation.center_coordinate[1], // plant location y coordinate
    };
  };


  const updateAnnotations = async (annotations) => {
    const snapshots = {};
    const interval = 100 / annotations.length;
    for (let i = 0; i < annotations.length; i++) {
      const index = i;
      const snapshotId = annotations[index]?.snapshot_id;
      if (!snapshots[snapshotId]) {
        const snapshot = await getSnapshot(snapshotId);
        const instance = await getInstance(snapshotId);
        snapshots[snapshotId] = {snapshot: snapshot, instance: instance};
      }
      const snapshot = snapshots[snapshotId].snapshot;
      const instance = snapshots[snapshotId].instance;
      const plant = snapshot?.data?.analysis_data?.plant_record_list.find((plant) => {
        return plant && plant.center_coordinate && plant.pixel_dimensions &&
        isPointInBox(annotations[index]?.center_coordinate,
            plant.center_coordinate, plant.pixel_dimensions);
      });
      annotations[index] = await appendAnnotationData(annotations[index], snapshot, instance, plant);
      annotations[index].time_of_day = annotations[index].time_of_day.
          split(' ')[1].split(':').slice(0, 2).join(':');
      annotations[index].isSpraying = annotations[index].isSpraying ? 'Yes' : 'No';
      setProgress((prevProgress) => prevProgress + interval);
    }
    setUpdatedAnnotations(annotations);
  };

  return (
    <div style={{marginTop: 20, display: 'flex', flexDirection: 'column',
      justifyContent: 'center', alignItems: 'center'}}>
      {progress < 100 ? <Box sx={{width: '100%'}}>
        Loading Annotations...
        <LinearProgress variant="determinate" value={progress} />
      </Box> : <>{labels && updatedAnnotations && <AnnotationTable labels={labels} data={updatedAnnotations}
        startDate={location.state.startDate} endDate={location.state.endDate}/>}</>}
    </div>
  );
};

export default AnnotationsExportPage;
