import React, {useEffect} from 'react';
import 'date-fns';
import ErrorList from './ErrorList';
import {dateToStringWithoutTime} from '../../Utils/DateUtils';
import {useHistory} from 'react-router-dom';
import {useContext} from 'react';
import {UserContext} from '../../contexts/userContext';
function getAllLocations(data) {
  const locations = {};
  for (let i = 0; i < data.length; i++) {
    const snapshot = data[i];
    appendLocation(locations, snapshot['data']);
  }
  return locations;
}

// this should be cleaned up
function getLaneInfo(data) {
  const lanes = {};
  for (let i = 0; i < data.length; i++) {
    const laneEntry = data[i].data.lane;
    const failureStatus = data[i].status;
    if (lanes[laneEntry] === undefined) {
      if (failureStatus === 'analyzed') {
        lanes[laneEntry] = {
          'analyzed': 1, 'failed': 0, 'discarded': 0,
          'errors': [],
        };
      }
      if (failureStatus === 'discarded') {
        lanes[laneEntry] = {
          'analyzed': 0, 'failed': 0, 'discarded': 1,
          'errors': [],
        };
        if (typeof data[i].failure !== 'undefined') {
          lanes[laneEntry]['errors'].push({'snapshot_id': data[i]._id, 'error_message': data[i].failure.message});
        } else {
          lanes[laneEntry]['errors'].push({'snapshot_id': data[i]._id, 'error_message': 'unknown error'});
        }
      } else if (failureStatus === 'failed') {
        lanes[laneEntry] = {
          'analyzed': 0, 'failed': 1, 'discarded': 0,
          'errors': [],
        };
        if (typeof data[i].failure !== 'undefined') {
          lanes[laneEntry]['errors'].push({'snapshot_id': data[i]._id, 'error_message': data[i].failure.message});
        } else {
          lanes[laneEntry]['errors'].push({'snapshot_id': data[i]._id, 'error_message': 'unknown error'});
        }
      }
      continue;
    }

    if (failureStatus === 'analyzed') {
      lanes[laneEntry]['analyzed'] += 1;
    }
    if (failureStatus === 'discarded') {
      lanes[laneEntry]['discarded'] += 1;
      if (typeof data[i].failure !== 'undefined') {
        lanes[laneEntry]['errors'].push({'snapshot_id': data[i]._id, 'error_message': data[i].failure.message});
      } else {
        lanes[laneEntry]['errors'].push({'snapshot_id': data[i]._id, 'error_message': 'unknown error'});
      }
    }
    if (failureStatus === 'failed') {
      lanes[laneEntry]['failed'] += 1;
      if (typeof data[i].failure !== 'undefined') {
        lanes[laneEntry]['errors'].push({'snapshot_id': data[i]._id, 'error_message': data[i].failure.message});
      } else {
        lanes[laneEntry]['errors'].push({'snapshot_id': data[i]._id, 'error_message': 'unknown error'});
      }
    }
  }
  return lanes;
}

function getTodayAsString() {
  const d = new Date();
  const datestring = dateToStringWithoutTime(d);
  return datestring;
}

function getTodayRounded() {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  return date;
}

function appendLocation(locations, snapshotData, sbris) {
  const lane = snapshotData['lane'];
  const subLane = snapshotData['sub_lane'];
  let hook = '';
  let distance = '';
  if (sbris === false) {
    hook = snapshotData['position_id']['hook'];
    distance = snapshotData['position_id']['distance_previous_hook_rounded'];
  }
  if (!(lane in locations)) {
    locations[lane] = {};
  }
  if (!(subLane in locations[lane])) {
    locations[lane][subLane] = {};
  }
  if (sbris === false) {
    if (!(hook in locations[lane][subLane])) {
      locations[lane][subLane][hook] = {};
    }
    if (!(distance in locations[lane][subLane][hook])) {
      locations[lane][subLane][hook][distance] = {};
    }
  }
}

const ErrorPage = ({data, clickItem, sbris}) => {
  const history = useHistory();
  const {currentClient} = useContext(UserContext);
  useEffect(() => {
    history.push((`/${currentClient || 'admin'}/errors`));
  }, [currentClient]);
  return (
    <ErrorList
      data={data}
      clickItem={clickItem}
      sbris={sbris}
      listUtils={{
        'getAllLocations': getAllLocations,
        'getToday': getTodayAsString,
        'getTodayRounded': getTodayRounded,
        'getLaneInfo': getLaneInfo,
      }}
    />
  );
};

export default ErrorPage;
