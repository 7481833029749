import {makeStyles} from '@material-ui/styles';
import CardContent from '@material-ui/core/CardContent';
import CommentBox from './CommentBox';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {UserContext} from '../../contexts/userContext';
import {ApiClient} from '../../Utils/ApiClient';
import {Checkbox} from '@material-ui/core';
import React, {useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  card: {
    minHeight: 100,
    padding: 25,
  },
  comment: {
    textAlign: 'left',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  commentField: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  sendButton: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
}));

function CommentSection(props) {
  const {t} = useTranslation();
  const classes = useStyles();
  const [comment, setComment] = useState('');
  const [comments, setComments] = useState([]);
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    fetchComments();
  }, []);

  useEffect(() => {
    setIsChecked(props.notifyADIDefault);
  }, [props.notifyADIDefault]);

  useEffect(() => {
    fetchComments();
  }, [props.data]);

  const fetchComments = () => {
    ApiClient.findCommentsBySnapshotId(props.data._id).then((comments) => {
      setComments(comments);
    });
  };

  const handleClick = () => {
    ApiClient.insertComment(comment, props.data._id, isChecked).then((response) => {
      fetchComments();
    });
    setComment('');
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <CardContent className={classes.card}>
        <Typography variant="body2" color="textSecondary" component="p">
          {t('Let us know')}
        </Typography>
        <TextField
          className={classes.commentField}
          value={comment ?? ''}
          onChange={(e) => setComment(e.target.value)}
          id="standard-multiline-static"
          label={t('Type comment')}
          multiline
        />
        <div>
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            color="primary"
          />
          <span>Notify ADI</span>
        </div>
        {comment ?
                    <UserContext.Consumer>
                      {(user) => (
                        <Button onClick={() => handleClick(user.username)}
                          className={classes.sendButton}
                          color="primary">{t('Send')}</Button>
                      )}
                    </UserContext.Consumer> :
                    ''}
      </CardContent>
      <CardContent className={classes.card}>
        <Typography variant="h6" color="textPrimary" component="h6">
          {t('Current Comments')}
        </Typography>
        <p></p>
        {comments.length === 0 ? <Typography variant="body2" color="textSecondary" component="p">
            There are currently no comments for this image.
        </Typography> : comments.map((item) => <CommentBox key={item._id} message={item}></CommentBox>)}
      </CardContent>
    </>
  );
}


export default CommentSection;
