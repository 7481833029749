import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import PlantDiv from './PlantDiv';
import {useAnnotation} from '../../Annotations/AnnotationContext';
import {Button, Snackbar} from '@mui/material';
import '../PlantBoxes.css';

function PlantOverlay({snapshot, downscalingRatio, setSelectedPlant}) {
  const [plants, setPlants] = useState([]);
  const {showPlantOverlay} = useAnnotation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!snapshot) {
      return;
    }
    setPlants(snapshot?.data?.analysis_data?.plant_record_list ?? []);
  }, [snapshot]);

  const handleCopyAll = () => {
    if (plants.length === 0) {
      return;
    }
    const text = plants.map((plant, index) => `${plant.plant_id},${snapshot._id}`).join('\n');
    navigator.clipboard.writeText(text);
    setOpen(true);
  };
  return (
    plants.length > 0 && showPlantOverlay ?
    <div>
      <div style={{position: 'absolute', right: 5, top: 5}}>
        <Button variant="contained" size='small'
          style={{'backgroundColor': 'rgba(25, 164, 85, .7)', 'color': 'white', 'border': '1px solid rgb(25, 164, 85)'}}
          onClick={handleCopyAll}>Copy All Plant IDs</Button>
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={2000}
          message="Copied all plant IDs to clipboard"
        />
      </div>
      {plants.map((plant) => {
        return <PlantDiv key={plant.plant_id + '_div'} plant={plant}
          downscalingRatio={downscalingRatio} snapshotId={snapshot._id} setPlantInDropDown={setSelectedPlant}>
        </PlantDiv>;
      })}
    </div> : ''
  );
}

export default PlantOverlay;
