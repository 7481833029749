import React from 'react';

// Component to render the lane label and the rectangle surrounding it
const LaneLabel = ({laneIndex, lane, boundsWidth, xScale, labelY, laneHeight}) => {
  let laneLabelColor = 'lightgrey';
  if (lane.laneBatchInfo) {
    laneLabelColor = 'green';
  } else if (lane.laneBatchInfo === false) {
    laneLabelColor = 'red';
  }
  return (
    <g key={`label-${laneIndex}`} transform={`translate(${boundsWidth + 5},${labelY})`}>
      <rect
        width={xScale.bandwidth()}// Height of the rectangle should be equal to lane height
        height={laneHeight}// Width of the rectangle should be equal to the cell width
        fill={laneLabelColor}
        stroke="black"
        rx={5}
      />
      <text
        dominantBaseline="middle"
        fontSize={24}
        style={{fontWeight: 'bold', zIndex: 1000}}
        transform={`translate(30, 130) rotate(90)`}
        z={1000}
      >
        {`Lane ${lane.laneId}`}
      </text>
    </g>
  );
};

export default LaneLabel;
