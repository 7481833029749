import {Card, InputLabel, Typography} from '@material-ui/core';
import {styled} from '@mui/material/styles';

export const CardWithMargins = styled(Card)(({theme}) => ({
  ...({
    margin: theme.spacing(3),
    marginTop: 0,
  }),
}));

export const InputLabelWithTopMargin = styled(InputLabel)(({theme}) => ({
  ...({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  }),
}));

export const Header5 = styled(Typography)(({theme}) => ({
  ...({
    textAlign: 'left',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(4),
    color: 'black',
  }),
}));

export const todayAsISODate = (endOfTheDay = false) => {
  const dateObject = new Date();
  if (endOfTheDay) {
    dateObject.setHours(23, 59, 59, 999);
  } else {
    dateObject.setHours(0, 0, 0, 0);
  }
  return dateObject.toISOString();
};
