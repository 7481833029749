import React from 'react';
import Typography from '@material-ui/core/Typography';

class CommentBox extends React.Component {
  render() {
    return (
      <>
        <Typography variant="body2" color="textSecondary" component="p">
          <b>{this.props.message.username}</b>: {this.props.message.message}
        </Typography>
      </>
    );
  }
}

export default CommentBox;
