import React, {useContext, useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {CredentialsContext} from '../../contexts/credentialsContext';


const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(2),
  },
  signIn: {
    marginTop: theme.spacing(4),
  },
}));


function Login(props) {
  const classes = useStyles();
  const {t} = useTranslation();
  const {login, ping} = useContext(CredentialsContext);

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [failure, setFailure] = useState('');
  const [emptyName, setEmptyName] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setEmptyName(false);
    setIncorrectPassword(false);
    if (!name || name === '') {
      setEmptyName(true);
      return;
    }
    ping().then((result) => {
      login(name, password).then((loggedIn) => {
        if (!loggedIn) {
          setIncorrectPassword(true);
        }
      });
    }).catch((error) => {
      setFailure('Failed to reach server with error: ' + error.code + ' ' + error.message);
      return;
    });
  };

  const isChrome = () => {
    return /Chrome/.test(navigator.userAgent);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Grid
        container
        direction="column"
        alignItems="center"

        justifyContent="center"
        style={{height: '80vh'}}>

        <Typography component="h1" variant="h5">
          {t('Sign In')}
        </Typography>
        <Paper className={classes.paper}>
          <form noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="username"
              label={t('Username')}
              name="name"
              autoComplete="username"
              autoFocus
              error={emptyName}
              helperText={emptyName ? 'Enter an Username' : ''}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              label={t('Password')}
              type="password"
              id="password"
              autoComplete="current-password"
              error={incorrectPassword}
              helperText={incorrectPassword ? 'Incorrect password' : ''}
            />
            {failure ? <Typography variant="body" color="error" align="center">
              {failure}
            </Typography> : ''}
            <Button
              className={classes.signIn}
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              id="sign-in-button"
            >
              {t('Log In')}
            </Button>
          </form>
        </Paper>
      </Grid>
      {!isChrome() ? <Typography variant="body" color="error" align="center">
        You are not using a recommended browser, please use Google Chrome.
      </Typography> : ''}
    </Container>
  );
}

export default Login;
