import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import './style.css';

import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';
import {CredentialsProvider} from './contexts/credentialsContext';

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const history = createBrowserHistory();
ReactDOM.render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <Router history={history}>
          <CredentialsProvider>
            <App />
          </CredentialsProvider>
        </Router>
      </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
