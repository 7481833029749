import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {ApiClient} from '../../Utils/ApiClient';
import LanesWithBatches from './LanesWithBatches';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    height: '70vh',
    width: '100%',
  },
}));

function BatchesMap({greenhouse, greenhouseStats, client}) {
  const [batches, setBatches] = useState(null);
  const [lanes, setLanes] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setBatches(null);
    setLanes(null);
    fetchBatches();
  }, [greenhouse]);

  useEffect(() => {
    if (batches && batches.length > 0) {
      setLanes(getLanesFromBatches());
    }
  }, [batches]);


  const getLanesFromBatches = () => {
    // eslint-disable-next-line max-len
    // lanes with data structure = {<laneNumber>: [{inLaneLocations: <[location, location, ...]>, batchData: <batchData>}, ...]}
    const lanesWithData = {};
    if (!batches) {
      return null;
    }
    for (const batch of batches) {
      const inGreenhouseLocations = batch.batch_information.in_greenhouse_locations;
      for (const location of inGreenhouseLocations) {
        const laneNumber = location.container.container_location.lane;
        if (!lanesWithData[laneNumber.toString()]) {
          lanesWithData[laneNumber] = [];
        }
        const locations = inGreenhouseLocations.map((location) => {
          return {sublane: location.container.container_location.sublane, topLeft: location.coordinates.top_left,
            bottomRight: location.coordinates.bottom_right};
        });
        lanesWithData[laneNumber].push({inLaneLocations: locations, batchData: batch});
      }
    }
    return lanesWithData;
  };

  const fetchBatches = () => {
    ApiClient.findBatches(greenhouse).then((batches) => {
      setBatches(batches);
    });
  };

  return (
    <div className={classes.container}>
      {batches && lanes && greenhouseStats ? <LanesWithBatches lanesWithData={lanes}
        greenhouseStats={greenhouseStats} client={client}/> : <h1>No Batches Found</h1>}
    </div>
  );
};

export default BatchesMap;
