import React from 'react';
import {ReactComponent as SuccessIcon} from './icons/success-3.svg';
import {ReactComponent as FailureIcon} from './icons/fail-1.svg';
import {ReactComponent as IncompleteIcon} from './icons/incomplete.svg';
const SnapshotMapKeyLabels = ({width}) => {
  return (
    <g className='snapshot-map-keys'>
      <rect
        x={width - 50}
        y={10}
        width={40}
        height={40}
        rx={5}
        stroke={'black'}
        fill='green'
        className='analyzed-key'
      />
      <SuccessIcon x={width - 45} y={15}/>
      <text
        x={width - 120}
        y={30}
        textAnchor="middle"

        dominantBaseline="middle"
        fontSize={14}
        style={{fontWeight: 'bold', zIndex: 1000}}
      >
          Analyzed Snapshot
      </text>
      <rect
        x={width - 50}
        y={60}
        width={40}
        height={40}
        rx={5}
        stroke={'black'}
        fill='red'
        className='failed-key'
      />
      <FailureIcon x={width - 45} y={65}/>
      <text
        x={width - 110}
        y={80}
        textAnchor="middle"

        dominantBaseline="middle"
        fontSize={14}
        style={{fontWeight: 'bold', zIndex: 1000}}
      >
          Failed Snapshot
      </text>
      <rect
        x={width - 50}
        y={110}
        width={40}
        height={40}
        rx={5}
        stroke={'black'}
        fill='orange'
      />
      <IncompleteIcon x={width - 45} y={115}/>
      <text
        x={width - 125}
        y={130}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={14}
        style={{fontWeight: 'bold', zIndex: 1000}}
      >
          Incomplete Snapshot
      </text>
      <rect
        x={width - 50}
        y={160}
        width={40}
        height={40}
        rx={5}
        stroke={'black'}
        fill='grey'
        // set opacity to 1 on hover
      />
      <line x1={width - 45} y1={165} x2={width - 15} y2={195} stroke="black" strokeWidth={2} opacity={0.5}/>
      <line x1={width - 15} y1={165} x2={width - 45} y2={195} stroke="black" strokeWidth={2} opacity={0.5}/>
      <text
        x={width - 120}
        y={180}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={14}
        style={{fontWeight: 'bold', zIndex: 1000}}
      >
          Discarded Snapshot
      </text>
      <rect
        x={width - 50}
        y={210}
        width={40}
        height={40}
        rx={5}
        stroke={'black'}
        fill='white'
      />
      <text
        x={width - 80}
        y={230}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={14}
        style={{fontWeight: 'bold', zIndex: 1000}}
      >
          No Data
      </text>
    </g>
  );
};

export default SnapshotMapKeyLabels;
