export const getDatePlusInterval = (interval = 0, endOfDay) => {
  const dateObject = new Date();
  dateObject.setDate(dateObject.getDate() + interval);
  if (endOfDay) {
    dateObject.setHours(23, 59, 59, 999);
  } else {
    dateObject.setHours(0, 0, 0, 0);
  }
  return dateObject;
};

export const dateToStringWithoutTime = (d) => {
  if (typeof d === 'string') {
    d = new Date(d);
  }
  return (d.getFullYear()) + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2);
};

export const dateToStringWithTime = (d) => {
  return (d.getFullYear()) + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2) + ' ' +
  ('0' + d.getHours()).slice(-2) + ':' + ('0' + d.getMinutes()).slice(-2) + ':' + ('0' + d.getSeconds()).slice(-2);
};

export const dateToRange = (d, toIso = true) => {
  d.setHours(0, 0, 0, 0);
  let startDate;
  startDate = d;
  if (toIso) {
    startDate = d.toISOString();
  }
  let endDate = new Date(d);
  endDate.setDate(d.getDate() + 1);
  if (toIso) {
    endDate = endDate.toISOString();
  }
  return [startDate, endDate];
};
