import React from 'react';
import './batchStyles.css';
const Calibrations = ({calibrations, sublane, sublanes, laneLength, laneWidth, sublaneWidth,
  setHideCalibrationTooltip}) => {
  const percentageWidthUnit = 100 / laneLength;
  const percentageHeightUnit = 100 / laneWidth;
  const yOffset = sublanes.indexOf(sublane) * sublaneWidth;
  return (
    <div>
      {calibrations.map((calibration, index) => {
        return (
          <div key={index} style={{
            position: 'absolute',
            top: `${(yOffset + 0.8) * percentageHeightUnit}%`,
            left: `${calibration.coordinates.y * percentageWidthUnit}%`,
            width: '10px',
            height: '10px',
            border: '2px solid black',
            zIndex: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transform: `translate(-50%, -50%)`,
          }} className='calibrations'>
            <div className='calibration-tooltip' onMouseOver={() => {
              setHideCalibrationTooltip(true);
            }} onMouseLeave={() => {
              setHideCalibrationTooltip(false);
            }}>
              <div className='tooltiptext'>
                <p>Ground to Pipe Distance: {calibration.ground_to_pipe_distance.toFixed(3)}m</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Calibrations;
