// Cart.js
import {Button} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getSnapshotTitle} from '../../Utils/SnapshotHelper';
import {StyledDeleteIcon} from './CartIcons';

const Cart = ({cartItems, removeFromCart, removeAllFromCart}) => {
  const {t} = useTranslation();

  return (
    <div >
      <h2>{t('Cart')}</h2>
      <ul >
        {cartItems.length > 0 ?
                    cartItems.map((item) => (
                      <li key={item._id}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <div>{getSnapshotTitle(item, true, true)}</div>
                          <StyledDeleteIcon style={{paddingLeft: '5px'}} color="primary"
                            onClick={() => removeFromCart(item._id)} />
                        </div>
                      </li>
                    )) : <div>{t('No items in cart')}</div>
        }
      </ul>
      <Button variant="contained" color="primary" onClick={removeAllFromCart} style={{width: '30%', maxWidth: 150}}>
        {t('Remove All')}
      </Button>
    </div>
  );
};

export default Cart;
