import React from 'react';
import {Card, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import generateValueBasedColor from '../../Utils/HeatmapUtils';
const useStyles = makeStyles((theme) => ({
  failure: {
    backgroundColor: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.primary.main,
  },
  systemEvaluationCard: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25%',
    padding: 5,
  },
}));

const SystemEvaluationResults = ({checks, pipeToSensorDistance}) => {
  const classes = useStyles();
  const evaluationResults = checks.filter((check) => check.name.includes('Identifier'));
  if (evaluationResults.length === 0) {
    return null;
  }
  const distanceToMarker30 = checks.find((check) => check.name === 'Distance to marker 30').actual_value;
  const distanceToMarker31 = checks.find((check) => check.name === 'Distance to marker 31').actual_value;
  const heightDifference = ((distanceToMarker30 - distanceToMarker31) * 100).toFixed(2);
  const pipeToMarker30Height = ((distanceToMarker30 + pipeToSensorDistance) * 100).toFixed(2);
  const pipeToMarker31Height = ((distanceToMarker31 + pipeToSensorDistance) * 100).toFixed(2);
  const rotationOfRawSD = checks.find((check) => check.name === 'Rotation of raw_sd').actual_value.toFixed(2);
  const rotationOfRawHD = checks.find((check) => check.name === 'Rotation of raw_hd').actual_value.toFixed(2);
  const rawSdHeatmapColor = rotationOfRawSD === 0 ? '#23A455' : generateValueBasedColor(rotationOfRawSD, 0, 90);
  const rawHdHeatmapColor = rotationOfRawHD === 0 ? '#23A455' : generateValueBasedColor(rotationOfRawHD, 0, 90);
  const verticalTilt = checks.find((check) => check.name === 'Tilt vertical').actual_value.toFixed(2);
  const verticalTiltValid = Math.abs(verticalTilt) <= 0.05;
  const horizontalTilt = checks.find((check) => check.name === 'Tilt horizontal').actual_value.toFixed(2);
  const horizontalTiltValid = Math.abs(horizontalTilt) <= 0.05;
  return (
    <div>
      <Typography variant="body3" color="textSecondary"><b>System Evaluation Results:</b></Typography>
      <Typography variant="body2" color="textSecondary" style={{marginTop: 10}}>
        <b>Detected Identifiers:</b>
      </Typography>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        {evaluationResults.map((result, id) => <Card key={id + '_evaluation_result'}
          className={`${classes.systemEvaluationCard} 
          ${result.actual_value === 1 ? classes.success : classes.failure}`}>
          <Typography variant="body2"
            color="textSecondary" >{result.name}</Typography></Card>)}
      </div>
      <Typography variant='body2' color='textSecondary' style={{marginTop: 10}}>
        <b>
                  Height difference between markers 30 & 31:
        </b>
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        {heightDifference} cm
      </Typography>
      <Typography variant='body2' color='textSecondary' style={{marginTop: 10}}>
        <b>
                  Pipe to marker height (marker 30):
        </b>
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        {pipeToMarker30Height} cm
      </Typography>
      <Typography variant='body2' color='textSecondary' style={{marginTop: 10}}>
        <b>
                  Pipe to marker height (marker 31):
        </b>
      </Typography>
      <Typography variant='body2' color='textSecondary'>
        {pipeToMarker31Height} cm
      </Typography>
      <Typography variant='body2' color='textSecondary' style={{marginTop: 10}}>
        <b>
                  Rotation of raw_hd:
        </b>
      </Typography>
      <Card className={classes.systemEvaluationCard} style={{backgroundColor: rawHdHeatmapColor}}>
        <Typography variant='body2' color='textSecondary'>
          {rotationOfRawHD} degrees
        </Typography>
      </Card>
      <Typography variant='body2' color='textSecondary' style={{marginTop: 10}}>
        <b>
                  Rotation of raw_sd:
        </b>
      </Typography>
      <Card className={classes.systemEvaluationCard} style={{backgroundColor: rawSdHeatmapColor}}>
        <Typography variant='body2' color='textSecondary'>
          {rotationOfRawSD} degrees
        </Typography>
      </Card>
      <Typography variant='body2' color='textSecondary' style={{marginTop: 10}}>
        <b>
                  Vertical Tilt:
        </b>
      </Typography>
      <Card
        className={`${classes.systemEvaluationCard} 
          ${verticalTiltValid ? classes.success : classes.failure}`}>
        <Typography variant='body2' color='textSecondary'>
          {verticalTilt} meters
        </Typography>
      </Card>
      <Typography variant='body2' color='textSecondary' style={{marginTop: 10}}>
        <b>
                  Horizontal Tilt:
        </b>
      </Typography>
      <Card
        className={`${classes.systemEvaluationCard} 
          ${horizontalTiltValid ? classes.success : classes.failure}`}>
        <Typography variant='body2' color='textSecondary'>
          {horizontalTilt} meters
        </Typography>
      </Card>
    </div>
  );
};

export default SystemEvaluationResults;
