import React, {useState, useEffect} from 'react';
import Card from '@material-ui/core/Card';
import 'date-fns';
import CardContent from '@material-ui/core/CardContent';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import {FormControl, InputLabel, Select, MenuItem} from '@mui/material';

function FilterByLocationId({indexData, filteredData, setFilteredData, selectedDate, selectedButton}) {
  const [locationIds, setLocationIds] = useState(['All locations']);
  const [currentLocationId, setCurrentLocationId] = useState('All locations');
  const [unsortedData, setUnsortedData] = useState([]);
  const [unsortedDataSaved, setUnsortedDataSaved] = useState(false);
  const {t} = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (filteredData.length > 0 && !unsortedDataSaved) {
      setUnsortedData(filteredData);
      setUnsortedDataSaved(true);
      extractLocationIds();
    }
  }, [filteredData]);

  useEffect(() => {
    setUnsortedDataSaved(false);
    setUnsortedData([]);
    setLocationIds(['All locations']);
    setUnsortedData([]);
    setCurrentLocationId('All locations');
  }, [selectedDate, selectedButton]);


  useEffect(() => {
    filterSnapshotsByLocationId();
  }, [currentLocationId]);

  const filterSnapshotsByLocationId = () => {
    if (currentLocationId === 'All locations') {
      setFilteredData(indexData(unsortedData));
    } else {
      const sortedData = unsortedData.filter((snapshot) => snapshot.data.location_id === currentLocationId);
      setFilteredData(indexData(sortedData));
    }
  };

  const extractLocationIds = () => {
    if (filteredData.length > 0) {
      const types = new Set(filteredData.map((snapshot) => snapshot.data.location_id));
      setLocationIds(['All locations'].concat(Array.from(types)));
    }
  };

  const handleLocationIdChange = (event) => {
    setCurrentLocationId(event.target.value);
  };

  return (
    <Card
      style={{
        maxHeight: 500,
        overflow: 'auto',
        position: 'relative',
        marginBottom: theme.spacing(2),
      }}>
      <CardContent>
        <FormControl style={{
          marginRight: theme.spacing(1),
          width: 200,
        }}>
          <InputLabel id="location-select-label">{t('Filter By Location ID')}</InputLabel>
          <Select
            value={currentLocationId}
            label="filter-by-location-id"
            onChange={handleLocationIdChange}
            style={{marginRight: theme.spacing(1)}}
          >
            {locationIds.length > 1 && locationIds.map((locationId, index) =>
              <MenuItem value={locationId} key={index}>{locationId}</MenuItem>,
            )}
          </Select>
        </FormControl>
      </CardContent>
    </Card>
  );
}

export default FilterByLocationId;
