import React from 'react';
import {Button, Checkbox, FormControl, Grid, Typography} from '@material-ui/core';
import {Link, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useAnnotation} from './AnnotationContext';
import {usePlantSelection} from '../Images/ResultVisualization/PlantSelectionContext';
import {RegularSpacer} from '../BasicComponents/Spacers';
function PlantAnnotationPanel({allPlantIds}) {
  const {generatePlantAnnotation,
    alreadyAnnotatedPlantIds, setShowPlantOverlay, showPlantOverlay} = useAnnotation();
  const {selectedPlant} = usePlantSelection();

  const {t} = useTranslation();
  const params = useParams();
  const onSubmit = () => {
    generatePlantAnnotation([selectedPlant.plant_id]);
  };

  const onSubmitAll = () => {
    if (!allPlantIds || !alreadyAnnotatedPlantIds) {
      return;
    }
    const unannotatedIds = allPlantIds.filter((id) => !alreadyAnnotatedPlantIds.includes(id));
    if (unannotatedIds.length === 0) {
      return;
    }
    generatePlantAnnotation(unannotatedIds);
  };

  const showPlantSpecificButton = () => (
    selectedPlant &&
    !alreadyAnnotatedPlantIds.includes(selectedPlant?.plant_id) &&
    selectedPlant?.disease_classifications?.length > 0
  );

  const allPlantsAnnotated = () => {
    return allPlantIds.length === alreadyAnnotatedPlantIds.length;
  };

  return (
    <div>
      <Typography variant="body2"
        color="textSecondary"><b>{t('Annotate plant')}</b></Typography>
      <FormControl>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Checkbox
            checked={showPlantOverlay}
            onChange={(event) => setShowPlantOverlay(event.target.checked)}
            inputProps={{'aria-label': 'primary checkbox'}}
          />
          <Typography variant="body2" color="textSecondary">{t('Show boxes on image')}</Typography>
        </Grid>
      </FormControl>
      <Typography variant="body2"
        color="textSecondary">
        {t('Plants marked for annotation can be found and annotated on') + ' '}
        <Link to={{pathname: `/${params.client_name}/annotations`}}>{t('this page')}</Link>.
      </Typography>
      {allPlantsAnnotated() ?
        <Typography variant="body2" color="textSecondary">
          {t('All plants have been annotated')}
        </Typography> :
        <span>
          <RegularSpacer />
          <Grid container spacing={5} alignItems="flex-end">
            <Grid item xs={6}>
              <Button variant="contained" color='primary' onClick={onSubmitAll}>
                {t('Mark all center plants')}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <FormControl>
                {showPlantSpecificButton() ?
                  <Button variant="contained" color='primary' onClick={onSubmit}>
                    {t('Mark plant ') + selectedPlant.plant_id}
                  </Button> :
                  ''}
              </FormControl>
            </Grid>
          </Grid>
        </span>
      }
    </div>
  );
}

export default PlantAnnotationPanel;
