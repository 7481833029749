import React, {useState, useEffect} from 'react';
import clientConfig from '../../Config/clients.json';
import UsageOverviewComponent from './UsageOverviewComponent';
import {Grid, Snackbar, useTheme} from '@material-ui/core';

function UsageOverviewPage() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [message, setMessage] = useState('');

  const theme = useTheme();

  useEffect(() => {
    setDates();
  }, []);

  const setDates = async () => {
    const date1 = new Date();
    const date2 = new Date();
    date1.setHours(0, 0, 0, 0);
    date1.setDate(date1.getDate() - 31 * 6);
    setStartDate(date1);
    setEndDate(date2);
  };


  return (
    <div style={{'width': '70%'}}>
      <Grid container>
        {Object.values(clientConfig.names).map((client) => (
          <Grid item xs={4} key={client} style={{'padding': theme.spacing(4)}}>
            <h2>{client}</h2>
            <UsageOverviewComponent startDate={startDate} endDate={endDate} client={client} hoverMessage={setMessage} />
          </Grid>
        ))}
      </Grid>
      {message !== '' ?
      <Snackbar
        autoHideDuration={10000}
        open={open}
        message={message}
        onClose={() => setMessage('')}
      /> : ''}
    </div>
  );
}
export default UsageOverviewPage;
