import React, {useEffect, useState} from 'react';
import {InputLabel, Select, MenuItem} from '@material-ui/core';
import TrayBatch from './TrayBatch';
import {DatePickerComponent} from '../../Utils/DatePickers';
import './batchStyles.css';

const MAX_PLANTING_DATE_WEEKS = 12;
const MIN_PLANTING_DATE_WEEKS = 6;


function TraysWithBatches({traysWithData, greenhouseStats}) {
  const [selectedTrayType, setSelectedTrayType] = useState(null);
  const [selectedTrayNumber, setSelectedTrayNumber] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (!selectedTrayType) {
      return;
    }
    setSelectedTrayNumber(null);
    setEndDate(null);
  }, [selectedTrayType]);


  const trayTypes = greenhouseStats.tray_types;

  const trayContainerStyle = {backgroundColor: 'lightgrey', width: '100%',
    flex: 1, display: 'flex', flexDirection: 'column',
    justifyContent: 'center', alignContent: 'center', alignItems: 'center',
    position: 'relative'};

  const getTrayBatches = (trayNumber) => {
    const batches = traysWithData[trayNumber];
    // order batches
    batches.sort((a, b) => a.batchOrder - b.batchOrder);
    const trayStats = greenhouseStats.tray_types.find((type) => type.tray_type === selectedTrayType);
    // filter out batches with planting date newer than 6 weeks and older than 12 weeks from the selected date
    const endPlantingDate = new Date(endDate);
    endPlantingDate.setDate(endPlantingDate.getDate() - MIN_PLANTING_DATE_WEEKS * 7);
    const startPlantingDate = new Date(endDate);
    startPlantingDate.setDate(startPlantingDate.getDate() - MAX_PLANTING_DATE_WEEKS * 7);
    const filteredBatches = batches.filter((batch) => {
      const plantingDate = new Date(batch.batchData.planting_date);
      return plantingDate >= startPlantingDate && plantingDate <= endPlantingDate;
    });
    return <TrayBatch trayBatches={filteredBatches} trayStats={trayStats}/>;
  };

  const getTrayNumberList = (trayType) => {
    const trayDataKeys = Object.keys(traysWithData);
    const trayNumbers = [];
    for (const trayNumber of trayDataKeys) {
      if (trayNumber.startsWith(trayType)) {
        trayNumbers.push(trayNumber);
      }
    }
    // sort tray number strings by number value
    trayNumbers.sort((a, b) => parseInt(parseInt(b.split('-')[1] - a.split('-')[1])));
    return trayNumbers;
  };

  const handleChangeDate = (event) => {
    setEndDate(event);
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100%',
      width: '100%', justifyContent: 'center',
      alignItems: 'center', alignContent: 'center'}}>
      <div className='dropdowns-container'>
        <div
        >
          <InputLabel id="label-select" style={{marginBottom: '10px'}}>Select Tray Type</InputLabel>
          <Select
            value={selectedTrayType}
            label="select-tray"
            onChange={(event) => setSelectedTrayType(event.target.value)}
            variant='outlined'
            style={{width: 150}}
          >
            {trayTypes.map((type, index) =>
              <MenuItem key={index} value={type.tray_type}>
                {type.tray_type}
              </MenuItem>,
            )}
          </Select>
        </div>
        {selectedTrayType &&
      <div style={{marginBottom: '10px'}}
      >
        <InputLabel id="label-select" style={{marginBottom: '10px'}}>Select Tray Number</InputLabel>
        <Select
          value={selectedTrayNumber}
          label="select-lane"
          onChange={(event) => setSelectedTrayNumber(event.target.value)}
          variant='outlined'
          style={{width: 150}}
        >
          {getTrayNumberList(selectedTrayType).map((trayNumber, index) =>
            <MenuItem key={index} value={trayNumber}>
              {trayNumber}
            </MenuItem>,
          )}
        </Select>
      </div>
        }
        <div style={{marginBottom: '10px'}}>
          {
          selectedTrayNumber ?
          <>
            <InputLabel id="label-select" style={{marginBottom: '10px'}}>Specify the Ending Date</InputLabel>
            <DatePickerComponent
              id="date"
              label="Date"
              changeDate={handleChangeDate}
            /> </>:
          <p>Select a tray number to view its batch information</p>

          }
        </div>
      </div>

      <div style={trayContainerStyle}>
        {endDate ?
        <>
          {getTrayBatches(selectedTrayNumber)}
        </> :
        <p>Set an ending date to view batches planted between {MAX_PLANTING_DATE_WEEKS} and {MIN_PLANTING_DATE_WEEKS }
          &nbsp;weeks from the selected date</p>
        }
      </div>
    </div>
  );
}

export default TraysWithBatches;
