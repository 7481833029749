export default class InBedPosition {
  constructor(lane = '', subLane = '', hook = null, distance = null) {
    this.lane = lane;
    this.subLane = subLane;
    this.hook = hook;
    this.distance = distance;
  }

  getQuery() {
    const query = {};
    if (this.lane !== null && this.lane !== '') {
      query['data.lane'] = this.lane;
    }
    if (this.subLane !== null && this.subLane !== '') {
      query['data.sub_lane'] = this.subLane;
    }
    if (this.hook !== null) {
      query['data.position_id.hook'] = Number(this.hook);
    }
    if (this.distance !== null) {
      query['data.position_id.distance_previous_hook_rounded'] = Number(this.distance);
    }

    return query;
  }
}
