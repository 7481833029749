import React, {useContext} from 'react';
import {OverviewMapContext} from '../../contexts/OverviewMapContext';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

// This component displays the current filters applied to the map view.
const MapFilterChips = () => {
  const {snapshotFilters, setSnapshotFilters} = useContext(OverviewMapContext);
  const chipStyle = {height: 30, fontSize: 16, backgroundColor: '#23A455', color: 'white'};
  return (
    <Stack direction='row' spacing={1}>
      { snapshotFilters.status !== 'all' &&
      <Chip
        label={`${snapshotFilters.status.toLowerCase()} snapshots`}
        variant="contained"
        onDelete={() => setSnapshotFilters({...snapshotFilters, status: 'all'})}
        style={chipStyle}
      />
      }
      { snapshotFilters.maxSystemSpeed !== 60 &&
      <Chip
        label={`max. speed: ${snapshotFilters.maxSystemSpeed} m/min`}
        variant="contained"
        onDelete={() => setSnapshotFilters({...snapshotFilters, maxSystemSpeed: 60})}
        style={chipStyle}
      />
      }
      { snapshotFilters.timeOfDay &&
      <Chip
        label={`snapshots taken after ${new Date(snapshotFilters.timeOfDay).getHours().toString().padStart(2, '0')}:
        ${new Date(snapshotFilters.timeOfDay).getMinutes().toString().padStart(2, '0')}`}
        variant="contained"
        onDelete={() => setSnapshotFilters({...snapshotFilters, timeOfDay: null})}
        style={chipStyle}
      />
      }
      { snapshotFilters.isSpraying !== 'all' &&
      <Chip
        label={`spray status: ${snapshotFilters.isSpraying.toLowerCase()}`}
        variant="contained"
        onDelete={() => setSnapshotFilters({...snapshotFilters, isSpraying: 'all'})}
        style={chipStyle}
      />
      }
    </Stack>
  );
};

export default MapFilterChips;
