import React from 'react';
import {Card, CardContent, useTheme} from '@material-ui/core';
import {useState} from 'react';
import {Link} from 'react-router-dom';
import {useEffect} from 'react';

const DatabaseDocumentCard = ({item}) => {
  const [links, setLinks] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    addLinks(item.body);
  }, [item]);

  const addLinks = (body) => {
    if (body.analysis_config) {
      const analysisConfig = body.analysis_config;
      const query = `selectedTable=Analysis+configs&query={"_id":"${analysisConfig}"}`;
      const link = <Link id={`link_to_analysis_config_${analysisConfig}`}
        key={`link_to_analysis_config_${analysisConfig}`}
        to={{pathname: '/admin/database-documents', search: `?${query}`}}>
        Find analysis config
      </Link>;
      setLinks([...links, link]);
    }
  };


  return (
    <Card style={{margin: theme.spacing(1)}}>
      <CardContent>
        <h3>{item.title}</h3>
        <pre>{JSON.stringify(item.body, null, 2)}</pre>
        {links.map((link) => link)}
      </CardContent>
    </Card>
  );
};

export default DatabaseDocumentCard;
