import React, {useContext} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@mui/icons-material/Done';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import 'date-fns';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/styles';
import {CartContext} from '../ShoppingCart/CartContext';
import {ConstructionAddIcon, ConstructionAddedIcon} from '../ShoppingCart/CartIcons';
import {getSnapshotTitle, getPlantCount} from '../../Utils/SnapshotHelper';

const useStyles = makeStyles((theme) => ({
  listItem: {
    'padding': theme.spacing(2),
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main + ' !important',
      color: '#ffffff !important',
    },
  },
  listItemDiscarded: {
    'padding': theme.spacing(2),
    'backgroundColor': theme.palette.secondary.light + ' !important',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main + ' !important',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main + ' !important',
      color: '#ffffff !important',
    },
  },
  listItemDeleted: {
    'padding': theme.spacing(2),
    'backgroundColor': '#757575 !important',
    'textDecoration': 'line-through',
    '&:hover': {
      backgroundColor: '#595959 !important',
    },
    '&.Mui-selected': {
      backgroundColor: '#757575 !important',
      color: '#ffffff !important',
    },
  },
  listItemSuccess: {
    'padding': theme.spacing(2),
    'backgroundColor': theme.palette.background.paper + ' !important',
    '&:hover': {
      backgroundColor: theme.palette.primary.light + ' !important',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light + ' !important',
    },
  },
  listItemFailed: {
    'padding': theme.spacing(2),
    'backgroundColor': theme.palette.failure.subtle + ' !important',
    '&:hover': {
      backgroundColor: theme.palette.failure.hover + ' !important',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.failure.main + ' !important',
    },
  },
  listItemIncomplete: {
    'padding': theme.spacing(2),
    'backgroundColor': theme.palette.orange.subtle + ' !important',
    '&:hover': {
      backgroundColor: theme.palette.orange.hover + ' !important',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.orange.main + ' !important',
    },
  },
  listItemDiseased: {
    'padding': theme.spacing(2),
    'backgroundColor': theme.palette.disease.subtle + ' !important',
    '&:hover': {
      backgroundColor: theme.palette.disease.hover + ' !important',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.disease.main + ' !important',
    },
  },
  smallMarginRight: {
    'marginRight': theme.spacing(2),
  },
  listItemPlantCount: {
    'padding': theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.primary.light + ' !important',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light + ' !important',
    },
  },
}));

const plantCountGradient = {'light': '#eeffba', 'normal': '#a5d721', 'dark': '#5d8700', 'zero': '#f6cdcd'};

function SnapshotListItem({innerRef, item, annotations, showAnnotations, selected, onClick,
  onKeyDown, showPlantCounts, plantConfidence}) {
  const {t} = useTranslation();
  const classes = useStyles();
  const {isAlreadyInCart, isCartVisible, addToCartById, removeFromCart} = useContext(CartContext);

  const hasDiseases = (snapshot) => {
    if (snapshot.data.analysis_data) {
      for (const plant in snapshot.data.analysis_data.plant_record_list) {
        if (snapshot.data.analysis_data.plant_record_list[plant].healthy === 0) {
          return true;
        }
      }
    }
    return false;
  };

  const getAnnotation = (annotations, snapshotId) => {
    for (const annotation of annotations) {
      if (annotation.snapshot_id === snapshotId) {
        return annotation;
      }
    }
    return null;
  };

  const isAnnotatedIcon = (item) => {
    const snapshotId = item['_id'];
    if (!showAnnotations) {
      return '';
    }
    const annotationSnapshotIds = annotations.map((annotation) => annotation.snapshot_id);
    if (annotationSnapshotIds.includes(snapshotId)) {
      const annotation = getAnnotation(annotations, snapshotId);
      if (annotation.annotations.length > 0) {
        return <SavedSearchIcon />;
      }
      return <DoneIcon />;
    }
    return '';
  };


  const getStatusText = (item) => {
    if (item.status === 'failed') {
      return t('failed');
    } else if (item.status === 'created') {
      return t('created');
    } else if (item.status === 'analyzed') {
      return t('analyzed');
    } else if (item.status === 'discarded') {
      return t('discarded');
    } else if (item.status === 'incomplete') {
      return t('incomplete');
    }
    return '';
  };

  const getInCardIcon = (item) => {
    if (isAlreadyInCart(item._id)) {
      return <ConstructionAddedIcon color="black" onClick={() => removeFromCart(item._id)} />;
    }
    return '';
  };

  const getListItemClass = (item) => {
    if (showPlantCounts) {
      return classes.listItemPlantCount;
    }
    if (item.status === 'failed') {
      return classes.listItemFailed;
    } else if (hasDiseases(item)) {
      return classes.listItemDiseased;
    } else if (item.status === 'discarded') {
      return classes.listItemDiscarded;
    } else if (item.status === 'deleted') {
      return classes.listItemDeleted;
    } else if (item.status === 'incomplete') {
      return classes.listItemIncomplete;
    } else {
      return classes.listItemSuccess;
    }
  };

  const showAddToCart = () => {
    return isCartVisible() && !isAlreadyInCart(item._id);
  };

  const calculateListItemColor = (item) => {
    const plantCount = getPlantCount(item?.data?.analysis_data?.plant_record_list, plantConfidence);
    if (plantCount === 0) {
      return plantCountGradient.zero;
    } else if (plantCount < 4) {
      return plantCountGradient.light;
    } else if (plantCount < 8) {
      return plantCountGradient.normal;
    } else {
      return plantCountGradient.dark;
    }
  };

  return (
    <ListItem
      ref={innerRef}
      className={getListItemClass(item)}
      key={item._id}
      onClick={onClick}
      onKeyDown={onKeyDown}
      selected={selected}
      id={item._id}
      style={{backgroundColor: showPlantCounts ? calculateListItemColor(item) : ''}}
      button>
      {isCartVisible() ? getInCardIcon(item) : ''}
      <ListItemText><div>
        {getSnapshotTitle(item, false, false, showPlantCounts, plantConfidence)}
      </div></ListItemText>
      <Typography
      >{isAnnotatedIcon(item)}</Typography>
      {showAddToCart() ?
        <div className={classes.smallMarginRight}>
          <ConstructionAddIcon onClick={() => addToCartById(item._id)} />
        </div> : ''}
      <Typography>{getStatusText(item)}</Typography>
    </ListItem>
  );
}

export default SnapshotListItem;
