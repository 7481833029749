export default class JobInput {
  constructor(client, collectionDate, status, name, priority, inBedPosition = null,
      secondDate = null, customProject=null) {
    this.client = client;
    this.collection_date = collectionDate;
    this.status = status;
    this.name = name;
    this.inBedPosition = inBedPosition;
    this.priority = priority;
    this.secondDate = secondDate;
    this.customProject = customProject;
  }
}
