import React from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import DoneIcon from '@mui/icons-material/Done';
import {styled} from '@mui/material/styles';
import {withTheme} from '@material-ui/core/styles';

const StyledConstructionIcon = withTheme(styled(ConstructionIcon)`
    color: ${(props) => (props.color === 'black' ? 'black' : props.theme.palette.primary.main)};
    cursor: pointer;
`);

const StyledAddIcon = withTheme(styled(AddIcon)`
    color: ${(props) => (props.color === 'black' ? 'black' : props.theme.palette.primary.main)};
    cursor: pointer;
`);

const StyledDoneIcon = withTheme(styled(DoneIcon)`
color: ${(props) => (props.color === 'black' ? 'black' : props.theme.palette.primary.main)};
`);

const StyledRemoveIcon = withTheme(styled(RemoveIcon)`
color: ${(props) => (props.color === 'black' ? 'black' : props.theme.palette.primary.main)};
`);

export const StyledDeleteIcon = withTheme(styled(DeleteIcon)`
    color: ${(props) => (props.color === 'black' ? 'black' : props.theme.palette.primary.main)};
`);

export const ConstructionAddIcon = ({color, onClick}) => (
  <div>
    <StyledConstructionIcon color={color} onClick={onClick} />
    <StyledAddIcon color={color} onClick={onClick} />
  </div>
);

export const ConstructionAddedIcon = ({color, onClick}) => (
  <div>
    <StyledConstructionIcon color={color} onClick={onClick} />
    <StyledDoneIcon color={color} onClick={onClick} />
  </div>
);

export const ConstructionRemoveIcon = ({color, onClick}) => (
  <div>
    <StyledConstructionIcon color={color} onClick={onClick} />
    <StyledRemoveIcon color={color} onClick={onClick} />
  </div>
);
