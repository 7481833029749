import React from 'react';
const AnnotationMapKeyLabels = ({width}) => {
  return (
    <g className='annotation-map-keys'>
      {/* healthy plants */}
      <rect
        x={width - 50}
        y={10}
        width={40}
        height={40}
        rx={5}
        stroke={'black'}
        fill='green'
        className='analyzed-key'
      />
      <text x={width - 30} y={30} textAnchor='middle'
        dominantBaseline='middle' fontSize={22} fontStyle='bold' fontWeight='bold'>H</text>
      <text
        x={width - 85}
        y={30}
        textAnchor="middle"

        dominantBaseline="middle"
        fontSize={14}
        style={{fontWeight: 'bold', zIndex: 1000}}
        className='annotation-key-label'
      >
          Healthy
      </text>
      {/* plants with Trips */}
      <rect
        x={width - 50}
        y={60}
        width={40}
        height={40}
        rx={5}
        stroke={'black'}
        fill='red'
      />
      <text x={width - 30} y={82} textAnchor='middle'
        dominantBaseline='middle' fontSize={22} fontStyle='bold' fontWeight='bold'>T</text>
      <text
        x={width - 75}
        y={80}
        textAnchor="middle"

        dominantBaseline="middle"
        fontSize={14}
        style={{fontWeight: 'bold', zIndex: 1000}}
        className='annotation-key-label'
      >
          Trips
      </text>
      <rect
        x={width - 50}
        y={110}
        width={40}
        height={40}
        rx={5}
        stroke={'black'}
        fill='#5e8cb5'
      />
      <text x={width - 30} y={132} textAnchor='middle'
        dominantBaseline='middle' fontSize={22} fontStyle='bold' fontWeight='bold'>C</text>
      <text
        x={width - 90}
        y={130}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={14}
        style={{fontWeight: 'bold', zIndex: 1000}}
        className='annotation-key-label'
      >
          Caterpillar
      </text>
      <rect
        x={width - 50}
        y={160}
        width={40}
        height={40}
        rx={5}
        stroke={'black'}
        fill='#ffff00'
      />
      <text x={width - 30} y={182} textAnchor='middle'
        dominantBaseline='middle' fontSize={22} fontStyle='bold' fontWeight='bold'>L</text>
      <text
        x={width - 70}
        y={180}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={14}
        style={{fontWeight: 'bold', zIndex: 1000}}
        className='annotation-key-label'
      >
          Lice
      </text>
      <rect
        x={width - 50}
        y={210}
        width={40}
        height={40}
        rx={5}
        stroke={'black'}
        fill='#63d2d6'
      />
      <text x={width - 30} y={232} textAnchor='middle'
        dominantBaseline='middle' fontSize={22} fontStyle='bold' fontWeight='bold'>E</text>
      <text
        x={width - 80}
        y={230}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={14}
        style={{fontWeight: 'bold', zIndex: 1000}}
        className='annotation-key-label'
      >
          Erwinia
      </text>
      <rect
        x={width - 50}
        y={260}
        width={40}
        height={40}
        rx={5}
        stroke={'black'}
        fill='#a9a9a9'
      />
      <text x={width - 30} y={282} textAnchor='middle'
        dominantBaseline='middle' fontSize={22} fontStyle='bold' fontWeight='bold'>D</text>
      <text
        x={width - 90}
        y={280}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={14}
        style={{fontWeight: 'bold', zIndex: 1000}}
        className='annotation-key-label'
      >
          Discarded
      </text>
      <rect
        x={width - 50}
        y={310}
        width={40}
        height={40}
        rx={5}
        stroke={'black'}
        fill='#ffa500'
      />
      <text x={width - 30} y={332} textAnchor='middle'
        dominantBaseline='middle' fontSize={22} fontStyle='bold' fontWeight='bold'>O</text>
      <text
        x={width - 75}
        y={330}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={14}
        style={{fontWeight: 'bold', zIndex: 1000}}
        className='annotation-key-label'
      >
          Other
      </text>
      <rect
        x={width - 50}
        y={360}
        width={40}
        height={40}
        rx={5}
        stroke={'black'}
        fill='#FF6C86'
      />
      <text x={width - 30} y={382} textAnchor='middle'
        dominantBaseline='middle' fontSize={22} fontStyle='bold' fontWeight='bold'>S</text>
      <text
        x={width - 105}
        y={380}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={14}
        style={{fontWeight: 'bold', zIndex: 1000}}
        className='annotation-key-label'
      >
          Split Branches
      </text>
    </g>
  );
};

export default AnnotationMapKeyLabels;
