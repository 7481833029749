import React from 'react';

// Renders a cross above a cell to signify it was discarded
const DiscardedIcon = ({laneIndex, i, x, y, xScale, yScale, yOffset}) => {
  return (
    <g>
      <line
        key={`${laneIndex}-${i}-line1`}
        x1={x}
        y1={y + yOffset}
        x2={x + xScale.bandwidth()}
        y2={y + yOffset + yScale.bandwidth()}
        stroke={'black'}
      />,
      <line
        key={`${laneIndex}-${i}-line2`}
        x1={x + xScale.bandwidth()}
        y1={y + yOffset}
        x2={x}
        y2={y + yOffset + yScale.bandwidth()}
        stroke={'black'}
      />
    </g>
  );
};

export default DiscardedIcon;
