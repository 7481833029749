import React, {useState, useEffect} from 'react';
import {ApiClient} from '../../Utils/ApiClient';
import ReactCalendarHeatmap from 'react-calendar-heatmap';

function UsageOverviewComponent({startDate, endDate, client, hoverMessage}) {
  const [counts, setCounts] = useState([]);
  const [formattedCounts, setFormattedCounts] = useState([]);

  useEffect(() => {
    if (!startDate || !endDate || !client) {
      return;
    }
    getCounts(client);
  }, [startDate, endDate, client]);

  const getCounts = async (client) => {
    const data = await ApiClient.findCounts(client, startDate, endDate, 1);
    setCounts(data);
  };

  useEffect(() => {
    const result = [];
    for (const count of counts) {
      result.push({
        'date': count.day.split('T')[0],
        'count': count.count,
      });
    }
    setFormattedCounts(result);
  }, [counts]);

  const showTooltip = (value) => {
    if (!value || !value?.count || !value?.date) {
      return;
    }
    hoverMessage(`${value.count} snapshots on ${value.date}.`);
  };

  return (
    <ReactCalendarHeatmap
      startDate={startDate}
      endDate={endDate}
      values={formattedCounts}
      onMouseOver={
        ((event, value) => showTooltip(value))
      }
      classForValue={(value) => {
        if (!value || value == 0) {
          return 'color-heatmap-empty';
        }
        return 'color-heatmap-set';
      }}
    />
  );
}
export default UsageOverviewComponent;
