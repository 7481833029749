
import {
  createMRTColumnHelper,
} from 'material-react-table';

const columnHelper = createMRTColumnHelper();


export const annotationColumns = [
  columnHelper.accessor('collection_date', {
    header: 'Collection Date',
    grow: false,
    maxSize: 10,
  }),
  columnHelper.accessor('_id', {
    header: 'Annotation ID',
    grow: false,
    size: 50,
  }),
  columnHelper.accessor('snapshot_id', {
    header: 'Snapshot ID',
    grow: false,
    size: 50,
  }),
  columnHelper.accessor('client', {
    header: 'Client',
    grow: false,
    size: 50,
  }),
  columnHelper.accessor('label', {
    header: 'Label',
    grow: false,
    size: 50,
    filterFn: 'equals',
    filterVariant: 'select',
    filterSelectOptions: ['healthy', 'trips', 'discarded', 'other'],
  }),
  columnHelper.accessor('systemLabel', {
    header: 'System Label',
    grow: false,
    size: 50,
  }),
  columnHelper.accessor('type', {
    header: 'Type of Chrysanthemum',
    grow: false,
    size: 50,
  }),
  columnHelper.accessor('speed', {
    header: 'Driving Speed (m/min)',
    grow: false,
    size: 50,
    filterFn: (row, id, filterValue) => parseFloat(row.getValue(id)) <= parseFloat(filterValue),
  }),
  columnHelper.accessor('isSpraying', {
    header: 'Spraying?',
    grow: false,
    size: 50,
    filterFn: 'equals',
    filterVariant: 'select',
    filterSelectOptions: ['Yes', 'No'],
  }),
  columnHelper.accessor('plantAge', {
    header: 'Plant Age (days)',
    grow: false,
    size: 50,
    filterFn: 'lessThanOrEqualTo',
  }),
  columnHelper.accessor('plantId', {
    header: 'Snapshot Plant ID',
    grow: false,
    size: 50,
  }),
  columnHelper.accessor('budsCount', {
    header: 'Flowers Count',
    grow: false,
    size: 50,
    filterFn: (row, id, filterValue) => row.getValue(id)?.toString() === filterValue,
  }),
  columnHelper.accessor('plantHeight', {
    header: 'Plant Height (cm)',
    grow: false,
    size: 50,
    filterFn: 'lessThanOrEqualTo',
  }),
  columnHelper.accessor('plantWidth', {
    header: 'Plant Width (cm)',
    grow: false,
    size: 50,
    filterFn: 'lessThanOrEqualTo',
  }),
  columnHelper.accessor('plantLength', {
    header: 'Plant Length (cm)',
    grow: false,
    size: 50,
    filterFn: 'lessThanOrEqualTo',
  }),
  columnHelper.accessor('plantLocationX', {
    header: 'Plant Location X',
    grow: false,
    size: 50,
  }),
  columnHelper.accessor('plantLocationY', {
    header: 'Plant Location Y',
    grow: false,
    size: 50,
  }),
  columnHelper.accessor('time_of_day', {
    header: 'Time of Day',
    grow: false,
    maxSize: 10,
  }),
];

export const isPointInBox = (pointCoordinates, boxCoordinates, boxDimensions) => {
  if (!pointCoordinates || !boxCoordinates || !boxDimensions) {
    return false;
  }
  const x = pointCoordinates[0];
  const y = pointCoordinates[1];
  const x1 = boxCoordinates[0] - boxDimensions[0] / 2;
  const y1 = boxCoordinates[1] - boxDimensions[1] / 2;
  const x2 = x1 + boxDimensions[0];
  const y2 = y1 + boxDimensions[1];
  const result = (x >= x1 && x <= x2) && (y >= y1 && y <= y2);
  return result;
};

export const generateConditionalRowStyles = (labels) => {
  const styles = [];
  Object.keys(labels).forEach((key) => {
    const label = labels[key];
    styles.push({
      when: (row) => row.label === label.value,
      style: {
        'backgroundColor': `${label.color}`,
        'color': 'white',
        'position': 'relative',
        '&:hover': {
          'cursor': 'pointer',
        },
      },
    });
  });
  styles.push({
    when: (row) => row.label === 'No label',
    style: {
      'backgroundColor': 'white',
      'color': 'black',
      'position': 'relative',
      '&:hover': {
        'cursor': 'pointer',
      },
    },
  });
  return styles;
};

function convertArrayOfObjectsToCSV(array) {
  let result;

  const columnDelimiter = ',';
  const lineDelimiter = '\n';
  const keys = annotationColumns.map((column) => column.selector.toString().split('.').pop());
  const titles = annotationColumns.map((column) => column.name);
  result = '';
  result += titles.join(columnDelimiter);
  result += lineDelimiter;


  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      result += `"${item[key]}"`;
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

export function downloadCSV(array) {
  console.log('here', array);
  if (!array) return;
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(array);
  if (csv == null) return;

  const filename = 'export.csv';

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}

