import React from 'react';
import randomColor from 'randomcolor';
import './batchStyles.css';
const Batch = ({batch, laneLength, laneWidth, sublaneWidth, sublanes}) => {
  if (!batch) {
    return null;
  };
  const percentageWidthUnit = 100 / laneLength;
  const percentageHeightUnit = 100 / laneWidth;
  const batchLocations = batch.inLaneLocations;
  const batchColor = randomColor();
  const getBatchWidth = (location) => {
    const width = location.bottomRight.y - location.topLeft.y;
    return `${width * percentageWidthUnit}%`;
  };
  const getBatchHeight = (location) => {
    const height = location.bottomRight.x - location.topLeft.x;

    return `${height * percentageHeightUnit}%`;
  };

  const getLeftPosition = (location) => {
    return `${location.topLeft.y * percentageWidthUnit}%`;
  };
  const getTopPosition = (location) => {
    return `${(location.topLeft.x * percentageHeightUnit) +
      (sublaneWidth * sublanes.indexOf(location.sublane)) * percentageHeightUnit}%`;
  };
  return (
    <div className='batch'>
      {
        batchLocations.map((location, index) => {
          return (
            <div key={index} className='tooltip'
              style={{backgroundColor: batchColor, width: getBatchWidth(location),
                height: getBatchHeight(location), position: 'absolute',
                opacity: 0.5, left: getLeftPosition(location), top: getTopPosition(location)}}>
              <span className='tooltiptext'>
                Batch ID: {batch.batchData.batch_id}
                <br/>
                Crop Type: {batch.batchData.crop_id}
                <br/>
                Plant Count: {batch.batchData.batch_information.actual_plant_amount}
                <br/>
                Planting Density: {batch.batchData.batch_information.planting_density}
                <br/>
                Planting Date: {batch.batchData.batch_information.planting_date}
                <br/>
                Harvesting Date: {batch.batchData.batch_information.harvesting_date}
              </span>
            </div>
          );
        })
      }
    </div>
  );
};

export default Batch;
