import React from 'react';
import ConstructionIcon from '@mui/icons-material/Construction';
import {CartContext} from './CartContext';
import {useContext} from 'react';
import {useHistory} from 'react-router-dom/cjs/react-router-dom';

const CartWidget = () => {
  const history = useHistory();
  const {cartItems, isCartVisible} = useContext(CartContext);

  const openCart = () => {
    history.push(`/admin/shopping-cart`);
  };

  return (
        isCartVisible() ?
            <button onClick={openCart}>
              <span >{cartItems.length}</span>
              <ConstructionIcon />
            </button> : ''
  );
};

export default CartWidget;
