import {useTheme} from '@material-ui/core';
import React from 'react';

function Crosshair({text}) {
  const theme = useTheme();

  return (
    <div>
      <div style={{position: 'absolute', top: '50%', left: '0%',
        width: '100%', height: '3px', backgroundColor: theme.palette.purple.main}}></div>
      <div style={{position: 'absolute', top: '0%', left: '50%',
        width: '3px', height: '100%', backgroundColor: theme.palette.purple.main}}></div>
      <div style={{position: 'absolute', top: '51%', left: '51%',
        color: theme.palette.purple.main, fontWeight: 'bold', fontSize: 25}}>
        {text}
      </div>
    </div>
  );
}

export default Crosshair;
