import React, {createContext, useEffect, useState} from 'react';
import clientConfig from '../Config/clients.json';
import {ApiClient} from '../Utils/ApiClient';


const UserContext = createContext();

const UserProvider = ({user, children}) => {
  const [currentClient, setCurrentClient] = useState(null);
  const [hasClientPicker, setHasClientPicker] = useState(false);
  const [isADIUser, setIsADIUser] = useState(false);

  useEffect(() => {
    setUserClients();
    checkIsADIUser();
  }, [user]);

  useEffect(() => {
    const queryParams = getQueryParams();
    const client = queryParams.get('client');
    const snapshotId = queryParams.get('snapshotId');
    if (snapshotId) {
      loadClientFromSnapshot(snapshotId);
      return;
    }
    if (client && client !=='null' && client !== currentClient) {
      setCurrentClient(client);
      return;
    }
  }, [window.location.href]);

  const loadClientFromSnapshot = (snapshotId) => {
    ApiClient.findSnapshotById(snapshotId).then((snapshot) => {
      if (snapshot === null) {
        return;
      }
      setCurrentClient(snapshot.client);
    });
  };

  const getQueryParams = () => {
    const url = new URL(window.location.href);
    return new URLSearchParams(url.search);
  };

  const setUserClients = () => {
    if (isADIUser) {
      if (!user?.clients || user.clients.length === 0) {
        user.clients = Object.values(clientConfig.names);
      }
    }
    if (!currentClient && user.clients.length === 1) {
      setCurrentClient(user.clients[0]);
    }
    const config = getUserConfig(false, user.clients[0]);
    if (user.clients.length > 1 || (user.level === 'team' || user.level === 'admin' || config.annotation_clients)) {
      setHasClientPicker(true);
    }
  };

  const checkIsADIUser = () => {
    setIsADIUser(user.level === 'team' || user.level === 'admin');
  };

  const getUserConfig = (forceCurrentClient = false, specificClient = null) => {
    if (specificClient) {
      return require(`../ClientConfigs/${specificClient}.json`);
    }
    if (!forceCurrentClient && isADIUser) {
      return require('../ClientConfigs/Admin.json');
    }
    if (!currentClient) {
      return null;
    }
    return require(`../ClientConfigs/${currentClient}.json`);
  };

  return (
    <UserContext.Provider value={{user, setCurrentClient, currentClient, getUserConfig, hasClientPicker, isADIUser}}>
      {children}
    </UserContext.Provider>
  );
};

export {UserContext, UserProvider};


