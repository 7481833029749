import React from 'react';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  legendStyle: {
    width: '80%',
    height: '20px',
    marginBottom: '10px',
    marginTop: '20px',
  },
  labelContainerStyle: {
    display: 'flex',
    justifyContent: 'center',
    width: '120%',
    left: 50,
  },
  labelStyle: {
    textAlign: 'center',
    color: 'black',
    width: '100%',
  },
}));

/**
 * returns the legend for the heatmap according to the given parameters
 * @param {number} maxValue - the maximum value of the heatmap
 * @param {string} color - the color of the heatmap
 * @return {JSX.Element}
 */
const HeatmapLegend = ({maxValue, color}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.legendStyle}
        style={{background: `linear-gradient(to right, white, ${color} 50%, ${color})`}}></div>
      <div className={classes.labelContainerStyle}>
        <div className={classes.labelStyle}>0</div>
        <div className={classes.labelStyle}>{Math.round(maxValue / 2)}</div>
        <div className={classes.labelStyle}>&#62;{maxValue}</div>
      </div>
    </div>
  );
};

export default HeatmapLegend;
