import React, {createContext, useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {ApiClient} from '../Utils/ApiClient';
export const CredentialsContext = createContext();

export const CredentialsProvider = ({children}) => {
  const history = useHistory();
  const [baseUrl, setBaseUrl] = useState(null);
  const [credentials, setCredentials] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);
  useEffect(() => {
    setBaseUrl(getBaseUrl());
  }, []);

  useEffect(() => {
    if (baseUrl === null) {
      return;
    }
    ApiClient.initialize(baseUrl);
    loadUserFromStorage();
  }, [baseUrl]);

  useEffect(() => {
    if (loggedInUser && location.pathname === '/login') {
      if (loggedInUser.clients.length === 1) {
        history.push(`/${loggedInUser.clients[0]}`);
      } else {
        history.push(`/admin`);
      }
    }
  }, [loggedInUser]);

  const logout = () => {
    setCredentials(null);
    ApiClient.setCredentials('');
    localStorage.removeItem('loginToken');
    setLoggedInUser(null);
    history.push('/login');
  };

  const login = async (name, password) => {
    await ApiClient.loginWithUsernameAndPassword(name, password).then((result) => {
      const credentials = 'Bearer ' + result?.token;
      const user = result?.data;
      if (!user) {
        logout();
        return false;
      }
      setLoggedInUser(user);
      saveCredentials(credentials);
      return true;
    });
  };

  const loadUserFromStorage = () => {
    const token = localStorage.getItem('loginToken');
    ApiClient.loginWithToken(token, false).then((result) => {
      if (result?.error === 'Unauthorized' || result === undefined) {
        logout();
      } else {
        setLoggedInUser(result.data);
        saveCredentials(token);
      }
    });
  };

  const ping = async () => {
    const rootUrl = baseUrl.substring(0, baseUrl.length - 4);
    await ApiClient.pingRoot(rootUrl).then((result) => {
      if (result) {
        return;
      }
      throw new Error('Cannot reach server, unknown reason.');
    });
  };

  const saveCredentials = (credentials) => {
    ApiClient.setCredentials(credentials);
    setCredentials(credentials);
    localStorage.setItem('loginToken', credentials);
  };

  const setAndStoreBaseUrl = (url) => {
    ApiClient.setBaseUrl(url);
    localStorage.setItem('baseUrl', url);
    setBaseUrl(url);
  };

  const getBaseUrl = () => {
    const url = localStorage.getItem('baseUrl');
    if (url != null) {
      return url;
    }
    return 'https://server.adinnovations.nl/api';
  };

  return (
    <CredentialsContext.Provider
      value={{
        baseUrl,
        setAndStoreBaseUrl,
        credentials,
        saveCredentials,
        login,
        logout,
        ping,
        loggedInUser,
      }}
    >
      {children}
    </CredentialsContext.Provider>
  );
};
