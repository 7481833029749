export function rgb2hsv(r, g, b) {
  // Normalize RGB values
  const R = r / 255;
  const G = g / 255;
  const B = b / 255;

  // Find maximum and minimum of R, G, B
  const maxColor = Math.max(R, G, B);
  const minColor = Math.min(R, G, B);

  let hue; let saturation; const value = maxColor;

  // Calculate the saturation
  if (maxColor !== 0) {
    saturation = (maxColor - minColor) / maxColor;
  } else {
    saturation = 0;
  }

  // Calculate the hue
  if (maxColor === minColor) {
    hue = 0; // undefined, achromatic
  } else {
    const delta = maxColor - minColor;
    if (maxColor === R) {
      hue = (G - B) / delta + (G < B ? 6 : 0);
    } else if (maxColor === G) {
      hue = (B - R) / delta + 2;
    } else {
      hue = (R - G) / delta + 4;
    }
    hue /= 6;
  }

  return [Math.round(hue * 360), Math.round(saturation * 100), Math.round(value * 100)];
}


export function getAverageColor(rgbColors) {
  const sumColor = rgbColors.reduce((acc, color) => {
    acc[0] += color[0];
    acc[1] += color[1];
    acc[2] += color[2];
    return acc;
  }, [0, 0, 0]);
  const averageColor = sumColor.map((channelSum) => Math.round(channelSum / rgbColors.length));
  return averageColor;
}
