import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useTranslation} from 'react-i18next';
import {Button} from '@material-ui/core';

export default function YesOrNoDialog({title, message, showDialog, handleDialogAnswer}) {
  const [open, setOpen] = React.useState(false);
  const {t} = useTranslation();

  React.useEffect(() => {
    setOpen(showDialog);
  }, [showDialog]);

  const handleClose = (answer) => {
    setOpen(false);
    handleDialogAnswer(answer);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>{t('No')}</Button>
          <Button onClick={() => handleClose(true)} autoFocus>
            {t('Yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
