/* eslint-disable max-len */
import React, {useContext} from 'react';
import './overview-map.css';
import PlantStats from './icons/plant-stats.js';
import {OverviewMapContext} from '../../contexts/OverviewMapContext';
import ImageCropper from './ImageCropper';

// Component to render the plant card with the plant image and stats
const PlantCard = ({plant}) => {
  if (!plant) {
    return null;
  }
  const {currentSnapshotImage, annotationLabels, mapType} = useContext(OverviewMapContext);
  const labels = Object.keys(annotationLabels);
  const colors = {};
  labels.forEach((label) => {
    colors[annotationLabels[label].value] = annotationLabels[label].color;
  });
  const plantLabel = plant.disease_classifications[0]?.label || 'healthy';
  const color = mapType === 'annotations' ? colors[plantLabel] : '#0e2f44';
  const plantStats = {
    height: (plant.height * 100).toFixed(2) || 0,
    width: plant.dimensions?.[0] ? (plant.dimensions[0] * 100).toFixed(2) : 0,
    length: plant.dimensions?.[1] ? (plant.dimensions[1] * 100).toFixed(2) : 0,
    buds: plant.buds.length || 0,
  };
  return (
    <div className='plant-card'>
      <div className='plant-card-title-container' style={{backgroundColor: color}}>
        <p className='plant-card-title'>Plant {plant.plant_id}</p>
      </div>
      <div className='plant-image-and-stats-container'>
        <PlantStats style={{width: '50%',
          height: '100%', overflow: 'hidden', paddingLeft: 3}} plantStats={plantStats}/>
        <div className='plant-card-image'>
          {plant && currentSnapshotImage ? (<ImageCropper imageUrl={currentSnapshotImage} plant={plant}/>) : (
          <div className='plant-image-loader'>
          </div>
        )}
        </div>

      </div>
    </div>
  );
};

export default PlantCard;
