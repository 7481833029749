import React, {useState, useEffect} from 'react';
import {InputLabel, Select, MenuItem} from '@material-ui/core';
import Batch from './Batch';
import './batchStyles.css';
import Checkbox from '@mui/material/Checkbox';
import {ApiClient} from '../../Utils/ApiClient';
import Calibrations from './Calibrations';
import Tooltip from '@mui/material/Tooltip';
import {inverseDistanceWeighting, convertLaneCalibrationsToPoints} from '../../Utils/BatchUtils';

function LanesWithBatches({lanesWithData, greenhouseStats, client}) {
  const [selectedLane, setSelectedLane] = useState(null);
  const [showGroundCalibrations, setShowGroundCalibrations] = useState(false);
  const [groundCalibrations, setGroundCalibrations] = useState(null);
  const [calibrationPoints, setCalibrationPoints] = useState({}); // [x, y, distance_to_pipe]
  const [interpolatedGroundToPipeDistance, setInterpolatedGroundToPipeDistance] = useState(0);
  const [hideCalibrationTooltip, setHideCalibrationTooltip] = useState(false);
  const laneLength = greenhouseStats.interhookDistance * greenhouseStats.hooks;
  const sublaneWidth = greenhouseStats.sublaneWidth;
  const laneWidth = sublaneWidth * greenhouseStats.sublanes.length;
  const laneContainerStyle = {backgroundColor: 'lightgrey', width: '100%',
    flex: 1, display: 'flex', flexDirection: 'column',
    justifyContent: 'center', alignContent: 'center', alignItems: 'center',
    position: 'relative'};
  const hookCount = greenhouseStats.hooks;
  const sublanes = greenhouseStats.sublanes;

  useEffect(() => {
    if (showGroundCalibrations && !groundCalibrations) {
      getGroundCalibrations(selectedLane);
    }
  }, [showGroundCalibrations]);

  useEffect(() => {
    if (selectedLane) {
      setShowGroundCalibrations(false);
      setGroundCalibrations(null);
    }
  }, [selectedLane]);

  const getBatches = (lane) => {
    const batches = lanesWithData[lane];
    // filter out the batches with harvesting_date < today
    const today = new Date();
    const currentBatches = batches.filter((batch) => {
      const harvestingDate = new Date(batch.batchData.batch_information.harvesting_date);
      const plantingDate = new Date(batch.batchData.batch_information.planting_date);
      return harvestingDate >= today && plantingDate <= today;
    });
    // filter out batches with duplicate _id
    const uniqueBatches = currentBatches.filter((batch, index, self) =>
      index === self.findIndex((t) => (
        t.batchData._id === batch.batchData._id
      )),
    );
    return (
      <>
        {uniqueBatches.map((batch, index) =>
          <Batch key={index} batch={batch} laneLength={laneLength} laneWidth={laneWidth}
            sublaneWidth={sublaneWidth} sublanes={greenhouseStats.sublanes}/>,
        )}
      </>
    );
  };

  const getGroundCalibrations = async (lane) => {
    const groundCalibrations = {};
    await Promise.all(sublanes.map(async (sublane) => {
      const response = await ApiClient.findGroundCalibrations(client, lane, sublane);
      if (!response) {
        return;
      }
      groundCalibrations[sublane] = response;
    }));
    setGroundCalibrations(groundCalibrations);
    setCalibrationPoints(convertLaneCalibrationsToPoints(groundCalibrations));
  };

  const showMouseOverCoordinates = (event) => {
    const sublane = event.target.textContent.toLowerCase();
    if (Object.keys(calibrationPoints).length === 0 || !calibrationPoints[sublane]) {
      setInterpolatedGroundToPipeDistance(0);
      return;
    }
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const physicalX = x * laneLength / rect.width;
    const physicalY = y * sublaneWidth / rect.height;
    const interpolatedDistance = inverseDistanceWeighting(physicalX, physicalY, calibrationPoints[sublane]);
    setInterpolatedGroundToPipeDistance(interpolatedDistance);
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100%',
      width: '100%', justifyContent: 'center',
      alignItems: 'center', alignContent: 'center'}}>
      <div style={{width: '100%', marginBottom: '10px'}}
      >
        <InputLabel id="label-select" style={{marginBottom: '10px'}}>Select Lane</InputLabel>
        <Select
          value={selectedLane}
          label="select-lane"
          onChange={(event) => setSelectedLane(event.target.value)}
          variant='outlined'
          style={{width: '10%'}}
        >
          {Object.keys(lanesWithData).map((lane, index) =>
            <MenuItem key={index} value={lane}>
              {lane}
            </MenuItem>,
          )}
        </Select>
        {
          selectedLane &&
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'left',
          alignItems: 'right', marginTop: 10, marginLeft: -10}}>
          <Checkbox
            checked={showGroundCalibrations}
            inputProps={{'aria-label': 'primary checkbox'}}
            onChange={() => setShowGroundCalibrations(!showGroundCalibrations)}
          />
          <p style={{fontWeight: 'bold'}}>Show Ground Calibrations</p>
        </div>
        }
      </div>

      <div style={{backgroundColor: 'red', width: '100%', height: 50, display: 'flex',
        flexDirection: 'row'}} className='hook-numbers'>
        {Array.from(Array(hookCount).keys()).map((hook, index) =>
          <div key={index} style={{width: laneLength, height: '100%', backgroundColor: 'darkgrey',
            border: '1px solid black'}}>
            <p style={{fontWeight: 'bold', marginLeft: 5, textAlign: 'center'}}>{hook + 1}</p>
          </div>,
        )}
      </div>
      <div style={laneContainerStyle}>
        {selectedLane ?
        <>
          {showGroundCalibrations && groundCalibrations &&
          Object.keys(groundCalibrations).map((sublane, index) =>
            <Calibrations key={index} calibrations={groundCalibrations[sublane]}
              sublane={sublane} sublanes={sublanes} sublaneWidth={sublaneWidth} laneLength={laneLength}
              laneWidth={laneWidth} setHideCalibrationTooltip={setHideCalibrationTooltip}/>,
          )}
          {!showGroundCalibrations && getBatches(selectedLane)}
          <Tooltip title={interpolatedGroundToPipeDistance !== 0 ?
          `Interpolated Ground to Pipe: ${interpolatedGroundToPipeDistance}m` : 'No Calibration Data'}
          placement='top' followCursor open={showGroundCalibrations && !hideCalibrationTooltip}>
            <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}
              onMouseMove={showGroundCalibrations && showMouseOverCoordinates}>
              {greenhouseStats.sublanes?.map((sublane, index) =>
                <div key={index} style={{width: '100%', height: '100%',
                  backgroundColor: 'lightgrey', border: '1px solid black'}}>
                  <p style={{fontWeight: 'bold', marginLeft: 5}}>{sublane.toUpperCase()}</p>
                </div>,
              )}
            </div>
          </Tooltip>
        </> :
        <p>Select a Lane to Show Batch Information</p>
        }
      </div>
    </div>
  );
}


export default LanesWithBatches;
