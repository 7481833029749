import React, {useContext, useState, useEffect} from 'react';
import {UserContext} from '../../contexts/userContext';
import {Card, CardContent, InputLabel, Select, MenuItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import StatusLog from './StatusLog';
import {ApiClient} from '../../Utils/ApiClient';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  datePickerCard: {
    marginBottom: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(1),
  },
}));

const StatusLogsPage = () => {
  const {currentClient} = useContext(UserContext);
  const classes = useStyles();
  const [statusLogs, setStatusLogs] = useState([]);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');
  const {t} = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (!currentClient) {
      return;
    }
    getStatusLogs();
  }, []);

  useEffect(() => {
    history.push(`/${currentClient || 'admin'}/status-logs`);
    setStatusLogs([]);
    setDevices([]);
    setSelectedDevice('');
    if (!currentClient) {
      return;
    }
    getStatusLogs();
  }, [currentClient]);

  useEffect(() => {
    if (!currentClient || !statusLogs) {
      return;
    }
    setDevices(getDevices());
  }, [statusLogs]);

  const getStatusLogs = async () => {
    const response = await ApiClient.findStatusLogs();
    if (!response) {
      return;
    }
    const logs = response.filter((log) => log.client === currentClient);
    setStatusLogs(logs);
  };

  const getDevices = () => {
    const clientDevices = [];
    statusLogs.forEach((statusLog) => {
      if (statusLog.client === currentClient) {
        clientDevices.push(statusLog.device);
      }
    });
    return clientDevices;
  };

  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
  };

  return (
    <div className={classes.container}>
      {currentClient ? (
        <div>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h5" component="h2">
                <b>System Status Logs for {currentClient}</b>
              </Typography>
            </CardContent>
          </Card>
          {
            Object.keys(statusLogs).length > 0 ?
            (
              <div>
                { devices.length > 0 &&
                  <Card className={classes.card}>
                    <CardContent>
                      <InputLabel id="label-select" className={classes.label}>{t('Select Device')}</InputLabel>
                      <Select
                        value={selectedDevice}
                        label="filter-by-label"
                        onChange={handleDeviceChange}
                        style={{width: '100%'}}
                      >
                        {devices.map((device, index) =>
                          <MenuItem value={device} key={index}>{device}</MenuItem>,
                        )}
                      </Select>
                    </CardContent>
                  </Card>
                }
                {selectedDevice && <StatusLog
                  statusLog={statusLogs.filter((log) => log.device === selectedDevice)[0]}/>}
              </div>
            ) : (
              <Card className={classes.card}>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    No Status Logs Found..
                  </Typography>
                </CardContent>
              </Card>
            )
          }
        </div>
      ) : (
            <Typography variant="h5" component="h2">
            No Client Selected..
            </Typography>
      )}
    </div>
  );
};

export default StatusLogsPage;
