import React, {useContext, useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import {ApiClient} from '../../Utils/ApiClient';
import {Box, Button, CardContent, FormControl,
  Grid, InputLabel, Select, useTheme, Slider} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {RegularSpacer} from '../BasicComponents/Spacers';
import {UserContext} from '../../contexts/userContext';
import {useHotkeys} from 'react-hotkeys-hook';
import {CenterWrapper} from '../StyledComponents/BasicStyles';
import RefreshIcon from '@mui/icons-material/Refresh';
import {useTranslation} from 'react-i18next';
import {getDatePlusInterval} from '../../Utils/DateUtils';
import {downloadFile, zipBlobs} from '../../Utils/SnapshotHelper';
import {dateToStringWithoutTime} from '../../Utils/DateUtils';
import {LinearProgress, ToggleButton, ToggleButtonGroup} from '@mui/material';
import {DateRangePickerComponent} from '../../Utils/DatePickers';
import SnapshotInfoCard from './SnapshotInfoCard';
import {useHistory} from 'react-router-dom';
import ClientPicker from '../UserInput/ClientPicker';
import {Link} from 'react-router-dom';
import SimpleFilter from '../Images/SimpleFilter';

const useStyles = makeStyles((theme) => ({
  selectedAnnotation: {
    'padding': theme.spacing(2),
    'backgroundColor': theme.palette.primary.main + ' !important',
  },
  normalAnnotation: {
    'padding': theme.spacing(2),
  },
  onlyMarginBottom: {
    marginBottom: 10,
  },
}));

export const getColorOfLabel = (label, userConfig) => {
  const labelsConfig = userConfig.annotation_labels;
  for (const labelName in labelsConfig) {
    if (labelsConfig[labelName].value === label) {
      return labelsConfig[labelName].color;
    }
  }
  return 'black';
};

export const getLabels = (userConfig) => {
  const labelDict = userConfig?.annotation_labels ?? {};
  return Object.values(labelDict);
};

function AnnotationPage() {
  const [allAnnotations, setAllAnnotations] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [showAnnotated, setShowAnnotated] = useState(false);
  const [reloadAnnotations, setReloadAnnotations] = useState(0);
  const [annotationImages, setAnnotationImages] = useState({});
  const [currentAnnotation, setCurrentAnnotation] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const {getUserConfig, currentClient, user, isADIUser} = useContext(UserContext);
  const [startDate, setStartDate] = useState(() => getDatePlusInterval(-31).toISOString());
  const [endDate, setEndDate] = useState(() => getDatePlusInterval(0, true).toISOString());
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageSize, setImageSize] = useState(325);

  const [filterLabel, setFilterLabel] = useState('');
  const [snapshotFilter, setSnapshotFilter] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const history = useHistory();

  const theme = useTheme();
  const classes = useStyles();
  const {t} = useTranslation();

  // TODO this is hardcoded for now, but should be dynamic based on the config.
  useHotkeys('a', () => handleClick('trips'));
  useHotkeys('s', () => handleClick('discarded'));
  useHotkeys('d', () => handleClick('healthy'));
  useHotkeys('f', () => handleClick('other'));
  useHotkeys('g', () => handleClick('uncertain'));
  useHotkeys('z', () => handleClick('caterpillar'));
  useHotkeys('x', () => handleClick('split_branches'));
  useHotkeys('c', () => handleClick('lice'));
  useHotkeys('v', () => handleClick('erwinia'));
  useHotkeys('b', () => handleClick('leaf_miner'));
  useHotkeys('k', () => handleClick(''));
  useHotkeys('delete', () => deleteAnnotation());
  useHotkeys('right', () => setCurrentIndex(currentIndex + 1));
  useHotkeys('left', () => setCurrentIndex(currentIndex - 1));

  useEffect(() => {
    history.push((`/${currentClient || 'admin'}/annotations`));
    if (currentClient && getUserConfig(true).annotation_clients) {
      setSelectedClient(getUserConfig(true).annotation_clients[0]);
    }
  }, [currentClient]);
  useEffect(() => {
    fetchAnnotations();
  }, [showAnnotated, reloadAnnotations, currentClient, startDate, endDate, selectedClient]);

  useEffect(() => {
    if (!annotations) {
      return;
    };
    if (currentIndex < -1) {
      setCurrentIndex(-1);
      return;
    }
    if (currentIndex >= annotations.length) {
      setCurrentIndex(annotations.length - 1);
      return;
    }
    if (currentIndex < annotations.length) {
      setCurrentAnnotation(annotations[currentIndex]);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (!currentAnnotation) {
      return;
    }
    const currentAnnotationIndex = annotations.findIndex((annotation) => {
      return annotation._id === currentAnnotation._id;
    });
    if (currentAnnotationIndex !== -1) {
      setCurrentAnnotation(annotations[currentAnnotationIndex]);
    }
  }, [annotations]);

  useEffect(() => {
    if (!currentAnnotation) {
      return;
    }
    loadImageUrl(currentAnnotation);
  }, [currentAnnotation]);

  useEffect(() => {
    const filtered = getFilteredAnnotations();
    setAnnotations(filtered);
  }, [allAnnotations, filterLabel, snapshotFilter]);

  const deleteAnnotation = async () => {
    if (!['admin', 'team'].includes(user?.level)) {
      return;
    }
    ApiClient.removePlantAnnotation(currentAnnotation);
  };

  const fetchAnnotations = () => {
    if (!startDate || !endDate) {
      return;
    }
    const query = {
      'createdAt': {'$gte': startDate, '$lte': endDate},
    };
    ApiClient.findAnnotations(selectedClient || currentClient, query).then((result) => {
      setAllAnnotations(result);
    });
  };

  const loadImageUrl = (annotation) => {
    ApiClient.getImageUrlAnnotation(annotation).then((imageUrl) => {
      setAnnotationImages((prevAnnotationImages) => ({
        ...prevAnnotationImages,
        [annotation._id]: imageUrl,
      }));
    });
  };

  const getImage = (annotation) => {
    if (!annotationImages.hasOwnProperty(annotation._id)) {
      return <h3>Loading...</h3>;
    }
    return <img src={annotationImages[annotation._id]} alt={'Picture to annotate'} height={imageSize} />;
  };

  const handleSelectAnnotation = (annotation, trackIndex) => {
    if (trackIndex) {
      setCurrentIndex(annotations.findIndex((a) => a._id === annotation._id));
    }
    setCurrentAnnotation(annotation);
  };

  const toggleShowAnnotated = () => {
    setCurrentIndex(-1);
    setAnnotations([]);
    setShowAnnotated((prevIsAnnotated) => !prevIsAnnotated);
  };

  const getAnnotationClass = (annotation, specialAnnotation) => {
    if (currentAnnotation && annotation._id === specialAnnotation._id) {
      return classes.selectedAnnotation;
    } else {
      return classes.normalAnnotation;
    }
  };

  const isAnnotated = (annotation) => {
    return annotation.hasOwnProperty('label') && annotation.label !== '';
  };

  const getFilteredAnnotations = () => {
    if (!allAnnotations?.length > 0) {
      return [];
    }
    let result = allAnnotations.filter((item) => showAnnotated === isAnnotated(item));
    if (filterLabel !== '') {
      result = result.filter((item) => item.label === filterLabel);
    }
    if (snapshotFilter !== '') {
      result = result.filter((item) => item.snapshot_id === snapshotFilter);
    }
    return result;
  };

  const getAnnotationCards = (targetAnnotations, trackIndex) => {
    if (!targetAnnotations?.length > 0) {
      return <Card style={{'padding': theme.spacing(2)}}>No annotations...</Card>;
    };
    return targetAnnotations.map((annotation) =>
      <Card key={annotation._id}
        onClick={() => handleSelectAnnotation(annotation, trackIndex)}
        className={getAnnotationClass(annotation, currentAnnotation) + ' clickable-element'}>
        {dateToStringWithoutTime(annotation.createdAt)} {annotation._id}
      </Card>);
  };

  const handleClientChange = (client) => {
    setSelectedClient(client);
  };

  const getAnnotationClientPicker = () => {
    const annotationClients = getUserConfig(true).annotation_clients;
    if (!annotationClients) {
      return;
    }

    return <ClientPicker clients={annotationClients}
      defaultClient={annotationClients[0]}
      label="Select Annotation Client" onClientChanged={handleClientChange} hideCard={true}/>;
  };

  const setLabel = (annotation, label)=> {
    ApiClient.setAnnotationLabel(annotation, label);
    setAnnotations((prevAnnotations) => prevAnnotations.map((annotation) => {
      if (annotation._id === currentAnnotation._id) {
        return {...annotation, label: label};
      }
      return annotation;
    }));
    setCurrentIndex(currentIndex + 1);
  };

  const handleClick = (value) => {
    setLabel(currentAnnotation, value);
  };

  const getLabelPanel = () => {
    const labelsConfig = getUserConfig(true).annotation_labels;
    if (!labelsConfig) {
      return;
    }

    const result = [];
    Object.keys(labelsConfig).forEach((labelName) => {
      const labelConfig = labelsConfig[labelName];
      result.push(
          <div key={labelName}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Button size="small" accessKey={labelConfig.shortcut.toLowerCase()}
                style={{backgroundColor: labelConfig.color}}
                onClick={() => handleClick(labelConfig.value)} variant="outlined">
                {labelName} ({labelConfig.shortcut})
              </Button>
              <p style={{marginLeft: theme.spacing(1)}}>{labelConfig.message}</p>
            </div>
            <RegularSpacer/>
          </div>);
    });
    return result;
  };

  const handleChangeDateStart = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(0, 0, 0, 0);
    setStartDate(dateObject.toISOString());
  };

  const handleChangeDateEnd = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(23, 59, 59, 999);
    setEndDate(dateObject.toISOString());
  };

  const downloadAnnotations = async () => {
    setLoading(true);
    const results = [];
    const progressionStep = 90 / annotations.length;
    setProgress(0);
    for (const annotation of annotations) {
      const url = await ApiClient.downloadImage(annotation.client, annotation.image_location);
      const fileName = annotation.image_location.split('.')[0].replace('/', '_');
      const fileNameWithLabel = annotation.label + '/' + fileName + '.jpg';
      try {
        const blob = await downloadFile(url);
        if (blob) {
          const file = {'name': fileNameWithLabel, 'blob': blob};
          results.push(file);
        }
      } catch (e) {
        console.log(e);
      }
      setProgress((prevProgress) => prevProgress + progressionStep);
    }
    const zip = await zipBlobs(results);
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(zip);
    downloadLink.download = 'annotations.zip';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    setTimeout(() => {
      URL.revokeObjectURL(downloadLink.href);
      document.body.removeChild(downloadLink);
    }, 100);
    setLoading(false);
  };

  return (
    currentClient ?
    <CenterWrapper width={1200} >
      <Grid container spacing={5}>
        <Grid item xs={8}>
          <Card>
            <CardContent>
              {
                currentAnnotation ?
                <div>
                  {currentAnnotation.label ?
                      <h2 style={{'color': getColorOfLabel(currentAnnotation.label, getUserConfig(true)),
                        'textAlign': 'center'}}>
                        {currentAnnotation.label}
                      </h2> :
                      <h2 style={{'textAlign': 'center'}}>No label</h2>}
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {getImage(currentAnnotation)}
                  </div>
                  <RegularSpacer />
                  <div>
                    <span>{t('Image Size')}:</span>
                    <Slider aria-label="Size" min={100} max={650} step={10} style={{width: '95%'}}
                      value={imageSize}
                      onChangeCommitted={(_, newValue) => setImageSize(newValue)}
                      valueLabelDisplay="auto" />
                  </div>
                  {getLabelPanel()}
                </div>:
                'Nothing selected'}
            </CardContent>
          </Card>
          <SnapshotInfoCard snapshotId={currentAnnotation?.snapshot_id}
            selectedClient={selectedClient} annotation={currentAnnotation}/>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardContent>
              <h2>Filters</h2>
              {getAnnotationClientPicker()}
              <FormControl>
                <InputLabel htmlFor="client-native-simple">{t('Image Type')}</InputLabel>
                <Select
                  native
                  value={filterLabel}
                  onChange={(event) => setFilterLabel(event.target.value)}
                >
                  <option aria-label="None" value="" />
                  {getLabels(getUserConfig(true)).map((item) => (
                    <option key={item.value + '_label_name_option'} value={item.value.toLowerCase()}>
                      {item.message}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {
                isADIUser ?
                <FormControl>
                  <SimpleFilter
                    triggerMethod={(v) => setSnapshotFilter(v)}
                    noCard={true} label = {'snapshot_id'} />
                </FormControl> : ''
              }
              <RegularSpacer size={5} />
              <FormControl fullWidth className={classes.onlyMarginBottom}>
                <DateRangePickerComponent
                  id="dateFrom"
                  defaultStartDate={startDate}
                  defaultEndDate={endDate}
                  changeStartDate={(event) => handleChangeDateStart(event)}
                  changeEndDate={(event) => handleChangeDateEnd(event)}
                />
              </FormControl>
            </CardContent>
          </Card>
          <RegularSpacer size={2} />
          <ToggleButtonGroup value={showAnnotated} exclusive onChange={toggleShowAnnotated}>
            <ToggleButton value={false} color='primary'>
              To annotate
            </ToggleButton>
            <ToggleButton value={true} color='primary'>
              Already annotated
            </ToggleButton>
          </ToggleButtonGroup>
          <Button><RefreshIcon onClick={() =>setReloadAnnotations(reloadAnnotations+1)}/></Button>
          <div style={{maxHeight: '600px', overflowY: 'scroll'}}>
            {getAnnotationCards(annotations, true)}
          </div>
          <RegularSpacer size={2} />
          {loading ? <LinearProgress variant="determinate" value={progress} /> : ''}
          <RegularSpacer size={1} />
          <Box style={{m: 1, position: 'relative', display: 'flex', gap: 10}}>
            <Button variant="contained" onClick={downloadAnnotations} color='primary' disabled={loading}>
              Download Images
            </Button>
            <Link to={{pathname: `/${currentClient || 'admin'}/export-annotations`,
              state: {annotations: annotations, startDate: startDate, endDate: endDate}}}>
              <Button variant='contained' color='primary'>Export Details</Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </CenterWrapper> : ''
  );
}

export default AnnotationPage;
