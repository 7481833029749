import React from 'react';
import {Card, CardContent, Button, LinearProgress} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import JSZip from 'jszip';
import {APIClientError, ApiClient} from '../../Utils/ApiClient';
import {RegularSpacer} from '../BasicComponents/Spacers';

const UploadSnapshotPage = () => {
  const {t} = useTranslation();
  const [progress, setProgress] = React.useState(0);
  const [logs, setLogs] = React.useState([]);

  const unpackZipFile = async (file) => {
    const zip = new JSZip();
    const contents = await zip.loadAsync(file);
    return contents.files;
  };

  const findImageInFiles = (imageName, snapshotId, allFileNames) => {
    let images = allFileNames.filter((fileName) => fileName.endsWith(imageName));
    images = images.filter((fileName) => fileName.startsWith(snapshotId));
    if (images.length !== 1) {
      return null;
    }
    return images[0];
  };

  const uploadSnapshots= async (files) => {
    const allFileNames = Object.keys(files);
    let snapshotIds = allFileNames.filter((fileName) => fileName.endsWith('snapshot.json'));
    snapshotIds = snapshotIds.map((fileName) => fileName.split('/')[0]);
    for (const snapshotId of snapshotIds) {
      const snapshotStr = await files[snapshotId + '/snapshot.json'].async('string');
      const snapshot = JSON.parse(snapshotStr);
      const images = snapshot?.data?.images;
      for (const imageUrl of Object.values(images)) {
        if (!imageUrl) {
          continue;
        }
        const fileName = findImageInFiles(imageUrl.split('/')[1], snapshotId, allFileNames);
        if (!fileName) {
          continue;
        }
        const imageFile = await files[fileName].async('blob');
        const imageResult = await ApiClient.uploadImage(snapshot.client, imageUrl, imageFile);
        const imageResultText = 'Image upload for ' + imageUrl + ' status: ' + imageResult?.statusText;
        setLogs((prevLogs) => [...prevLogs, imageResultText]);
      }
      let logMessage = 'Snapshot upload for ' + snapshotId + ' status: ';
      try {
        await ApiClient.createSnapshot(snapshot);
        logMessage += 'success';
      } catch (error) {
        logMessage += 'failed ';
        if (error instanceof APIClientError) {
          logMessage += error?.message;
        }
      }
      setLogs((prevLogs) => [...prevLogs, logMessage]);
      setProgress((prevProgress) => prevProgress + 100 / snapshotIds.length);
    }
  };

  const handleFileUpload = async (event) => {
    const zipFile = event.target.files[0];
    const files = await unpackZipFile(zipFile);
    setProgress(0);
    setLogs([]);
    await uploadSnapshots(files);
    setProgress(100);
  };

  return (
    <div style={{'width': '70%'}}>
      <Card>
        <CardContent>
          <h2>{t('Upload Snapshots')}</h2>
          <input
            accept=".zip"
            type="file"
            onChange={handleFileUpload}
            style={{display: 'none'}}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <Button variant="contained" color="primary" component="span">
            Select Zip File
            </Button>
          </label>
          <RegularSpacer/>
          {progress > 0 ? <LinearProgress variant="determinate" value={progress} /> : ''}
        </CardContent>
      </Card>
      {logs.length > 0 ?
      <div>
        <RegularSpacer/>
        <Card>
          <CardContent>
            <h2>{t('Logs')}</h2>
            {logs.map((log) => <p key={log}>{log}</p>)}
          </CardContent>
        </Card>
      </div> : ''}
    </div>
  );
};

export default UploadSnapshotPage;
