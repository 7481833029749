import React, {useEffect} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import HomePage from './Components/Home';
import ImagePage from './Components/Images/ImagePage';
import SystemLogsPage from './Components/SystemLogs/SystemLogsPage';
import SnapshotPage from './Components/Snapshot/SnapshotPage';
import CommentsPage from './Components/CommentsPage/CommentsPage';
import ErrorPage from './Components/Errors/ErrorPage';
import RerunPage from './Components/Rerun/RerunPage';
import ChartPage from './Components/Charts/ChartsPage';
import MorelDataUpload from './Components/Upload/MorelDataUpload';
import DailyReportPage from './Components/DailyReport/DailyReportPage';
import AnnotationPage from './Components/Annotations/AnnotationPage';
import ShoppingCartPage from './Components/ShoppingCart/ShoppingCartPage';
import DashboardPage from './Components/Dashboard/DashboardPage';
import AccountPage from './Components/Account';
import DatabaseDocumentsPage from './Components/DatabaseDocuments/DatabaseDocumentsPage';
import {UserContext} from './contexts/userContext';
import {useContext} from 'react';
import UploadSnapshotPage from './Components/Snapshot/UploadSnapshotPage';
import ImageUploadPage from './Components/Snapshot/ImageUploadPage';
import DeleteInstancesPage from './Components/Instances/DeleteInstancesPage';
import UsageOverviewPage from './Components/Dashboard/UsageOverviewPage';
import ExportPage from './Components/ERP/ExportPage';
import {useLocation} from 'react-router-dom';
import LaneBatchesPage from './Components/Lane/LaneBatchesPage';
import OverviewMapPage from './Components/IPMOverview/OverviewMapPage';
import HarvestLondonDataUpload from './Components/Upload/HarvestLondonDataUpload';
import StatusLogsPage from './Components/StatusLogs/StatusLogsPage';
import AnnotationsExportPage from './Components/Annotations/AnnotationsExportPage';
import BatchesPage from './Components/Batches/BatchesPage';
const Routing = () => {
  const {setCurrentClient, currentClient} = useContext(UserContext);
  const location = useLocation();
  // match current client to client name in the url whenever a new route is visited
  useEffect(() => {
    const pathname = location.pathname;
    const parts = pathname.split('/');
    parts.shift();
    const urlClientName = parts[0];
    if (urlClientName !== currentClient) {
      if (urlClientName ==='admin') {
        if (currentClient) {
          setCurrentClient(null);
        }
      } else {
        if (urlClientName !== 'login') {
          setCurrentClient(urlClientName);
        }
      }
    }
  }, []);


  const getSwitch = () => {
    return (
      <Switch>
        <Route exact path='/'>
          {currentClient ? <Redirect to={`/${currentClient}`}/> : <Redirect to={`/admin}`}/>}
        </Route>
        <Route exact path='/:client_name'>
          <HomePage />
        </Route>
        <Route path='/:client_name/images/:snapshot_id'>
          {currentClient ? <ImagePage annotations={false} snapshotId={true}/> : ''}
        </Route>
        <Route path='/:client_name/images'>
          {currentClient ? <ImagePage annotations={false} /> : ''}
        </Route>
        <Route exact path="/:client_name/snapshots" render={(props) => (
          <SnapshotPage key={props.location.pathname} {...props} />
        )} />

        <Route path="/:client_name/snapshots/:snapshot_id" render={(props) => (
          <SnapshotPage key={props.location.pathname} {...props} />
        )} />
        <Route path='/:client_name/annotations'>
          <AnnotationPage />
        </Route>
        <Route path='/:client_name/comments'>
          <CommentsPage sbris={true} />
        </Route>
        <Route path='/:client_name/upload-sheet'>
          <MorelDataUpload sbris={true} />
        </Route>
        <Route path='/:client_name/batch-info'>
          <HarvestLondonDataUpload />
        </Route>
        <Route path='/:client_name/errors'>
          <ErrorPage sbris={false} />
        </Route>
        <Route path='/:client_name/daily-report'>
          <DailyReportPage sbris={false} />
        </Route>
        <Route path='/:client_name/charts'>
          <ChartPage />
        </Route>
        <Route path='/:client_name/system-logs'>
          <SystemLogsPage />
        </Route>
        <Route path='/:client_name/dashboard'>
          <DashboardPage />
        </Route>
        <Route path='/:client_name/shopping-cart'>
          <ShoppingCartPage />
        </Route>
        <Route exact path='/:client_name/account'>
          <AccountPage />
        </Route>
        <Route path='/:client_name/rerun'>
          <RerunPage sbris={false} />
        </Route>
        <Route path='/:client_name/database-documents'>
          <DatabaseDocumentsPage />
        </Route>
        <Route path='/:client_name/upload-snapshot'>
          <UploadSnapshotPage />
        </Route>
        <Route path='/:client_name/delete-instances'>
          <DeleteInstancesPage />
        </Route>
        <Route path='/:client_name/usage-overview'>
          <UsageOverviewPage />
        </Route>
        <Route path='/:client_name/export-page'>
          <ExportPage />
        </Route>
        <Route path='/:client_name/lane-batches'>
          <LaneBatchesPage />
        </Route>
        <Route path='/:client_name/overview-map'>
          <OverviewMapPage />
        </Route>
        <Route path='/:client_name/status-logs'>
          <StatusLogsPage />
        </Route>
        <Route path='/:client_name/export-annotations'>
          <AnnotationsExportPage />
        </Route>
        <Route path='/:client_name/batches'>
          <BatchesPage/>
        </Route>
        <Route path='/:client_name/image-upload'>
          <ImageUploadPage/>
        </Route>
      </Switch>
    );
  };

  return getSwitch();
};

export default Routing;
