import React, {useState} from 'react';
import {annotationColumns} from '../../Utils/AnnotationHelpers';
import {AnnotationImagePopUp} from './AnnotationImagePopup';
import {Box, Button} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {mkConfig, generateCsv, download} from 'export-to-csv';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import {pSBC} from '../../Utils/shadeColorLabels';
import {jsPDF} from 'jspdf';
import autoTable from 'jspdf-autotable';

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
  title: 'Annotations',
});

function tableToJson(table, columns) {
  const data = [];
  // go through cells
  for (let i = 1; i < table.length; i++) {
    const tableRow = table[i];

    // create an array rather than an object
    const rowData = [];
    for (const key of Object.keys(tableRow)) {
      if (columns.includes(key)) {
        rowData.push(tableRow[key]);
      }
    }
    data.push(rowData);
  }

  return data;
}

const AnnotationTable = ({labels, data, startDate, endDate}) => {
  if (!data || !labels || !startDate || !endDate) return null;
  const [showPopUp, setShowPopUp] = useState({show: false, annotation: '', position: {x: 0, y: 0}});

  const handleCSVExportRows = (rows, visibleColumns) => {
    const visibleColumnLabels = visibleColumns.map((column) => column.id);
    const rowData = rows.map((row) => row.original);

    // filter unselected columns from the data
    const filteredRowData = rowData.map((row) => {
      const filteredRow = {};
      Object.keys(row).forEach((key) => {
        if (visibleColumnLabels.includes(key)) {
          // get header label from annotationColumns using the key
          const header = annotationColumns.find((column) => column.accessorKey === key).header;
          filteredRow[header] = row[key];
        }
      });
      return filteredRow;
    });
    const csv = generateCsv(csvConfig)(filteredRowData);
    download(csvConfig)(csv);
  };

  const handlePDFExportRows = (rows, visibleColumns) => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('l', 'mm', [420, 297]);
    const tableData = rows.map((row) => row.original);
    const tableHeaders = visibleColumns.map((column) => column.id);

    const filteredHeaders = new Set();
    const filteredTableData = tableData.map((row) => {
      const filteredRow = {};
      Object.keys(row).forEach((key) => {
        if (tableHeaders.includes(key)) {
          // get header label from annotationColumns using the key
          const header = annotationColumns.find((column) => column.accessorKey === key).header;
          filteredHeaders.add(header);
          filteredRow[header] = row[key];
        }
      });
      return filteredRow;
    });

    const finalTable = tableToJson(filteredTableData, Array.from(filteredHeaders));

    autoTable(doc, {
      head: [Array.from(filteredHeaders)],
      body: finalTable,
    });

    doc.save(`annotations-${startDate}-${endDate}.pdf`);
  };

  const table = useMaterialReactTable({
    muiTableBodyRowProps: ({row}) => ({
      sx: {
        backgroundColor: pSBC(0.50, labels[row.original.label].color, false, true),
      },
      onMouseEnter: (e) => setShowPopUp({show: true, annotation: row.original, position: {x: e.clientX, y: e.clientY}}),
      onMouseLeave: () => setShowPopUp({show: false, annotation: '', position: {x: 0, y: 0}}),
    }),
    columns: annotationColumns,
    data: data,
    enableRowSelection: true,
    enableBatchRowSelection: true,
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    initialState: {density: 'compact'},
    renderTopToolbarCustomActions: ({table}) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handleCSVExportRows(table.getPrePaginationRowModel().rows, table.getVisibleFlatColumns())
          }
          startIcon={<FileDownloadIcon />}
        >
          Export CSV All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleCSVExportRows(table.getRowModel().rows, table.getVisibleFlatColumns())}
          startIcon={<FileDownloadIcon />}
        >
          Export CSV Current Page
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleCSVExportRows(table.getSelectedRowModel().rows, table.getVisibleFlatColumns())}
          startIcon={<FileDownloadIcon />}
        >
          Export CSV Selected Rows
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() =>
            handlePDFExportRows(table.getPrePaginationRowModel().rows, table.getVisibleFlatColumns())
          }
          startIcon={<FileDownloadIcon />}
        >
          Export PDF All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handlePDFExportRows(table.getRowModel().rows, table.getVisibleFlatColumns())}
          startIcon={<FileDownloadIcon />}
        >
          Export PDF Current Page
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handlePDFExportRows(table.getSelectedRowModel().rows, table.getVisibleFlatColumns())}
          startIcon={<FileDownloadIcon />}
        >
          Export PDF Selected Rows
        </Button>
      </Box>
    ),
  });


  return <div style={{maxWidth: '95vw'}}>
    {showPopUp.show && <AnnotationImagePopUp isVisible annotation={showPopUp.annotation}
      position={showPopUp.position}/>}
    <MaterialReactTable table={table}/>
  </div>;
};

export default AnnotationTable;
