
import {Cell, Pie, PieChart} from 'recharts';
import {Grid, useTheme} from '@material-ui/core';
import {useEffect, useState} from 'react';

function SpeedChart(props) {
  const theme = useTheme();
  const [fastNeedleValue, setFastNeedleValue] = useState(null);
  const [fastNeedleRange, setFastNeedleRange] = useState(null);
  const [slowNeedleValue, setSlowNeedleValue] = useState(null);
  const [slowNeedleRange, setSlowNeedleRange] = useState(null);

  useEffect(() => {
    const keys = Object.keys(props.inputValues);
    const range = keys[0];
    const value = props.inputValues[range].length / (range / 60);
    const range2 = keys[1];
    const value2 = props.inputValues[range2].length / (range2 / 60);
    if (range > range2) {
      setFastNeedleValue(value2);
      setFastNeedleRange(range2);
      setSlowNeedleValue(value);
      setSlowNeedleRange(range);
    } else {
      setFastNeedleValue(value);
      setFastNeedleRange(range);
      setSlowNeedleValue(value2);
      setSlowNeedleRange(range2);
    }
  }, [props.inputValues]);

  const RADIAN = Math.PI / 180;
  const data = [
    {name: 'A', value: 20, color: 'lightgray'},
    {name: 'B', value: 20, color: 'darkgray'},
    {name: 'C', value: 20, color: 'gray'},
  ];
  const cx = 150;
  const cy = 200;
  const iR = 50;
  const oR = 100;

  const needle = (value, data, cx, cy, iR, oR, color, extraNeedleLength = 0, name = 'noname') => {
    let total = 0;
    data.forEach((v) => {
      total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3 + extraNeedleLength;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 8;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle key={'circle_name' + name} cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
      <path key={'path_name' + name} d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke="#none" fill={color} />,
    ];
  };


  return (
    <div style={{width: 300}}>
      <p style={{
        marginBottom: -80,
        textAlign: 'center',
      }}>{props.chartTile}</p>
      <PieChart width={300} height={250}>
        <Pie
          dataKey="value"
          startAngle={180}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          paddingAngle={5}
          fill="#8884d8"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {needle(fastNeedleValue, data, cx, cy, iR, oR, theme.palette.secondary.main, 20, 'fast')}
        {needle(slowNeedleValue, data, cx, cy, iR, oR, theme.palette.primary.main, -25, 'slow')}
      </PieChart>
      <Grid container>
        <Grid item md={12} lg={6}>
          <p style={{
            textAlign: 'center',
            marginTop: -10,
            marginBottom: 0,
            color: theme.palette.secondary.main,
            fontSize: 30,
          }}>{fastNeedleValue}</p>
          <p style={{
            textAlign: 'center',
            color: theme.palette.secondary.main,
            marginTop: -5,
          }}>Average speed in last {fastNeedleRange} seconds</p>
        </Grid>
        <Grid item md={12} lg={6}>
          <p style={{
            textAlign: 'center',
            marginTop: -10,
            marginBottom: 0,
            color: theme.palette.primary.main,
            fontSize: 30,
          }}>{slowNeedleValue}</p>
          <p style={{
            textAlign: 'center',
            color: theme.palette.primary.main,
            marginTop: -5,
          }}>Average speed in last {slowNeedleRange / 60} minutes</p>
        </Grid>
      </Grid>
    </div>);
}

export default SpeedChart;
