import React, {useState, useEffect} from 'react';
import {CardContent, Grid, Select, MenuItem, Button} from '@material-ui/core';
import {ApiClient} from '../../Utils/ApiClient';
import {getDatePlusInterval} from '../../Utils/DateUtils';
import clientConfig from '../../Config/clients.json';
import {UserContext} from '../../contexts/userContext';
import {DateRangePickerComponent} from '../../Utils/DatePickers';
import {CardWithMargins, Header5, InputLabelWithTopMargin} from './ChartsPageStyles';
import {DataFormatter} from './DataFormatter';
import {RegularSpacer} from '../BasicComponents/Spacers';
import SimpleChart from './SimpleChart';
import LanePositionPicker from '../UserInput/LanePositionPicker';
import InBedPosition from '../../Utils/InBedPosition';
import {useContext} from 'react';
import {useHistory} from 'react-router-dom';

class GraphInput {
  constructor(selectedClient, startDate, endDate, otherFilters, postFilters) {
    this.selectedClient = selectedClient;
    this.startDate = startDate;
    this.endDate = endDate;
    this.otherFilters = otherFilters;
    this.postFilters = postFilters;
  }
}

function ChartPage(props) {
  const allClients = clientConfig.names;

  const [otherFilters, setOtherFilters] = useState('{}');
  const [startDate, setStartDate] = useState(() => getDatePlusInterval(-31 * 3));
  const [endDate, setEndDate] = useState(() => getDatePlusInterval(0));
  const [verticalMarkers, setVerticalMarkers] = useState();
  const [postFilters, setPostFilters] = useState();
  const [batches, setBatches] = useState({});
  const [batchName, setBatchName] = useState('test');
  const [batchNames, setBatchNames] = useState([]);
  const [crop, setCrop] = useState('');
  const [inBedPosition, setInBedPosition] = useState(new InBedPosition('', '', null, null));
  const [chartSettings, setChartSettings] = useState(null);
  const [chartData, setChartData] = useState({});
  const {getUserConfig, currentClient} = useContext(UserContext);
  const history = useHistory();
  useEffect(() => {
    setOtherFilters(inBedPosition.getQuery());
  }, [inBedPosition]);
  useEffect(() => {
    if (batchNames?.length > 0) {
      setBatchName(batchNames[0]);
    }
  }, [batchNames]);

  useEffect(() => {
    const userConfig = getUserConfig(true);
    const chartSettings = userConfig?.charts_page?.charts;
    setChartSettings(chartSettings);
    let batches = userConfig?.charts_page?.batches;
    if (batches === undefined) {
      batches = {};
    }
    setBatches(batches);
    setBatchNames(userConfig?.charts_page?.batchNames);
    history.push((`/${currentClient || 'admin'}/charts`));
  }, [currentClient]);

  useEffect(() => {
    if (Object.keys(batches).includes(batchName)) {
      const batch = JSON.parse(JSON.stringify(batches[batchName]));
      if (batch.flowering_date !== undefined) {
        setVerticalMarkers([batch.flowering_date]);
        delete batch.flowering_date;
      }
      setPostFilters(batch);
      const queryBatch = convertBatchToQuery(batch);
      setOtherFilters(queryBatch);
    } else {
      setOtherFilters({'data.batch_ids': {$elemMatch: {batch_id: batchName}}});
    }
  }, [batchName]);

  useEffect(() => {
    const cropToQuery = {
      'Left': {'data.crop': {'$regex': '-l$'}},
      'Right': {'data.crop': {'$regex': '-r$'}},
    };
    setOtherFilters(cropToQuery[crop]);
  }, [crop]);

  const fetchData = (graphInput) => {
    if (!chartSettings) {
      return;
    }
    Object.entries(chartSettings).forEach(([graphName, chartSetting]) => {
      ApiClient.findSnapshotsByClient(graphInput.startDate, graphInput.endDate,
          'analyzed', graphInput.otherFilters, true, graphInput.selectedClient).then((snapshots) => {
        snapshots = filterSnapshots(snapshots, graphInput.postFilters);
        snapshots.sort((a, b) => (new Date(a.collection_date) - new Date(b.collection_date)));
        snapshots = DataFormatter.formatData(snapshots, chartSetting);
        setChartData((prevData) => ({...prevData, [graphName]: snapshots}));
      });
    });
  };

  const drawGraph = () => {
    if (!currentClient) {
      return;
    }
    const graphInput = new GraphInput(currentClient, startDate, endDate, otherFilters, postFilters);
    if (typeof graphInput.otherFilters !== 'undefined') {
      fetchData(graphInput);
    }
  };

  const convertBatchToQuery = (batch) => {
    const queries = [];
    if (batch === undefined) {
      return {};
    }
    batch = {...batch};
    const startingHook = batch['start'][0];
    const endingHook = batch['end'][0];
    const startingDistance = batch['start'][1];
    const endingDistance = batch['end'][1];
    delete batch['start'];
    delete batch['end'];
    batch['status'] = 'analyzed';
    for (let hook = startingHook; hook <= endingHook; hook++) {
      batch['data.position_id.hook'] = hook;
      if (hook === startingHook && hook === endingHook) {
        batch['data.position_id.distance_previous_hook_rounded'] = {'$gt': startingDistance, '$lt': endingDistance};
      } else if (hook === startingHook) {
        batch['data.position_id.distance_previous_hook_rounded'] = {'$gt': startingDistance};
      } else if (hook === endingHook) {
        batch['data.position_id.distance_previous_hook_rounded'] = {'$lt': endingDistance};
      }
      queries.push(batch);
    }
    return {'$or': queries};
  };

  const filterSnapshots = (snapshots, filter) => {
    if (typeof filter === 'undefined') {
      return snapshots;
    }
    const filteredSnapshots = [];
    const batch = filter;
    const startingHook = batch['start'][0];
    const startingDistance = batch['start'][1];
    const endingHook = batch['end'][0];
    const endingDistance = batch['end'][1];
    for (let i = 0; i < snapshots.length; i++) {
      const snapshot = snapshots[i];
      const hook = snapshot['data']['position_id']['hook'];
      const distance = snapshot['data']['position_id']['distance_previous_hook_rounded'];
      if (hook === startingHook && distance < startingDistance) {
        continue;
      }
      if (hook === endingHook && distance > endingDistance) {
        continue;
      }
      filteredSnapshots.push(snapshot);
    }
    return filteredSnapshots;
  };

  const handleChangeDate = (e, isStart) => {
    const dateObject = new Date(e);
    dateObject.setHours(0, 0, 0, 0);
    if (isStart) {
      setStartDate(dateObject.toISOString());
    } else {
      setEndDate(dateObject.toISOString());
    }
  };

  const getBatchItems = () => {
    if (batches === undefined) {
      return <></>;
    }
    return Object.entries(batches).map(([batchName, _]) => {
      return <MenuItem key={batchName} value={batchName}>{batchName}</MenuItem>;
    });
  };

  const isLaneClient = (client) => {
    if (!client) {
      return false;
    }
    if (client === allClients.ROYAL_VAN_ZANTEN) {
      return false;
    }
    const hasLane = getUserConfig(true)?.has_lane;
    return hasLane === true;
  };

  function getCharts(settings, data) {
    let allDataLoaded = true;
    Object.keys(settings).forEach((graphName) => {
      if (!data[graphName]) {
        allDataLoaded = false;
      }
    });
    if (!allDataLoaded) {
      return <></>;
    }
    const result = [];
    Object.entries(settings).forEach(([graphName, chartSetting]) => {
      result.push(
          <>
            <Header5>{graphName}</Header5>
            <CardWithMargins style={{width: 'auto', maxWidth: '800px'}}>
              <CardContent>
                <SimpleChart data={data[graphName]} graphSettings={chartSetting}
                  verticalMarkers={verticalMarkers}></SimpleChart>
              </CardContent>
            </CardWithMargins>
          </>);
    });
    return result;
  }

  function getInputFields() {
    return (
      <span>
        <Header5>Inputs</Header5>
        <CardWithMargins>
          <CardContent>
            {currentClient === allClients.HARVEST_LONDON ? <>
              <InputLabelWithTopMargin id="batch-picker">Camera</InputLabelWithTopMargin>
              <Select
                labelId="crop-picker"
                id="crop-picker-select"
                label="Crop"
                value={crop}
                onChange={(event) => setCrop(event.target.value)}>
                <MenuItem id="Left" value={'Left'}>Left</MenuItem>
                <MenuItem id="Right" value="Right">Right</MenuItem>
              </Select>
            </> : <></>}
            {currentClient === allClients.ROYAL_VAN_ZANTEN ? <>
              <InputLabelWithTopMargin id="batch-name-picker">Batch</InputLabelWithTopMargin>
              <Select
                labelId="batch-name-picker"
                id="batch-name-picker-select"
                label="Batch"
                value={batchName}
                onChange={(event) => setBatchName(event.target.value)}>
                {getBatchItems()}
              </Select>
            </> : <></>}
            {currentClient === allClients.ANTHURA ? <>
              <InputLabelWithTopMargin id="batch-picker">Batch</InputLabelWithTopMargin>
              <Select
                labelId="batch-picker"
                id="batch-picker-select"
                label="Batch"
                value={batchName}
                onChange={(event) => setBatchName(event.target.value)}>
                {batchNames ? batchNames.map((item) => {
                  return <MenuItem key={item} value={item}>{item}</MenuItem>;
                }): []}
              </Select>
              <RegularSpacer size={3}/>
            </> : <></>}
            {props.startDate && props.endDate ?
                            '' :
                <DateRangePickerComponent
                  id="dateFrom"
                  defaultStartDate={startDate}
                  defaultEndDate={endDate}
                  changeStartDate={(event) => handleChangeDate(event, true)}
                  changeEndDate={(event) => handleChangeDate(event, false)}
                />
            }
            {isLaneClient(currentClient) ?
                              <LanePositionPicker
                                hideCard={true}
                                onLanePositionChanged={setInBedPosition}
                              /> : ''
            }
            <InputLabelWithTopMargin></InputLabelWithTopMargin>
            <Button variant="contained" color="primary" onClick={drawGraph} >
              {'Draw Graph'}
            </Button>
          </CardContent>
        </CardWithMargins>
      </span>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={3} style={{maxWidth: '400px'}}>
        {getInputFields()}
      </Grid>
      <Grid item sm={12} md={9}>
        {chartSettings && chartData ? getCharts(chartSettings, chartData) : <span></span>}
      </Grid>
    </Grid>
  );
}

export default ChartPage;
