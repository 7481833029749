import React, {useState, useRef} from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import {useTranslation} from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import {ApiClient} from '../../Utils/ApiClient';
import {styled, useTheme} from '@mui/material/styles';
import {CenteredCardContent, UserInputs} from '../StyledComponents/BasicStyles';
import {useEffect} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {dateToStringWithoutTime} from '../../Utils/DateUtils';
import {CardContent, Grid} from '@material-ui/core';
import SimpleFilter from '../Images/SimpleFilter';

const Input = styled('input')(({theme}) => ({
  alignSelf: 'center',
  marginBottom: theme.spacing(2),
}));

const SuccessText = styled(Typography)(({theme}) => ({
  ...({
    marginTop: theme.spacing(1),
  }),
}));

function MorelDataUpload() {
  const {t} = useTranslation();
  const theme = useTheme();
  const inputRef = useRef();
  const [selectedFile, setSelectedFile] = useState();
  const [isUploaded, setIsUploaded] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [failedUpload, setFailedUpload] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [isTruncated, setIsTruncated] = useState(false);

  const truncateNumber = 20;
  const wrapperWidth = 1000;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    filterDataOnText();
  }, [filterText, data]);

  const filterDataOnText = () => {
    let visibleData = data;
    if (filterText !== '') {
      visibleData = data.filter((entry) => entry.tray_number.includes(filterText));
    }
    if (visibleData.length > truncateNumber) {
      visibleData = visibleData.slice(0, truncateNumber);
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
    setFilteredData(visibleData);
  };

  const removeEntryFromData = (id) => {
    const newData = data.filter((entry) => entry._id !== id);
    setData(newData);
  };

  const removeEntry = (id) => {
    ApiClient.deleteMorelTrayInformation(id).then((response) => {
      if (response === undefined || response?.status !== 200) {
        return;
      }
      removeEntryFromData(id);
    });
  };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmission = () => {
    const formData = new FormData();

    formData.append('File', selectedFile);

    ApiClient.uploadMorelDataSheet(formData)
        .then((result) => {
          if (result.status !== 200) {
            setSelectedFile();
            setFailedUpload(true);
            setIsUploaded(false);
            setStatusMessage(t('Upload failed.'));
            if (result.status === 409) {
              setStatusMessage(t('409Morel'));
            } else if (result.status === 206) {
              setStatusMessage(t('206Morel'));
            }
          } else {
            setSelectedFile();
            setIsUploaded(true);
          }
          getData();
          inputRef.current.value = '';
        })
        .catch((error) => {
          console.log('Error', error);
        });
  };

  const getData = () => {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() - 365);
    ApiClient.getMorelTrayInformation(startDate).then((response) => {
      if (response.status !== 200) {
        return;
      }
      setData(response.data.data);
      setFilteredData(response.data.data);
    });
  };

  const getExampleTable = () => {
    return (<div>
      <table>
        <tr>
          <th>Date de comptage</th>
          <th>Semaine Semis</th>
          <th>Date de semis</th>
          <th>N° Terrine</th>
          <th>N° Lot</th>
          <th>Qt graine tot/lot</th>
          <th>Âge du jeunes plants/Jours</th>
          <th>Mauvais</th>
          <th>Moyen</th>
          <th>Bon</th>
          <th>Marbré/Blanc</th>
          <th>Jaune</th>
          <th>Rose</th>
          <th>Pourcentage de germination</th>
        </tr>
        <tr>
          <th></th>
          <th>1</th>
          <th>25/01/2023</th>
          <th>T600-00276</th>
          <th>2022G.S01.562A</th>
          <th>100</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        <tr>
          <th></th>
          <th>..</th>
          <th>..</th>
          <th>..</th>
          <th>..</th>
          <th>..</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </table>
    </div>);
  };
  return (
    <UserInputs>
      <Card>
        <CenteredCardContent maxWidth={wrapperWidth}>
          <h2 >{t('Upload Sheet (csv)')}</h2>
          <br />
          {getExampleTable()}
          <br />
          <div>
            <Input style={{color: theme.palette.primary.main}} ref={inputRef}
              type="file" name="file" accept=".csv" onChange={changeHandler} />
            <br />
            <Button size="small" color="primary" onClick={handleSubmission}>
              <Typography>{t('Upload')}</Typography>
            </Button>
            {isUploaded ? <SuccessText theme={theme} >{t('Successful Upload')}</SuccessText> : <></>}
            {failedUpload ? <SuccessText theme={theme} >{statusMessage}</SuccessText> : <></>}
          </div>
        </CenteredCardContent>
      </Card>
      <Card style={{marginTop: theme.spacing(5), marginBottom: theme.spacing(1)}}>
        <CenteredCardContent maxWidth={wrapperWidth}>
          <h2>Batches in the database</h2>
          <Typography>
            {'This is a list of all batches from the last year '}
            {'that are currently in the database.'}
          </Typography>
        </CenteredCardContent>
      </Card>
      <SimpleFilter
        triggerMethod={(v) => setFilterText(v)}
        label={'N° Terrine'} />
      <Grid container justifyContent="center" style={{width: wrapperWidth, marginLeft: theme.spacing(-1)}}>
        {filteredData.map((entry) =>
          <Grid item key={entry._id}>
            <Card
              style={{
                marginBottom: theme.spacing(1),
                marginLeft: theme.spacing(1),
                position: 'relative',
                textAlign: 'left',
                width: 250,
                height: 120,
              }}>
              <CardContent>
                <Typography>Tray: {entry.tray_number}</Typography>
                <Typography>Planting date: {dateToStringWithoutTime(new Date(entry.planting_date))}</Typography>
                <Typography>Batch: {entry.batch}</Typography>
                <DeleteIcon
                  style={{position: 'absolute', bottom: 10, right: 10, color: 'red'}}
                  onClick={() => removeEntry(entry._id)} />
              </CardContent>
            </Card>
          </Grid>,
        )}
        {isTruncated ? <Card
          style={{
            marginBottom: theme.spacing(1),
            marginLeft: theme.spacing(1),
            position: 'relative',
            textAlign: 'left',
            width: 250,
            height: 120,
          }}>
          <CardContent>
            <Typography>...</Typography>
          </CardContent>
        </Card> : <></>}
      </Grid>
    </UserInputs >
  );
}

export default MorelDataUpload;
