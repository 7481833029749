
import React, {useState, useEffect} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import List from '@material-ui/core/List';
import MediaCard from './MediaCard';
import {ApiClient} from '../../Utils/ApiClient';
import {makeStyles} from '@material-ui/styles';
import {Button} from '@material-ui/core';
import {useContext} from 'react';
import {UserContext} from '../../contexts/userContext';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
  },
  media: {
    width: '100%',
  },
  card: {
    minHeight: 100,
    padding: 50,
  },
}));

function DetailedList(props) {
  const {t} = useTranslation();
  const classes = useStyles();
  const [currentSnapshot, setCurrentSnapshot] = useState(null);
  const [snapshots, setSnapshots] = useState([]);
  const [visibleSnapshots, setVisibleSnapshots] = useState([]);
  const [instance, setInstance] = useState(null);
  const [showAllSnapshots, setShowAllSnapshots] = useState(false);
  const {currentClient} = useContext(UserContext);
  useEffect(() => {
    if (props.data?._id === undefined) {
      return;
    }
    setInstance(null);
    setVisibleSnapshots([]);
    setShowAllSnapshots(false);
    loadOneSnapshot();
  }, [props.data._id]);

  useEffect(() => {
    setVisibleSnapshots([]);
  }, [currentClient]);
  useEffect(() => {
    if (currentSnapshot) {
      loadInstance(currentSnapshot);
    }
  }, [currentSnapshot]);

  useEffect(() => {
    if (instance && props?.loadInstanceProperties) {
      props.loadInstanceProperties(instance);
      loadAllSnapshots();
    }
  }, [instance]);

  useEffect(() => {
    if (snapshots?.length > 0 && showAllSnapshots) {
      setVisibleSnapshots(snapshots);
    } else if (currentSnapshot && !showAllSnapshots) {
      setVisibleSnapshots([currentSnapshot]);
    }
  }, [snapshots, currentSnapshot, showAllSnapshots]);

  const loadAllSnapshots = async () => {
    const result = await ApiClient.findSnapshotsById(instance.snapshots);
    const snapshotIds = result.map((snapshot) => snapshot._id);
    if (snapshotIds.includes(props.data._id)) {
      setSnapshots(result);
    }
  };

  const loadOneSnapshot = async () => {
    const currentSnapshot = await ApiClient.findSnapshotById(props.data._id);
    setCurrentSnapshot(currentSnapshot);
  };

  const loadInstance = async (snapshot) => {
    const instance = await ApiClient.findMatchingInstance(snapshot._id);
    if (instance) {
      setInstance(instance);
    }
  };

  const getMediaCard = (item) => {
    return <MediaCard key={item._id + '_media_card'} data={item} instance={instance}
      changeSnapshotStatus={props.changeSnapshotStatus} />;
  };

  const getCardList = (data) => {
    data.sort((a, b) => (new Date(a.collection_date) < new Date(b.collection_date)) ? 1 : -1);
    const result = [];
    for (const snapshot of data) {
      result.push(getMediaCard(snapshot));
    }
    return <List className={classes.list}>{result}</List>;
  };

  return (
    visibleSnapshots.length === 0 ?
            <Card className={classes.root}>
              <Paper />
              <CardContent className={classes.card}>
                <Typography gutterBottom variant="h5" component="h2">
                  {t('Empty..')}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {t('Select an item in the list left to be displayed in more detail here.')}
                </Typography>
              </CardContent>
            </Card> :
            <div>
              {getCardList(visibleSnapshots)}
              {instance !== null && !showAllSnapshots ?
              <Button variant="outlined" onClick={() => setShowAllSnapshots(true)}>
                {t('Load more pictures of these plants.')}
              </Button> : t('These are all the pictures available of these plants.')}
            </div>
  );
}

export default DetailedList;
