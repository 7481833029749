import React from 'react';
import {ReactComponent as SuccessIcon} from './icons/success-3.svg';
import {ReactComponent as FailureIcon} from './icons/fail-1.svg';
import {ReactComponent as IncompleteIcon} from './icons/incomplete.svg';
import DiscardedIcon from './DiscardedIcon';

// This component is responsible for rendering the symbols in the map cells.
const MapCellSymbol = ({d, laneIndex, i, xScale, yScale, yOffset, mapType, annotationLetter, annotationFilters}) => {
  const plantCount = d.plantCount || 0;
  const labelCounts = d.labelCounts || {};
  const currentLabel = annotationFilters.label;
  const currentLabelCount = labelCounts[currentLabel]?.count || 0;
  return (
    <g>
      {
        mapType === 'annotations' &&
        <g>
          {
          annotationFilters.label !== 'all' ?
          <text x={xScale(d.x) + xScale.bandwidth() / 2}
            y={yScale(d.y) + yOffset + yScale.bandwidth() / 2}
            textAnchor="middle" dominantBaseline="middle" fontSize={14} fill='black'
            fontWeight='bold'>{currentLabelCount === 0 ? '' : `${currentLabelCount}/${plantCount}`}
          </text> :
        <text x={xScale(d.x) + xScale.bandwidth() / 2}
          y={yScale(d.y) + yOffset + yScale.bandwidth() / 2}
          textAnchor="middle" dominantBaseline="middle" fontSize={32} fill='black'
          fontWeight='bold'>{annotationLetter}
        </text> }
        </g>
      }
      {
        mapType === 'snapshots' &&
        <g>
          { d.heighestStatus === 'discarded' &&
            <DiscardedIcon laneIndex={laneIndex} i={i} x={xScale(d.x)} y={yScale(d.y)} xScale={xScale}
              yScale={yScale} yOffset={yOffset}/>
          }
          {d.heighestStatus === 'analyzed' &&
            <SuccessIcon x={xScale(d.x) + (xScale.bandwidth()/4)}
              y={yScale(d.y) + yOffset + (yScale.bandwidth()/4)}/>}
          {d.heighestStatus === 'failed' &&
            <FailureIcon x={xScale(d.x) + (xScale.bandwidth()/4)}
              y={yScale(d.y) + yOffset + (yScale.bandwidth()/4)}/>}
          {d.heighestStatus === 'incomplete' &&
            <IncompleteIcon x={xScale(d.x) + (xScale.bandwidth()/4)}
              y={yScale(d.y) + yOffset + (yScale.bandwidth()/4)}/>}
        </g>
      }
    </g>
  );
};

export default MapCellSymbol;
