import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import 'date-fns';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';


function SimpleFilter({triggerMethod, label, handleSubmit, noCard = false}) {
  const [filterText, setFilterText] = useState('');
  const {t} = useTranslation();
  const theme = useTheme();

  const changeHandler = (event) => {
    setFilterText(event.target.value);
    if (triggerMethod !== null) {
      triggerMethod(event.target.value);
    }
  };

  const getFormControl = () => {
    return <span>
      <FormControl style={{
        marginRight: theme.spacing(1),
        width: 200,
      }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={label}
          value={filterText}
          style={{marginRight: theme.spacing(1)}}
          onChange={changeHandler}
          id="batch_id"
          name="name"
          autoFocus
        />
      </FormControl>
      {handleSubmit !== undefined ?
                    <Button size="small" color="primary" onClick={(filterText) => handleSubmit(filterText)}
                      style={{
                        margin: theme.spacing(1),
                        marginTop: theme.spacing(2),
                        height: 50,
                      }}>
                      {t('Search')}
                    </Button> : <></>
      }
    </span>;
  };

  return (
    noCard ? getFormControl() :
    <Card
      style={{
        maxHeight: 500,
        overflow: 'auto',
        position: 'relative',
        marginBottom: theme.spacing(2),
      }}>
      <CardContent>
        {getFormControl()}
      </CardContent>
    </Card>

  );
}

export default SimpleFilter;
