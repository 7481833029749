import {styled} from '@mui/material/styles';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {withTheme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

export const HeaderText = withTheme(styled(Typography)`
  text-align: left;
  margin-left: ${(props) => props.theme.spacing(2)}
  margin-bottom: 10;
  color: ${(props) => props.theme.palette.primary.dark}
`);

export const RootDiv = withTheme(styled('div')`
    flex-grow: 1;
    padding: ${(props) => props.theme.spacing(2)}
`);

export const RootCard = withTheme(styled(Card)`
    flex-grow: 1;
    padding: ${(props) => props.theme.spacing(2)}
`);

export const NormalizedGrid = withTheme(styled(Grid)`
    margin: 0;
    width: 100%;
`);

export const SizedCardContent = withTheme(styled(CardContent)`
    margin: 0;
    width: 100%;
`);

export const CommentText = withTheme(styled(Typography)`
    text-align: left;
    margin-left: theme.spacing(2);
    margin-bottom: 10;
    margin-top: 20;
    color: ${(props) => props.theme.palette.primary.dark}
`);

export const SendButton = withTheme(styled(Button)`
    width: '100%'
    margin-top: ${(props) => props.theme.spacing(3)}
`);

export const ButtonCard = withTheme(styled(Card)`
    margin-bottom: 5px;
    width: fit-content;
`);
