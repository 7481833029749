import React, {createContext, useContext, useState} from 'react';

const PlantSelectionContext = createContext();

export function PlantSelectionProvider({children}) {
  const [selectedPlant, setSelectedPlant] = useState(null);

  return (
    <PlantSelectionContext.Provider value={{selectedPlant, setSelectedPlant}}>
      {children}
    </PlantSelectionContext.Provider>
  );
}

export function usePlantSelection() {
  return useContext(PlantSelectionContext);
}
