import React, {useState, useEffect, useRef} from 'react';
import {Select, MenuItem, CardContent, FormControl, InputLabel} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {Link} from 'react-router-dom';
import {MapInteractionCSS} from 'react-map-interaction';
import {useHotkeys} from 'react-hotkeys-hook';
import {checkVersionSafely} from '../Snapshots/SnapshotUtils';
import SnapshotDetailsCard from '../Snapshot/SnapshotDetailsCard.js';
import {UserContext} from '../../contexts/userContext';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/styles';
import {ContainsData, SimpleCalculations, DisplayMetric, displayMetricFunction} from './DisplayDivs.js';
import {ApiClient} from '../../Utils/ApiClient';
import CopySnapshot from '../CopySnapshot/CopySnapshot';
import {CartContext} from '../ShoppingCart/CartContext';
import {useContext} from 'react';
import {ConstructionAddIcon, ConstructionAddedIcon} from '../ShoppingCart/CartIcons';
import PlantAnnotationPanel from '../Annotations/PlantAnnotationPanel';
import {AnnotationProvider} from '../Annotations/AnnotationContext';
import PlantOverlay from './ResultVisualization/PlantOverlay';
import {PlantSelectionProvider} from './ResultVisualization/PlantSelectionContext';
import Crosshair from './ResultVisualization/Crosshair.js';
import SystemEvaluationResults from './SystemEvaluationResults.js';
import {RegularSpacer} from '../BasicComponents/Spacers.js';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    marginBottom: theme.spacing(2),
  },
  deletedCard: {
    maxWidth: '100%',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.gray.main,
  },
  crossedOut: {
    // eslint-disable-next-line quote-props
    width: '100%',
    // eslint-disable-next-line quote-props
    position: 'relative',
    // eslint-disable-next-line quote-props
    background: 'gray',
    // eslint-disable-next-line quote-props
    overflow: 'hidden',
    '&::before, &::after': {
      position: 'absolute',
      content: '""',
      background: theme.palette.gray.main,
      display: 'block',
      width: '100%',
      height: '10px',
      transform: 'rotate(-45deg)',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
    '&::after': {
      transform: 'rotate(45deg)',
    },
  },
  fullWidth: {
    width: '100%',
  },
  card: {
    minHeight: 100,
    padding: 50,
  },
  warningText: {
    color: theme.palette.primary.dark,
    variant: 'body2',
  },
  mediaContainer: {
    justifyContent: 'space-between',
  },
  commentCard: {
    marginBottom: theme.spacing(3),
  },
  warning: {
    color: theme.palette.error.main,
  },
  failure: {
    backgroundColor: theme.palette.error.main,
  },
  success: {
    backgroundColor: theme.palette.primary.main,
  },
  systemEvaluationCard: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25%',
    padding: 5,
  },
}));

function MediaCard(props) {
  const {t} = useTranslation();
  const classes = useStyles();
  const imgRef = useRef(null);
  const resizeTimeout = useRef(null);
  const {currentClient, getUserConfig, user} = useContext(UserContext);
  const config = getUserConfig(null, props.data?.client);

  const [imagePage, setImagePage] = useState(0);
  const [imageType, setImageType] = useState();
  const [imageUrls, setImageUrls] = useState({});
  const [detailsBox, setDetailsBox] = useState(false);
  const [selectedPlant, setSelectedPlant] = useState(0);
  const [clickedScheduleOneJob, setClickedScheduleOneJob] = useState(false);
  const [possibleImages, setPossibleImages] = useState([]);
  const [downscalingRatio, setDownscalingRatio] = useState(null);
  const [showCrosshair, setCrosshair] = useState(false);
  const [clickedResetBatches, setClickedResetBatches] = useState(false);
  const {addToCart, isAlreadyInCart, removeFromCart, isCartVisible} = useContext(CartContext);
  const status = props.data?.status;

  useHotkeys('c', () => setCrosshair(!showCrosshair));

  const calculateDownscalingRatio = () => {
    const img = imgRef.current;
    if (img && img.naturalWidth && img.clientWidth) {
      const downscalingRatio = img.naturalWidth / img.clientWidth;
      setDownscalingRatio(downscalingRatio);
    }
  };

  const handleResize = () => {
    if (resizeTimeout.current) {
      clearTimeout(resizeTimeout.current);
    }
    resizeTimeout.current = setTimeout(() => {
      calculateDownscalingRatio();
    }, 100);
  };

  const trackScalingRatioImage = () => {
    calculateDownscalingRatio();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
      }
    };
  };

  useEffect(() => {
    const img = imgRef.current;
    if (img) {
      img.onload = trackScalingRatioImage;
    }
  }, [imageType, imageUrls, imgRef.current]);

  useEffect(() => {
    const images = getImages();
    setPossibleImages(images);
    setImageType(images[0]);
  }, [props.data]);

  useEffect(() => {
    retrieveImageUrls(possibleImages, props.data);
  }, [possibleImages, props.data]);

  const getImageCyclingButton = () => {
    if (!imageType) {
      return '';
    }
    return <Button size="small" color="primary" onClick={handleClick} variant="outlined">
      {t(imageType.toLowerCase() + '_image')}
    </Button>;
  };

  const getScheduleAsJobButton = () => {
    if (clickedScheduleOneJob) {
      return '';
    }
    if (props.data?.status === 'created') {
      return '';
    }
    if (!config?.media_card_metrics.includes('rerun_button') && !['team', 'admin'].includes(user?.level)) {
      return '';
    }
    return <Button size="small" color="primary" variant="outlined"
      onClick={handleClickScheduleOneJob} id='analyze-again-button'>
            Analyze Again
    </Button>;
  };

  const getResetBatchesButton = () => {
    if (clickedResetBatches) {
      return '';
    }
    if (!config?.media_card_metrics.includes('reset_batches_button')) {
      return '';
    }
    if (props.data?.status === 'created') {
      return '';
    }
    return <Button size = "small" color = "primary" variant = "outlined"
      onClick = {() => handleClickResetBatches(props.data._id)}>
            Reset Batches
    </Button>;
  };

  const getCopySnapshotButton = () => {
    if (!config?.media_card_metrics.includes('copy_snapshot_button')) {
      return '';
    }
    const targetClient = config?.copy_snapshot_to_client;
    return <CopySnapshot targetClient={targetClient} snapshot={props.data} />;
  };

  const addToCartButton = () => {
    if (isAlreadyInCart(props.data._id)) {
      return <ConstructionAddedIcon color="black" onClick={() => removeFromCart(props.data._id)} />;
    }
    return <ConstructionAddIcon onClick={() => addToCart(props.data)} />;
  };

  const getImages = () => {
    let images;
    if (!props.data) {
      return [imageType.RAWSD];
    }
    const client = props.data.client;

    if (client === undefined) {
      return [];
    }
    images = config?.primary_images;
    if (images === null) {
      images = ['rawSD'];
      return images;
    }
    if (props.data.status === 'analyzed') {
      images = config?.analyzed_images;
      return images;
    }
    if (Object.keys(props.data.data.images).includes('cropped') && props.data.data.images.cropped) {
      images = ['cropped'];
      return images;
    }
    return images;
  };

  const getTitle = (instance, snapshot) => {
    if (instance === '' || !instance) {
      return '';
    }
    return t('Capture date') + ': ' + getReadible(new Date(snapshot.collection_date));
  };

  const getReadible = (dateObject, includeTime = false) => {
    const monthNames = t('AllMonths', {returnObjects: true});
    const month = monthNames[dateObject.getMonth()];
    const day = String(dateObject.getDate()).padStart(2, '0');
    const year = dateObject.getFullYear();
    let output = day + ' ' + month + ' ' + year;
    if (includeTime) {
      output += ' ' + dateObject.toTimeString();
      output = output.substring(0, output.indexOf('(') - 1);
    }
    return output;
  };

  const getSnapshotGeneralInfo = (data) => {
    return <div>
      <Typography variant="body2"
        color="textSecondary">{t('Last modified') + ': ' + getReadible(new Date(data.modified))}</Typography>
      <Typography variant="body2"
        color="textSecondary">{t('Capture date') + ': ' + getReadible(new Date(data.collection_date))}</Typography>
      <Typography variant="body2"
        color="textSecondary">{t('Creation date') + ': ' + getReadible(new Date(data.created), true)}</Typography>
    </div>;
  };

  const getCropInfo = (data, props) => {
    if (!ContainsData.checkNested(data, 'crop')) {
      return null;
    }
    return <div><Typography variant="body2" color="textSecondary"> {t('Crop') + ': ' + data.crop}</Typography></div>;
  };

  const getFailureMessage = (data) => {
    let message = data?.failure?.message;
    if (!message) {
      return null;
    }
    const key = Object.keys(config?.error_messages_mapping || {}).find((key) => message.includes(key));
    if (key !== undefined) {
      message = t('Reason of failure') + ': ' + config.error_messages_mapping[key];
    } else if ((config?.visible_error_messages || []).some((item) => message.includes(item))) {
      message= t('Reason of failure') + ': ' + message;
    } else {
      message = t('Technical error, contact ADI for more information') + ': \n ' + message;
    }
    return <div><Typography variant="body2" color="textSecondary">
      {message}</Typography></div>;
  };

  const getSpeed = (data, props) => {
    const speed = data?.data?.position_id?.speed;
    if (speed === undefined) {
      return null;
    }
    const roundedSpeed = Math.round(speed * 60);
    return <div><Typography variant="body2" color="textSecondary">
      {t('System speed') + ': ' + roundedSpeed + ' m/min'}</Typography></div>;
  };

  const getLeafDimensionText = (plant) => {
    let averageLength = 0;
    let averageWidth = 0;
    const numberOfPlants = plant['leaves'].length;

    for (let i = 0; i < numberOfPlants; i++) {
      averageLength += plant['leaves'][i].length;
      averageWidth += plant['leaves'][i].width;
    }

    averageLength = (averageLength / numberOfPlants).toFixed(3);
    averageWidth = (averageWidth / numberOfPlants).toFixed(3);

    return 'Average Leaf Dimensions: ' + averageLength + ' cm x ' + averageWidth + ' cm';
  };

  const getPlantInfo = (plant) => {
    if (plant === undefined) {
      return <></>;
    }
    return <div>
      {plant.hasOwnProperty('height') ?
                <Typography variant="body2" color="textSecondary">
                    Highest point: {Math.round(plant.height * 100)} cm</Typography> :
                <></>
      }
      {plant.hasOwnProperty('emerging_leaf_point_height') ?
                <Typography variant="body2" color="textSecondary">
                    Center point height: {Math.round(plant.emerging_leaf_point_height * 100)} cm</Typography> :
                <></>
      }
      {plant.hasOwnProperty('leaves') ?
                <Typography variant="body2" color="textSecondary">{getLeafDimensionText(plant)}</Typography> :
                <></>
      }
    </div>;
  };

  const showHarvestLondonDropdown = (analysisData) => {
    if (!ContainsData.checkNested(analysisData, 'plant_record_list') || selectedPlant === undefined) {
      return null;
    }
    const plants = analysisData.plant_record_list;
    return <div>
      <Typography variant="body2">Select a plant:</Typography>
      <Select value={selectedPlant} onChange={(event) => setSelectedPlant(event.target.value)}>
        {plants.map((plant, id) => <MenuItem key={id + '_menu_item'} value={id}>{plant.plant_id}</MenuItem>)}
      </Select>
      {getPlantInfo(plants[selectedPlant])}
    </div>;
  };

  const showPlantsDropdown = (analysisData) => {
    if (!ContainsData.checkNested(analysisData, 'plant_record_list') || selectedPlant === undefined) {
      return null;
    }
    const plants = analysisData.plant_record_list;
    const plant = plants.find((plant) => plant.plant_id === selectedPlant);
    if (plants.length === 0) {
      return null;
    }
    return <div>
      <Typography variant="body2"
        color="textSecondary"><b>Selected Plant Details:</b></Typography>
      <FormControl style={{width: '15%', marginTop: 15, marginBottom: 15}} variant='outlined'>
        <InputLabel id='select-plant-label'>Select Plant</InputLabel>
        <Select value={selectedPlant} onChange={(event) => setSelectedPlant(event.target.value)}
          labelId='select-plant-label'label="Select Plant">
          {plants.map((plant, id) => <MenuItem key={id + '_menu_plant_item'} value={plant.plant_id}>
            {plant.plant_id}</MenuItem>)}
        </Select>
      </FormControl>
      <Typography variant="body2" color="textSecondary">
        Flower size: {plant?.flower_size_classification} / 5
        (average {SimpleCalculations.calculateAverageBudSize(plant)} cm)
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Plant size: {plant?.size_classification} / 5
        (width {SimpleCalculations.calculateAveragePlantDimension(plant)} cm)
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Plant height: {(plant?.height * 100).toFixed(1)} cm
      </Typography>
    </div>;
  };

  const getSprayStatus = (snapshotData) => {
    if (!ContainsData.checkNested(snapshotData.data, 'spray_status')) {
      return null;
    }
    let sprayMessage = t('Is spraying') + ': ';
    if (snapshotData?.data?.spray_status?.is_spraying) {
      sprayMessage += t('Yes');
    } else {
      sprayMessage += t('No');
    }
    return <div>
      <Typography variant="body2"
        color="textSecondary"><b>System Status:</b></Typography>
      <Typography variant="body2" color="textSecondary">{sprayMessage}</Typography>
    </div>;
  };

  const getAnnotatePlant = (snapshotData) => {
    if (!ContainsData.checkNested(snapshotData, 'data', 'analysis_data', 'plant_record_list')) {
      return null;
    }
    const plantRecordList = snapshotData.data.analysis_data.plant_record_list;
    const plantRecordListDiseases = plantRecordList.filter(
        (plantRecord) => plantRecord?.disease_classifications?.length > 0);
    const plantIds = plantRecordListDiseases.map((plantRecord) => plantRecord.plant_id);
    return <PlantAnnotationPanel allPlantIds={plantIds} />;
  };

  const showCaptureTimeOfDay = (collectionDate) => {
    const timeOfDay = collectionDate.split('T')[1];
    const hoursMinutesSeconds = timeOfDay.split('.')[0];

    return <Grid>
      <Typography variant="body2" color="textSecondary">Capture time: {hoursMinutesSeconds} UTC</Typography>
    </Grid>;
  };

  const showSystemEvaluationResults = (analysisData, pipeToSensorDistance) => {
    if (!ContainsData.checkNested(analysisData, 'camera_calibration', 'checks')) {
      return null;
    }
    const checks = analysisData.camera_calibration.checks;
    return <SystemEvaluationResults checks={checks} pipeToSensorDistance={pipeToSensorDistance}/>;
  };

  const getSubTitle = (user, data) => {
    if (user === '' || data.client === undefined || config === null) {
      return t('Missing data');
    }
    const toShow = config.media_card_metrics;
    const details = [];
    const analysisData = data.data.analysis_data;
    for (let i = 0; i < toShow.length; i++) {
      if (Object.values(DisplayMetric).includes(toShow[i])) {
        details.push(displayMetricFunction[toShow[i]](analysisData, t, props.data));
      }
      if (toShow[i] === 'capture_time_of_day') {
        details.push(showCaptureTimeOfDay(data.collection_date));
      }
      if (toShow[i] === 'chrysanthemum_plants_dropdown') {
        details.push(showPlantsDropdown(analysisData));
      }
      if (toShow[i] === 'harvest_london_dropdown') {
        details.push(showHarvestLondonDropdown(analysisData));
      }
      if (toShow[i] === 'snapshot_info') {
        details.push(getSnapshotGeneralInfo(data));
      }
      if (toShow[i] === 'crop') {
        details.push(getCropInfo(data));
      }
      if (toShow[i] === 'system_speed') {
        details.push(getSpeed(data, props));
      }
      if (toShow[i] === 'failure_message') {
        details.push(getFailureMessage(data));
      }
      if (toShow[i] === 'spray_status') {
        details.push(getSprayStatus(data));
      }
      if (toShow[i] === 'annotate_plant') {
        details.push(getAnnotatePlant(data));
      }
      if (toShow[i] === 'system_evaluation_results') {
        details.push(showSystemEvaluationResults(analysisData, data.data.pipe_to_sensor_distance));
      }
    }

    details.push(<br />);

    if (config.media_card_grid !== undefined && config.media_card_grid === true) {
      return <div>
        <Grid container className={classes.fullWidth}>
          {details}
        </Grid>
        <br/>
      </div>;
    }

    return details;
  };

  const getAdvancedDebugInformation = (instance, snapshot) => {
    const id = snapshot['_id'];
    return (<div>
      <Typography variant="body2"
        color="textSecondary"><b>{t('Detailed Information') + ': '}</b></Typography>
      {instance ? <Typography variant="body2"
        color="textSecondary">{'Instance ID: ' + instance['_id']}</Typography> : ''}
      <Typography variant="body2"
        color="textSecondary">
        {t('Snapshot ID') + ': '}
        <Link to={{pathname: `/${currentClient}/snapshots/${id}`}}>
          {id}
        </Link>
      </Typography>
    </div>);
  };


  const retrieveImageUrls = (neededImages, snapshot) => {
    if (!snapshot?.data?.hasOwnProperty('images')) {
      return;
    }
    const images = snapshot['data']['images'];
    for (const key in images) {
      if (!images.hasOwnProperty(key)) {
        continue;
      }
      if (!neededImages.includes(key)) {
        continue;
      }
      const image = images[key];
      ApiClient.downloadImage(snapshot.client, image).then((url) => {
        setImageUrls((prevImageUrls) => ({...prevImageUrls, [key]: url}));
      });
    }
  };

  const handleClick = () => {
    const nextPage = (imagePage + 1) % possibleImages.length;
    setImageType(possibleImages[nextPage]);
    setImagePage(nextPage);
  };

  const handleClickScheduleOneJob = () => {
    const succeeded = ApiClient.scheduleOneJob(props.data?._id);
    if (succeeded) {
      setClickedScheduleOneJob(true);
      props.changeSnapshotStatus(props.data?._id, 'created');
    }
  };

  const handleClickResetBatches = (snapshotId) => {
    setClickedResetBatches(true);
    ApiClient.findMatchingInstance(snapshotId).then((instance) => {
      if (instance === null) {
        return;
      }
      ApiClient.removeInstanceById(instance._id);
    });
  };


  const isImageUrlLoaded = () => {
    if (imageUrls && imageType) {
      return imageUrls[imageType] !== undefined;
    }
    return false;
  };


  const getImage = () => {
    return (
      <>
        <MapInteractionCSS minScale={1} maxScale={10}>
          <div style={{position: 'relative'}} className={status === 'deleted' ? classes.crossedOut : classes.fullWidth}>
            <img src={imageUrls[imageType]} alt={'Selected'}
              ref={imgRef} className={classes.fullWidth} />
            {config['plant_overlay'] ?
            <PlantOverlay snapshot={props.data} downscalingRatio={downscalingRatio}
              setSelectedPlant={setSelectedPlant}/> : ''}
            {config['crosshair'] | showCrosshair ?
            <Crosshair text={props.data.data?.position_id?.total_distance} /> : ''}
          </div>
        </MapInteractionCSS>
      </>
    );
  };

  const getActions = (user) => {
    return (
      <>
        {imageUrls ? getImageCyclingButton() : ''}
        {getScheduleAsJobButton()}
        {getResetBatchesButton()}
        {getCopySnapshotButton()}
        {isCartVisible() ? addToCartButton() : ''}
      </>
    );
  };

  const showDetails = () => {
    if (detailsBox === false) {
      setDetailsBox(true);
    } else {
      setDetailsBox(false);
    }
  };


  return (
    <div>
      <Card className={status === 'deleted' ? classes.deletedCard : classes.root}>
        <PlantSelectionProvider>
          <AnnotationProvider snapshot={props.data}>
            <CardActions>
              {getActions()}
            </CardActions>
            {!props.annotations && isImageUrlLoaded() ? getImage() : ''}
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {getTitle(props.instance, props.data)}
              </Typography>
              <div className={classes.mediaContainer}>
                <div>
                  {getSubTitle(user, props.data)}
                  {user.level === 'admin' || user.level === 'team' ?
                      getAdvancedDebugInformation(props.instance, props.data) :
                      ''}
                </div>
                <RegularSpacer size={2} />
                {checkVersionSafely(props.data) ?
                                <div>
                                  <Typography variant="body2" color="textSecondary">
                                    Version: {props.data.data.analysis_data.version}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary">
                                    Version date: {props.data.data.analysis_data.version_date}
                                  </Typography>
                                </div> :
                                <></>}
              </div>
            </CardContent>
            <CardActions>
              {
                    user.level === 'admin' || user.level === 'team' ?
                        <Button onClick={showDetails} size="small" color="primary">Details</Button> : ''
              }
            </CardActions>
          </AnnotationProvider>
        </PlantSelectionProvider>
      </Card>
      {detailsBox ? <SnapshotDetailsCard snapshot={props.data.data} shorten={false} /> : ''}
    </div>
  );
}


export default MediaCard;
