import {ApiClient} from '../../Utils/ApiClient';

export const DATABASE_DOCUMENTS = {
  'Projects': {
    'name': 'Projects',
    'route': '/project',
  },
  'Analysis configs': {
    'name': 'Analysis configs',
    'route': '/analysis/config',
  },
  'Harvest London batches': {
    'name': 'Harvest London batches',
    'route': '/harvestLondonBatch',
  },
  'Lane information QMS data': {
    'name': 'Lane information QMS data',
    'route': '/laneBatchInformation',
  },
  'Plant annotations': {
    'name': 'Plant annotations',
    'route': '/plantAnnotation',
  },
  'Daily reports': {
    'name': 'Daily reports',
    'route': '/daily-reports',
  },
  'Daily report configs': {
    'name': 'Daily report configs',
    'route': '/daily-reports/config',
  },
};

export class DatabaseDocument {
  constructor(data) {
    this.id = data._id;
    this.title = this.getTitle(data);
    this.body = data;
  }

  getTitle(data) {
    if (data?.name !== undefined) {
      return data.name;
    }
    if (data?.batch !== undefined) {
      return data.batch;
    }
    if (data?.snapshot_id !== undefined) {
      return data.snapshot_id;
    }
    return data._id;
  }
}


export const getDocuments = async (documentName, query) => {
  const queryDict = JSON.parse(query);
  const data = await ApiClient.getGeneric(DATABASE_DOCUMENTS[documentName].route, queryDict);
  const result = [];
  for (const documentData of data) {
    const document = new DatabaseDocument(documentData);
    result.push(document);
  }
  return result;
};
