import React, {useState, useEffect} from 'react';
import {ApiClient} from '../../Utils/ApiClient';


export const AnnotationImagePopUp = ({isVisible, annotation, position}) => {
  const [src, setSrc] = useState(null);
  useEffect(() => {
    if (annotation) {
      ApiClient.getImageUrlAnnotation(annotation).then((url) => {
        setSrc(url);
      });
    }
  }, [annotation]);
  const style = {
    display: isVisible ? 'block' : 'none',
    position: 'fixed',
    top: `${position.y + 10}px`,
    left: `${position.x + 30}px`,
    zIndex: 1000,
    border: '1px solid black',
    PointerEvent: 'none',
  };
  if (!src) {
    return <h3 style={style}>Loading...</h3>;
  }
  return <img src={src} width={300} alt="Pop-up" style={style} />;
};
