import React, {createContext, useContext, useEffect, useState} from 'react';
import {ApiClient} from '../../Utils/ApiClient';
import {isPointInBox} from '../../Utils/AnnotationHelpers';
const AnnotationContext = createContext();

export function AnnotationProvider({children, snapshot}) {
  const [annotations, setAnnotations] = useState([]);
  const [possibleAnnotationPlantId, setPossibleAnnotationPlantId] = useState(null);
  const [possibleAnnotationPlant, setPossibleAnnotationPlant] = useState(null);
  const [alreadyAnnotatedPlantIds, setAlreadyAnnotatedPlantIds] = useState([]);
  const [showPlantOverlay, setShowPlantOverlay] = useState(false);
  const [pendingAnnotations, setPendingAnnotations] = useState([]);

  const plants = snapshot?.data?.analysis_data?.plant_record_list ?? [];

  useEffect(() => {
    if (!snapshot) {
      return;
    }
    loadAnnotations();
  }, [snapshot]);

  useEffect(() => {
    if (!snapshot || !possibleAnnotationPlantId) {
      return;
    }
    const plant = plants.find((plant) => plant.plant_id === possibleAnnotationPlantId);
    setPossibleAnnotationPlant(plant);
  }, [snapshot, possibleAnnotationPlantId]);

  useEffect(() => {
    if (!annotations) {
      return;
    }
    const ids = [];
    for (const plant of plants) {
      const possibleAnnotation = getPlantAnnotation(plant);
      if (!possibleAnnotation) {
        continue;
      }
      ids.push(plant.plant_id);
    }
    setAlreadyAnnotatedPlantIds(ids);
  }, [annotations]);


  const loadAnnotations = async () => {
    setAnnotations(await ApiClient.findAnnotations(snapshot.client,
        {'snapshot_id': snapshot._id}));
  };

  const generatePlantAnnotation = (plantIds) => {
    ApiClient.generatePlantAnnotation(snapshot._id, plantIds).then((result) => {
      setPossibleAnnotationPlantId(null);
      setPossibleAnnotationPlant(null);
      if (result?.status == 202) {
        setPendingAnnotations((prevPendingAnnotations) => [...prevPendingAnnotations, ...plantIds]);
      }
    });
  };

  const getPlantAnnotation = (plant) => {
    for (const annotation of annotations) {
      if (isPointInBox(annotation.center_coordinate, plant.center_coordinate, plant.pixel_dimensions)) {
        return annotation;
      }
    }
    return null;
  };

  return (
    <AnnotationContext.Provider
      value={{annotations, generatePlantAnnotation, setPossibleAnnotationPlantId,
        possibleAnnotationPlant, getPlantAnnotation, alreadyAnnotatedPlantIds,
        showPlantOverlay, setShowPlantOverlay, pendingAnnotations}}>
      {children}
    </AnnotationContext.Provider>
  );
}

export function useAnnotation() {
  return useContext(AnnotationContext);
}
