import React from 'react';

const TriangleIndicator = ({cellTopCenter}) => {
  // triangle points

  // first point is the top center of the cell
  const x1 = cellTopCenter.x;
  const y1 = cellTopCenter.y;
  // second point is top left of the first point
  const x2 = x1 - 20;
  const y2 = y1 - 30;
  // third point is top right of the first point
  const x3 = x1 + 20;
  const y3 = y1 - 30;
  return (
    <g>
      <polygon points={`${x1},${y1} ${x2},${y2} ${x3},${y3}`}
        stroke='black' strokeWidth='1' style={{fill: 'red'}}
      />
      <animateTransform attributeName="transform" attributeType="XML"
        type="translate" from="0 0" to="0 -10" dur="1s" repeatCount="indefinite"/>
    </g>
  );
};

export default TriangleIndicator;
