import React, {useContext} from 'react';
import {OverviewMapContext} from '../../contexts/OverviewMapContext';
import './overview-map.css';

// Renders a snapshot card with the snapshot image and id
const SnapshotsCard = ({snapshot, color}) => {
  const {snapshotImages, currentClient} = useContext(OverviewMapContext);


  const truncate = (str, n) => {
    return (str.length > n) ? str.slice(0, n-1) + '...' : str;
  };

  const viewSnapshot = () => {
    // open a new tab when the snapshot is clicked
    window.open(`/${currentClient}/images/${snapshot._id}`, '_blank');
  };

  return (
    <div className='popup-card-item' style={{backgroundColor: color}} onClick={viewSnapshot}>
      {snapshotImages[snapshot._id] ? (
         <img src={snapshotImages[snapshot._id]} alt='snapshot'/>
        ) : (
          <div className='snapshot-image-loader'></div>
        )
      }
      <p className='popup-card-item-title'>{truncate(snapshot._id, 14)}</p>
    </div>
  );
};

export default SnapshotsCard;
