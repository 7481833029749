import {getBatchFromSnapshot} from '../../Utils/SnapshotHelper';

export default class SnapshotFilter {
  constructor(date = null, client = null, lane = '', subLane = '', batch = '', author = null, filterByButton = '') {
    this.date = date;
    this.client = client;
    this.lane = lane;
    this.subLane = subLane;
    this.batch = batch?.toLowerCase();
    this.author = author?.toLowerCase();
    this.filterByButton = filterByButton;
  }

  filter(snapshots, skipLocationFilter = false) {
    if (this.lane !== '' && !skipLocationFilter) {
      snapshots = snapshots.filter((snapshot) => snapshot.data.lane === this.lane);
      if (this.subLane !== '') {
        snapshots = snapshots.filter((snapshot) => snapshot.data.sub_lane === this.subLane);
      }
    }
    if (this.filterByButton !== undefined) {
      snapshots = snapshots.filter((snapshot) => this.filterByButtonName(snapshot, this.filterByButton));
    }

    if (this.batch !== null && this.batch !== '') {
      snapshots = snapshots.filter((snapshot) => this.filterByBatch(snapshot, this.batch));
    }

    return snapshots;
  }


  hasDiseases(snapshot) {
    if (snapshot.data.analysis_data) {
      for (const plant in snapshot.data.analysis_data.plant_record_list) {
        if (snapshot.data.analysis_data.plant_record_list[plant].healthy === 0) {
          return true;
        }
      }
    }
    return false;
  }

  filterByBatch(snapshot, value) {
    const snapshotBatchId = getBatchFromSnapshot(snapshot).toLowerCase();
    return snapshotBatchId.includes(value.toLowerCase());
  }

  filterByButtonName(snapshot, value) {
    if (value === 'All') {
      return true;
    }
    if (value === 'Failure') {
      return snapshot.status === 'failed';
    }
    if (value === 'Disease') {
      return this.hasDiseases(snapshot);
    }
    if (value === 'Discarded') {
      return snapshot.status === 'discarded';
    }
    if (value === 'Good') {
      return snapshot.status === 'analyzed';
    }
    return false;
  }
}
