import React from 'react';

// This component displays the loading animation for a lane
const LaneLoading = ({laneWidth, laneHeight, laneRectX, laneRectY}) => {
  const circleRadius = 6; // Radius of the circles
  const circleSpacing = 20; // Spacing between circles

  // Calculate the x and y coordinates of the animated circles
  const circle1X = laneRectX + (laneWidth / 2) - circleSpacing;
  const circle2X = laneRectX + (laneWidth / 2);
  const circle3X = laneRectX + (laneWidth / 2) + circleSpacing;
  const circleY = laneRectY + (laneHeight / 2);
  return (
    <g>
      {/* Circles */}
      <circle fill="#000" stroke="none" cx={circle1X} cy={circleY} r={circleRadius}>
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill="#000" stroke="none" cx={circle2X} cy={circleY} r={circleRadius}>
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
      <circle fill="#000" stroke="none" cx={circle3X} cy={circleY} r={circleRadius}>
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.3"
        />
      </circle>
    </g>
  );
};

export default LaneLoading;
