import React from 'react';
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import CardContent from '@material-ui/core/CardContent';
import {UserContext} from '../../contexts/userContext';
import {ApiClient} from '../../Utils/ApiClient';
import {useState} from 'react';
import {useContext} from 'react';
import {makeStyles} from '@material-ui/core';
import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  divPlacement: {
    width: '50%',
    margin: '0 auto',
    marginTop: '30px',
  },
  card: {
    padding: 25,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    marginBottom: theme.spacing(2),
  },
}));

function CommentsPage() {
  const [comments, setComments] = useState([]);

  const classes = useStyles();
  const {currentClient} = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    fetchAllComments();
    history.push((`/${currentClient || 'admin'}/comments`));
  }, [currentClient]);

  // TODO: sorting and manipulating data is way faster server-side than client side.
  // This entire function will crash, burn and fail once a few thousand comments passed to it
  // This should not be done client-side!
  const sortComments = (comments) => {
    comments.sort((a, b) => (new Date(a.created) < new Date(b.created)) ? 1 : -1);
    const sortedComments = {};
    comments.forEach((comment) => {
      if (sortedComments[comment['snapshot_id']]) {
        sortedComments[comment['snapshot_id']].push(comment);
      } else {
        sortedComments[comment['snapshot_id']] = [comment];
      }
    });
    return sortedComments;
  };

  const fetchAllComments = () => {
    ApiClient.findAllCommentsForClient(currentClient).then((comments) => {
      setComments(sortComments(comments));
    });
  };


  const singleEntry = (key) => {
    return (
      <>
        <Typography gutterBottom variant="h5" component="div">
          <Link
            to={{
              pathname: `/${currentClient}/snapshots/${key}`,
            }}
          >
            {key}
          </Link>
        </Typography>
        {
          comments[key].map(
              (item) =>
                <Typography variant="body2" color="textSecondary" key={item._id}>
                  <b>{item.username}</b> commented:<br></br><b>{item.message}</b>
                </Typography>,
          )
        }
      </>
    );
  };

  const buildCommentEntries = () => {
    return (
      <Card className={classes.card}>
        <CardContent>
          <FormControl className={classes.clientCard}>
            {
              Object.entries(comments)
                  .map(([key, value]) =>
                    singleEntry(key),
                  )
            }
          </FormControl>
        </CardContent>
      </Card>
    );
  };

  return (
    <Container className={classes.container}>
      {buildCommentEntries()}
    </Container>
  );
}

export default CommentsPage;
