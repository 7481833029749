import React, {useState, useEffect, useContext} from 'react';
import Typography from '@material-ui/core/Typography';
import {ApiClient} from '../../Utils/ApiClient';
import {useTranslation} from 'react-i18next';
import {Card, Select, MenuItem} from '@material-ui/core';
import {DateRangePickerComponent} from '../../Utils/DatePickers';
import {SubmitButton, TopText, UserInputs} from '../StyledComponents/BasicStyles';
import {UserContext} from '../../contexts/userContext';
import clientConfig from './ClientToDevice.json';
import {CenteredCardContent} from '../StyledComponents/BasicStyles';
import {getDatePlusInterval} from '../../Utils/DateUtils';
import {RegularSpacer} from '../BasicComponents/Spacers';
import {useHistory} from 'react-router-dom';
import ConfirmDialog from './ConfirmDialog';

function DailyReportPage() {
  const {t} = useTranslation();
  const {user, getUserConfig, currentClient} = useContext(UserContext);

  const [fromDate, setFromDate] = useState(() => new Date());
  const [toDate, setToDate] = useState(() => getDatePlusInterval(1));
  const [email, setEmail] = useState('');
  const [highlightDates, setHighlightDates] = useState(new Set());
  const [emailError, setEmailError] = useState('');
  const [resultMessage, setResultMessage] = useState('');
  const [confimOpen, setConfirmOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (currentClient) {
      daysWithUploads(currentClient, 'analyzed');
    }
    history.push((`/${currentClient || 'admin'}/daily-report`));
  }, [currentClient]);

  const daysWithUploads = (client, status) => {
    ApiClient.findDaysWithUploads(client, status).then((days) => {
      setHighlightDates(days);
    });
  };

  const handleChangeFromDate = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(0, 0, 0, 0);

    setFromDate(dateObject.toISOString());
  };

  const handleChangeToDate = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(23, 59, 59, 0);

    setToDate(dateObject.toISOString());
  };

  const sendReport = () => {
    ApiClient.sendDailyReport(currentClient, fromDate, toDate, email, clientConfig.clientToDevice[currentClient])
        .then((response) => {
          let message = 'Report requested failed, please contact ADI.';
          if (response.status === 202) {
            message = t('Report successfully requested, it can take a few minutes to arrive.');
          }
          setResultMessage(message);
        });
  };

  const sendDailyReport = () => {
    if (email === '') {
      setEmailError(t('Please set an email to send the report to'));
      return;
    }
    const startDate = new Date(fromDate);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(toDate);
    ApiClient.findSnapshotsByClient(startDate, endDate, null, null, false, currentClient)
        .then((snapshots) => {
          if (snapshots.length === 0) {
            setResultMessage(t('No snapshots found for this period.'));
            return;
          } else {
          // check how many snapshots are created vs how many are analyzed
            const createdSnaphots = snapshots.filter((snapshot) => snapshot.status === 'created');
            const analyzedSnaphots = snapshots.filter((snapshot) => snapshot.status === 'analyzed');
            if (createdSnaphots.length > 0) {
              setDialogMessage(`${createdSnaphots.length} snapshots out of 
                ${analyzedSnaphots.length + createdSnaphots.length} snapshots are not yet analyzed,
                  do you want to proceed with sending the report?`);
              setConfirmOpen(true);
            } else {
              sendReport();
            }
          }
        });
  };

  const getAllowedEmails = () => {
    let emails = getUserConfig(true)?.daily_report?.allowed_emails;
    if (!emails) {
      return [];
    }
    if (['team', 'admin'].includes(user?.level)) {
      const adiEmails = getUserConfig(false, 'AgriDataInnovations')?.daily_report?.allowed_emails;
      emails = emails.concat(adiEmails);
    }
    return emails;
  };

  return (<>
    <UserInputs>
      <Card>
        <CenteredCardContent maxWidth={800}>
          <TopText style={{'maxWidth': '800px'}} >{t('Daily report page explained')}</TopText>
          <RegularSpacer spacing={4}/>
          <DateRangePickerComponent
            changeStartDate={handleChangeFromDate}
            changeEndDate={handleChangeToDate}
            highlightDates={highlightDates}
          />
          <TopText style={{'maxWidth': '800px'}} >{t('Email dropdown message')}</TopText>
          <TopText>{t('Email to send it to')}</TopText>
          <Select
            labelId="email-picker"
            id="email-picker-select"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}>
            {getAllowedEmails(currentClient).map((item) =>
              <MenuItem key={item} value={item}>{item}</MenuItem>,
            )}
          </Select>
          <Typography>{emailError}</Typography>
          <br />
          <SubmitButton onClick={sendDailyReport}>{t('Mail report')}</SubmitButton>
          <br />
          <Typography>{resultMessage}</Typography>
          <ConfirmDialog
            title={dialogMessage}
            open={confimOpen}
            setOpen={setConfirmOpen}
            onConfirm={sendReport}
            setTitle={setDialogMessage}
          />
        </CenteredCardContent>
      </Card>
    </UserInputs>
  </>);
}

export default DailyReportPage;
