import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {ApiClient} from '../../Utils/ApiClient';
import TraysWithBatches from './TraysWithBatches';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    height: '100%',
    width: '100%',
  },
}));

function TrayBatchesMap({greenhouse, greenhouseStats}) {
  if (!greenhouse) {
    return null;
  };
  const [batches, setBatches] = useState(null);
  const [trays, setTrays] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    setBatches(null);
    setTrays(null);
    if (greenhouse) {
      fetchBatches();
    }
  }, [greenhouse]);

  useEffect(() => {
    if (batches && batches.length > 0) {
      setTrays(getTraysFromBatches());
    }
  }, [batches]);


  const getTraysFromBatches = () => {
    const traysWithData = {};
    if (!batches) {
      return null;
    }
    for (const batch of batches) {
      const inGreenhouseLocations = batch.batch_information.in_greenhouse_locations;
      for (const location of inGreenhouseLocations) {
        const trayNumber = location.container.container_location.tray_id;
        if (!traysWithData[trayNumber.toString()]) {
          traysWithData[trayNumber] = [];
        }
        traysWithData[trayNumber].push({batchOrder: location.coordinates.order, batchId: batch.batch_id,
          batchData: location.container.container_batch_information});
      }
    }
    return traysWithData;
  };

  const fetchBatches = () => {
    ApiClient.findBatches(greenhouse).then((batches) => {
      setBatches(batches);
    });
  };

  return (
    <div className={classes.container}>
      {batches && trays && greenhouseStats ? <TraysWithBatches traysWithData={trays}
        greenhouseStats={greenhouseStats}/> : <h1>No Batches Found</h1>}
    </div>
  );
};

export default TrayBatchesMap;
