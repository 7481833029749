import TextField from '@material-ui/core/TextField';
import SnapshotCard from './SnapshotCard';
import SnapshotDetailsCard from './SnapshotDetailsCard';
import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/styles';
import {useTranslation} from 'react-i18next';
import {ApiClient} from '../../Utils/ApiClient';
import {Link} from 'react-router-dom/cjs/react-router-dom';
import {useParams, useLocation} from 'react-router-dom';
import {useContext} from 'react';
import {UserContext} from '../../contexts/userContext';
import {useHistory} from 'react-router-dom';
import {Button} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  grid: {
    margin: 0,
    width: '100%',
  },
  card: {
    minHeight: 100,
    padding: 25,
  },
  container: {
    background: '#ffffff',
    zIndex: 2,
    padding: theme.spacing(5),
  },
  header: {
    textAlign: 'left',
    marginLeft: theme.spacing(2),
    marginBottom: 10,
    color: theme.palette.primary.dark,
  },
  comment: {
    textAlign: 'left',
    marginLeft: theme.spacing(2),
    marginBottom: 10,
    marginTop: 20,
    color: theme.palette.primary.dark,
  },
  commentField: {
    marginTop: 15,
    width: '100%',
  },
  sendButton: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  jumpButtonContainer: {
    marginTop: theme.spacing(3),
    textAlign: 'left',
  },
}));


function SnapshotPage(props) {
  const {t} = useTranslation();
  const classes = useStyles();
  const [snapshot, setSnapshot] = useState('');
  const [snapshotId, setSnapshotId] = useState(null);
  const {setCurrentClient, currentClient} = useContext(UserContext);
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  useEffect(() => {
    if (params.snapshot_id) {
      loadURLParameters();
    }
  }, []);

  useEffect(() => {
    if (!params.snapshot_id) {
      history.push((`/${currentClient || snapshot.client}/snapshots`));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (currentClient !== params.client_name && currentClient) {
      history.push((`/${currentClient || snapshot.client}/snapshots`));
    }
  }, [currentClient]);

  useEffect(() => {
    if (!snapshotId) {
      return;
    }
    loadSnapshot(snapshotId);
  }, [snapshotId]);

  useEffect(() => {
    if (snapshot) {
      setSnapshotIdtoUrl();
    }
  }, [snapshot]);

  const setSnapshotIdtoUrl = () => {
    if (snapshot.client !== currentClient) {
      setCurrentClient(snapshot.client);
      history.push((`/${snapshot.client}/snapshots/${snapshotId}`));
    }
  };
  const clearSnapshotIdFromUrl = () => {
    history.push((`/${params.client_name}/snapshots`));
  };
  const loadURLParameters = () => {
    if (params.snapshot_id) {
      setSnapshotId(params.snapshot_id);
    } else {
      console.log('');
    }
  };


  const loadSnapshot = (snapshotId) => {
    ApiClient.findSnapshotById(snapshotId).then((result) => {
      if (result) {
        setSnapshot(result);
      } else {
        setSnapshot(null);
      }
    });
  };

  const handleTextChange = (event) => {
    if (!event.target.value) {
      clearSnapshotIdFromUrl();
      setSnapshotId(null);
    } else {
      setSnapshotId(event.target.value);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={5}>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={snapshotId ?? ''}
            onChange={handleTextChange}
            name="snapshot_id"
            label={t('Snapshot ID')}
            type="text"
            id="text"
          />
        </Grid>
        <Grid item xs={6}>
          {snapshot ?
          <div>
            <SnapshotCard snapshot={snapshot} data={[]}
              all_images={true}
              baseUrl={props.baseUrl} />
            <div className={classes.jumpButtonContainer}>
              <Link to={{pathname: `/${snapshot.client}/images/${snapshot._id}`}}>
                <Button variant="contained" color="primary">
                Jump to images page
                </Button>
              </Link>
            </div>
            <SnapshotDetailsCard snapshot={snapshot} data={[]}
              all_images={true} />
          </div> :
          ''}
        </Grid>
      </Grid>

    </div>
  );
}


export default SnapshotPage;
