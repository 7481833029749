import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Select, FormControl, InputLabel, MenuItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import 'date-fns';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  locationSelection: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  positionCard: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: 200,
  },
}));

const SnapshotSortByDate = ({sortSnapshotsBy, handleSortBy, snapshotRuns, selectedRun, handleRunSelect}) => {
  const {t} = useTranslation();
  const classes = useStyles();
  return (
    <Card style={{marginBottom: 15}}>
      <CardContent>
        <FormControl fullWidth className={classes.positionCard}>
          <InputLabel>{t('Sort By')}</InputLabel>
          <Select
            value={sortSnapshotsBy}
            label="SortBy"
            onChange={handleSortBy}
          >
            <MenuItem value={'distance'}>{t('Distance')}</MenuItem>
            <MenuItem value={'collection_date'}>{t('Collection Date')}</MenuItem>
          </Select>
        </FormControl>
        {snapshotRuns.length > 0 && sortSnapshotsBy === 'collection_date' &&
          <FormControl fullWidth className={classes.positionCard}>
            <InputLabel>{t('Select Run')}</InputLabel>
            <Select
              value={selectedRun}
              label="SelectRun"
              onChange={handleRunSelect}
            >
              {snapshotRuns.map((snapshot, index) =>
                <MenuItem key={index} value={index}>Run {index + 1}</MenuItem>,
              )}
            </Select>
          </FormControl>
        }
      </CardContent>
    </Card>
  );
};

export default SnapshotSortByDate;
