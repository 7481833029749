import React from 'react';
import Container from '@material-ui/core/Container';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ErrorsMoreDetails from './ErrorsMoreDetails';
import {DatePickerComponent} from '../../Utils/DatePickers';
import {UserContext} from '../../contexts/userContext';
import {ApiClient} from '../../Utils/ApiClient';
import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {useEffect} from 'react';


const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginRight: theme.spacing(1),
  },
  successfulLaneText: {
    color: theme.palette.primary.dark,
  },
  container: {
    padding: 0,
  },
  basicSpacing: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));


const ErrorList = ({sbris, listUtils}) => {
  const [successfulLanes, setSuccessfulLanes] = useState([]);
  const [lanes, setLanes] = useState({});
  const [highlightDates, setHighlightDates] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(listUtils['getTodayRounded']());

  const {currentClient} = useContext(UserContext);
  const {t} = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    daysWithUploads();
    retrieveSnapshots();
  }, [currentClient, date]);

  const handleChangeDate = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(0, 0, 0, 0);
    setSuccessfulLanes([]);
    setLanes({});
    setDate(dateObject);
  };

  const daysWithUploads = async () => {
    setHighlightDates(new Set());
    const status = !sbris ? 'analyzed' : null;
    const days = await ApiClient.findDaysWithUploads(currentClient, status);
    setHighlightDates(days);
  };

  const retrieveSnapshots = () => {
    setLoading(true);
    const startDate = date.toISOString();
    const d = new Date();
    d.setDate(date.getDate() + 1);
    const endD = new Date(d); // - 18 * 60000
    const endDate = new Date(endD).toISOString();

    ApiClient.findSnapshotsByClient(startDate, endDate, null, null, false, currentClient).then((snapshots) => {
      const lanes = snapshots ? listUtils['getLaneInfo'](snapshots) : [];
      setLanes(lanes);
      if (snapshots) {
        setSuccessfulLanes(getFullySuccessfulLanes(lanes));
      }
      setLoading(false);
    });
  };

  const getFullySuccessfulLanes = (data) => {
    const goodLanes = [];
    for (const [key, value] of Object.entries(data)) {
      if (value['failed'] === 0 && value['discarded'] === 0) {
        goodLanes.push(key);
      }
    }
    return goodLanes;
  };

  const generateMessageFullySuccessful = () => {
    let fullMessage = '';
    if (successfulLanes.length === 1) {
      fullMessage = t('Lane') + ' ' + successfulLanes[0] + ' ' + t('is fully successful') + '.';
    } else {
      fullMessage = t('Lanes') + ' ';
      for (let i = 0; i < successfulLanes.length; i++) {
        if (i === successfulLanes.length - 2) {
          fullMessage += successfulLanes[i] + ' ' + t('and') + ' ';
          continue;
        }
        if (i === successfulLanes.length - 1) {
          fullMessage += successfulLanes[i];
          continue;
        }
        fullMessage += successfulLanes[i] + ', ';
      }
      fullMessage += ' ' + t('are fully successful') + '.';
    }
    return (
      <Card className={classes.basicSpacing}>
        <CardContent>
          <Typography className={classes.successfulLaneText}>{fullMessage }</Typography>
        </CardContent>
      </Card>
    );
  };

  const generateMessageImperfectLanes = (key, value) => {
    if (value['failed'] > 0 || value['discarded'] > 0) {
      const fullMessage = t('Lane') + ' ' + key + ' ' + t('has') + ' ' + value['analyzed'] +
                ' ' + t('successfully analyzed snapshots, ') + value['discarded'] + ' discarded snapshots and ' +
                value['failed'] + ' ' + t('failed snapshots') + '.';
      return (
        <Card className={classes.basicSpacing}>
          <CardContent>
            <Typography variant="body2" color="secondary "key={key}>{fullMessage}</Typography>
            <ErrorsMoreDetails data={value['errors']}></ErrorsMoreDetails>
          </CardContent>
        </Card>
      );
    }
  };

  return (
    <Container className={classes.container}>
      <Card>
        <CardContent>
          <form className={classes.form} noValidate>
            <DatePickerComponent
              id="date"
              label="Date"
              highlightDates={highlightDates}
              changeDate={handleChangeDate}
            />
          </form>
        </CardContent>
      </Card>
      {loading ? <Typography>{t('Collecting the data...')}</Typography> : ''}
      {successfulLanes.length > 0 ? generateMessageFullySuccessful() : ''}
      {Object.entries(lanes).map(([key, value]) => generateMessageImperfectLanes(key, value))}
    </Container>
  );
};

export default ErrorList;
