
import {interpolateReds} from 'd3';

export default function generateValueBasedColor(value, minValue, maxValue,
    colorScale = interpolateReds) {
  const color = colorScale((value - minValue) / (maxValue - minValue));
  return color;
}


