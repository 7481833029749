import React, {useState} from 'react';
import {Typography, useTheme} from '@material-ui/core';
import {useEffect} from 'react';
import {ApiClient} from '../../Utils/ApiClient';

const BatchCard = ({locationId, distance}) => {
  const [batchExists, setBatchExists] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    loadBatchInformation(locationId);
  }, [locationId]);

  const loadBatchInformation = async (locationId) => {
    const result = await ApiClient.getLaneBatchInformation(locationId, 'Deliflor-1');
    if (!result || result.length === 0) {
      return;
    }
    console.log(result);
    setBatchExists(true);
  };

  const getText = () => {
    let text = '';
    text += 'Batch "' + locationId + '" Start distance ' + Math.round(distance * 100)/ 100 + 'm';
    if (batchExists) {
      text += ' - Batch in database.';
    }
    return text;
  };

  return (
    <div>
      <Typography style={{'color': theme.palette.primary.main}} >
        {getText()}
      </Typography>
    </div>
  );
};

export default BatchCard;
