

import React, {useState, useEffect, useContext, useRef} from 'react';
import clientConfig from '../../Config/clients.json';
import {DatePickerComponent} from '../../Utils/DatePickers';
import {ApiClient} from '../../Utils/ApiClient';
import {Bar, LineChart, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis, Line, Label, Text} from 'recharts';
import {Card, CardContent, Checkbox, Grid, useTheme} from '@material-ui/core';
import SpeedChart from './SpeedChart';
import {UserContext} from '../../contexts/userContext';
import DailyLineChart from './LineChart';

const allLabels = ['failed', 'created', 'deleted', 'discarded', 'analyzed', 'incomplete'];
const jobRanges = [30, 10 * 60];

function DashboardPage() {
  const theme = useTheme();
  const [data, setData] = useState(null);
  const [completedJobs, setCompletedJobs] = useState(null);
  const [jobsInQueue, setJobsInQueue] = useState(null);
  const [targetDateDayData, setTargetDateDayData] = useState(() => new Date());
  const [forceToday, setForceToday] = useState(true);
  const [lastDayData, setLastDayData] = useState(null);
  const {currentClient} = useContext(UserContext);
  const [averageUploadTime, setAverageUploadTime] = useState(0);
  const [isLineGraphLoading, setIsLineGraphLoading] = useState(true);

  const snapshotDataRef = useRef();

  useEffect(() => {
    snapshotDataRef.current = updateToCurrentMoment;
  });

  useEffect(() => {
    const queueInterval = setInterval(loadJobsInQueue, 60000);
    const jobsInterval = setInterval(loadCompletedJobs, 5000);
    const snapshotDataInterval = setInterval(() => snapshotDataRef.current(), 60000);
    return () => {
      clearInterval(jobsInterval);
      clearInterval(queueInterval);
      clearInterval(snapshotDataInterval);
    };
  }, []);

  useEffect(() => {
    loadDailySnapshots();
    loadSnapshotData();
    loadCompletedJobs();
  }, [targetDateDayData, currentClient]);

  const handleChangeDate = (date) => {
    setIsLineGraphLoading(true);
    setForceToday(false);
    setTargetDateDayData(new Date(date));
  };

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setTargetDateDayData(new Date());
    }
    setForceToday(event.target.checked);
  };

  const loadCompletedJobs = async () => {
    const endDate = new Date();
    for (const range of jobRanges) {
      const startDate = new Date();
      startDate.setSeconds(startDate.getSeconds() - range);
      const jobs = await ApiClient.getJobs(startDate, endDate, 'Succeeded');
      setCompletedJobs((prevCompletedJobs) => ({
        ...prevCompletedJobs,
        [range]: jobs,
      }));
    }
  };

  // process/format snapshot data to be usable by the line graph component
  const processLastDayData = (snapshotData) => {
    // get only the snapshot data we need for the graph
    const abbreviatedData = snapshotData.map((snapshot) => {
      return {client: snapshot.client, collected: snapshot.collection_date, uploaded: snapshot.created};
    });
    // initialize a map to keep track of number of snapshots collected/uploaded each hour
    const hourMap = new Map();
    for (let i = 0; i < 24; i++) {
      hourMap.set(i, {SnapshotsCollected: 0, SnapshotsUploaded: 0});
    }

    // track number of snapshots in data vs total minutes passed between collection/uploading
    const numOfSnapshots = abbreviatedData.length;
    let totalMinutesPassed = 0;

    // grab collection/uploading time from snapshot data
    abbreviatedData.forEach((snapshot) => {
      const collectedDate = new Date(snapshot.collected);
      const uploadedDate = new Date(snapshot.uploaded);
      const minutesPassed = (uploadedDate - collectedDate) / 60000;
      totalMinutesPassed += minutesPassed;
      const collectedHour = collectedDate.getHours();
      const uploadedHour = uploadedDate.getHours();
      // update snapshot counts in the map
      const countCollectedData = hourMap.get(collectedHour);
      hourMap
          .set(collectedHour, {...countCollectedData, SnapshotsCollected: countCollectedData.SnapshotsCollected + 1});
      const countUploadedData = hourMap.get(uploadedHour);
      hourMap.set(uploadedHour, {...countUploadedData, SnapshotsUploaded: countUploadedData.SnapshotsUploaded + 1});
    });
    // calculate and store the average upload time
    setAverageUploadTime(Math.ceil(totalMinutesPassed / numOfSnapshots || 1));
    // create an array from the counts map
    const chartData = Array.from(hourMap, ([hour, counts]) => ({
      // hours should follow the '23:00' format
      Hour: `${hour < 10 ? '0' : ''}${hour}:00`,
      ...counts,
    }));
    // sort the array from earlier to later in hours
    chartData.sort((a, b) => a.Hour.localeCompare(b.Hour));
    return chartData;
  };

  // load all snapshots taken during the selected day
  // snapshots loaded are for the currently selected client (or for all clients if a client is not selected)
  const loadDailySnapshots = async () => {
    const startDate = new Date(targetDateDayData.getTime());
    const endDate = new Date(targetDateDayData.getTime());
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    const snapshots = await ApiClient.findSnapshotsByClient(startDate, endDate, null, null, false, currentClient);
    const processedData = processLastDayData(snapshots);
    setLastDayData(processedData);
    setIsLineGraphLoading(false);
  };

  const loadJobsInQueue = async () => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setHours(startDate.getHours() - 24 * 5);
    const jobsInQueue = await ApiClient.getJobs(startDate, endDate, 'Queued');
    setJobsInQueue(jobsInQueue);
  };

  const updateToCurrentMoment = () => {
    if (forceToday) {
      setTargetDateDayData(new Date());
    }
  };

  const loadSnapshotData = async () => {
    const clients = Object.values(clientConfig.names);
    const dayData = await loadData(clients, 1);
    if (!'maxDomain' in dayData) {
      dayData['maxDomain'] = 1000;
    }
    const weekData = await loadData(clients, 7);
    const result = {
      'Selected Day': dayData,
      'Week': weekData,
    };
    setData(result);
  };

  const getCountAllLabels = (entry) => {
    return entry.analyzed + entry.failed + entry.created + entry.discarded;
  };

  const getSimpleStats = (data) => {
    return {
      'day_count': data['Selected Day'].reduce((a, b) => a + getCountAllLabels(b), 0),
      'week_count': data['Week'].reduce((a, b) => a + getCountAllLabels(b), 0),
    };
  };

  const loadData = async (clients, days) => {
    try {
      const inputDate = new Date(targetDateDayData.getTime());
      const counts = await Promise.all(clients.map((client) => getCounts(client, days, inputDate)));
      const result = {};
      clients.forEach((client, index) => {
        for (const item of counts[index]) {
          if (!(item.client in result)) {
            result[item.client] = getAllLabelsDefault();
          }
          result[item.client][item.status] += item.count;
        }
      });
      const flatten = [];
      for (const [client, resultPerClient] of Object.entries(result)) {
        resultPerClient.client = client;
        flatten.push(resultPerClient);
      }
      // get the max domain for the bar chart
      if (days === 1) {
        const maxDomain = Math.max(...counts.map((count) => count.reduce((a, b) => a + getCountAllLabels(b), 0)));
        flatten['maxDomain'] = maxDomain;
      }
      return flatten;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getAllLabelsDefault = () => {
    const result = {};
    allLabels.map((label) => {
      result[label] = 0;
    });
    return result;
  };

  const getCounts = async (client, range = 1, targetDate = null) => {
    if (targetDate === null) {
      targetDate = new Date();
    }
    targetDate.setHours(23, 59, 59, 999);
    const startingDate = new Date();

    startingDate.setHours(0, 0, 0, 0); // this makes the range already 1 day
    range = range - 1;
    startingDate.setDate(targetDate.getDate() - range);
    // handle the case where the starting date is in the previous month
    if (startingDate.getDate() > targetDate.getDate()) {
      startingDate.setMonth(targetDate.getMonth() - 1);
    } else {
      startingDate.setMonth(targetDate.getMonth());
    }
    // handle the case where the starting date is in the previous year
    if (startingDate.getMonth() > targetDate.getMonth()) {
      startingDate.setFullYear(targetDate.getFullYear() - 1);
    } else {
      startingDate.setFullYear(targetDate.getFullYear());
    }
    return await ApiClient.findCounts(client, startingDate, targetDate, 1);
  };

  const getColorByLabel = (label) => {
    if (label === 'failed') {
      return theme.palette.red.main;
    } else if (label === 'analyzed') {
      return theme.palette.green.main;
    } else if (label === 'discarded') {
      return theme.palette.purple.main;
    } else if (label === 'deleted') {
      return theme.palette.gray.main;
    } else if (label === 'incomplete') {
      return theme.palette.orange.main;
    } else {
      return theme.palette.blue.main;
    }
  };

  const getShortClientName = (data) => {
    if (data.client.length > 30) {
      return data.client.substring(0, 30) + '...';
    }
    return data.client;
  };

  // calculate the color of the text for the client name in the bar chart
  // color is defined according to instances of 'analyzed', 'failed', 'discarded', etc.
  const calculateTextColor = (data, clientName) => {
    const clientData = data.find((obj) => obj.client === clientName);
    const analyzedValue = clientData.analyzed;
    const createdValue = clientData.created;
    const deletedValue = clientData.deleted;
    const discardedValue = clientData.discarded;
    const failedValue = clientData.failed;
    const incompleteValue = clientData.incomplete;
    if (analyzedValue > 0 && createdValue === 0 && deletedValue === 0 && discardedValue === 0 && failedValue === 0) {
      return getColorByLabel('analyzed');
    }
    if (failedValue > 0 && failedValue > discardedValue) {
      return getColorByLabel('failed');
    }
    if (incompleteValue > 0) {
      return getColorByLabel('incomplete');
    }
    if (discardedValue > 0) {
      return getColorByLabel('discarded');
    }
    return 'grey';
  };

  // a custom tick component to display the client name in the bar chart
  // the color of the text is calculated based on the client's snapshot data
  const CustomTick = ({payload, x, y, data}) => {
    const textColor = calculateTextColor(data, payload.value);
    return (
      <Text
        x={x}
        y={y}
        dy={4}
        textAnchor="end"
        fill={textColor}
      >
        {payload.value}
      </Text>
    );
  };

  const getBarChart = (data) => {
    let domain = [];
    if (data?.maxDomain) {
      domain = [0, data.maxDomain];
    }
    return <BarChart width={850} height={400} data={data} layout="vertical"
      margin={{left: 185}}>
      <CartesianGrid strokeDasharray="3 3" horizontal={false} />
      <XAxis type="number" domain={domain} allowDataOverflow={true} />
      <YAxis dataKey={(payload) => getShortClientName(payload)} type="category"
        tick={<CustomTick data={data}/>} interval={0}/>
      <Tooltip />
      <Legend />
      {allLabels.map((oneLabel) =>
        <Bar dataKey={oneLabel} key={oneLabel} stackId="client" fill={getColorByLabel(oneLabel)} />,
      )}
    </BarChart>;
  };

  // generate a line chart to visualize daily upload speed performance (snapshots collected vs. snapshots uploaded)
  const getLineChart = (data) => {
    return <LineChart width={850} height={400} data={data} >
      <Line type="monotone" dataKey='SnapshotsCollected' stroke={theme.palette.red.main} strokeWidth={6} />
      <Line type="monotone" dataKey='SnapshotsUploaded' stroke={theme.palette.green.main} strokeWidth={4} />
      <CartesianGrid stroke='#ccc' strokeDasharray="3 3" />
      <XAxis dataKey="Hour" />
      <YAxis>
        <Label value="Number of Snapshots" angle={-90} position='insideLeft' style={{textAnchor: 'middle'}} />
      </YAxis>
      <Tooltip />
      <Legend />
    </LineChart>;
  };

  const areCompletedJobsBothLoaded = () => {
    if (completedJobs === null) {
      return false;
    }
    return Object.keys(completedJobs).length === 2;
  };

  const getAllCharts = (data) => {
    const result = [];
    const simpleStats = getSimpleStats(data);
    result.push(
        <Grid item md={6} lg={3} key={'General snapshot stats'}>
          <Card
            style={{
              margin: theme.spacing(1),
            }}>
            <CardContent>
              <h2>General Snapshot stats</h2>
              <DatePickerComponent
                id="date"
                label="Date"
                startingDate={null}
                changeDate={handleChangeDate}
              />
              <div>
                <Checkbox
                  checked={forceToday}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
                <span>Today</span>
              </div>
              <p>
              Day of interest: {simpleStats.day_count}<br />
              Last week: {simpleStats.week_count}<br />
              Average day: {Math.round(simpleStats.week_count / 7)} <br />
              In the queue: {jobsInQueue?.length} <br />
              Average upload time: {averageUploadTime} minutes
              </p>
            </CardContent>
          </Card>
        </Grid>);
    result.push(
        <Grid item md={6} lg={3} key={'Snapshot speed'}>
          <Card style={{margin: theme.spacing(1), display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <CardContent>
              {areCompletedJobsBothLoaded() ?
              <SpeedChart
                inputValues={completedJobs}
                chartTile={'Snapshots analyzed per minute'} /> : ''}
            </CardContent>
          </Card>
        </Grid>,
    );
    for (const [title, chartData] of Object.entries(data)) {
      result.push(
          <Grid item md={12} lg={6} key={title}>
            <Card
              style={{
                margin: theme.spacing(1),
              }}>
              <CardContent>
                <h2 style={{'marginTop': 0, 'marginBottom': 0}}>
                  {title} {title === 'Selected Day' && '(' + targetDateDayData?.toDateString() + ')'}</h2>
                {getBarChart(chartData)}
              </CardContent>
            </Card>
          </Grid>,
      );
    }
    result.push(
        <Grid item md={12} lg={6} key={'Upload Speed'}>
          <DailyLineChart targetDateDayData={targetDateDayData} currentClient={currentClient} lastDayData={lastDayData}
            getLineChart={getLineChart} isLineGraphLoading={isLineGraphLoading}/>
        </Grid>,
    );
    return result;
  };


  return (
    <Grid container>{data ? getAllCharts(data) : ''}</Grid>
  );
}
export default DashboardPage;
