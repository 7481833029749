import React, {useState, useEffect} from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {useTranslation} from 'react-i18next';
import RedirectDialog from './RedirectDialog';
import {ApiClient} from '../../Utils/ApiClient';
import {useContext} from 'react';
import {CredentialsContext} from '../../contexts/credentialsContext';
const useStyles = makeStyles((theme) => ({
  container: {
    background: '#ffffff',
    zIndex: 2,
    padding: theme.spacing(5),
  },
  logOutButton: {
    float: 'right',
  },
}));

function AccountPage() {
  const {t} = useTranslation();
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const {logout} = useContext(CredentialsContext);
  useEffect(() => {
    ApiClient.getLoggedInUser().then((result) => {
      if (result?.data?.username) {
        setUser(result.data);
      }
    });
  }, []);

  function isStrongPassword(password) {
    const expression = new RegExp(
        '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,}))|(?=.{20,})',
    );
    return expression.test(password);
  }

  function changePassword() {
    // TODO enforce minimum length constraint after implementing it on the server
    const isPasswordInvalid =
      !password || password === '' || password.length > 200;
    const isPasswordWeak = !isStrongPassword(password);
    if (isPasswordInvalid || isPasswordWeak) {
      setInvalidPassword(true);
      return;
    }
    setInvalidPassword(false);

    const newPassword = password;
    ApiClient.changePassword(newPassword).then((success) => {
      if (success) {
        setOpenDialog(true);
      } else {
        setInvalidPassword(true);
      }
    });
  }

  function getGeneralInfo() {
    return (
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            General account information
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Username: {user.username} <br />
            {user.clients ? 'Clients: ' + user.clients : ''}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Container maxWidth="sm" className={classes.container}>
      <h1>{t('Account Page')}</h1>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          {user ? getGeneralInfo() : null}
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            error={invalidPassword}
            label={t('New Password')}
            type="password"
            id="password"
            helperText={
              invalidPassword ? (
                <span>
                  {t('The password needs to be strong')}: <br />
                  - {t('Contain at least 1 lowercase character')}<br />
                  - {t('Contain at least 1 uppercase character')}<br />
                  - {t('Contain at least 1 special character')}<br />
                  - {t('Has a minimal character length of 8')}<br />
                  {t('Or')}<br />
                  - {t('Has a minimal character length of 20')}
                </span>
              ) : (
                ''
              )
            }
          />
          <Button variant="contained" color="primary" onClick={changePassword}>
            {t('Change Password')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.logOutButton}
            color="default"
            onClick={logout}
          >
            {t('Log Out')}
          </Button>
          <RedirectDialog
            open={openDialog}
            handleClose={() => logout()}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default AccountPage;
