import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import {ApiClient} from '../../Utils/ApiClient';
import {Card, CardContent, CardActions, Button} from '@material-ui/core';
import {DatePickerComponent} from '../../Utils/DatePickers';
import {CenterWrapper, TopText} from '../StyledComponents/BasicStyles';
import {UserContext} from '../../contexts/userContext';
import {CenteredCardContent} from '../StyledComponents/BasicStyles';
import {dateToRange, getDatePlusInterval} from '../../Utils/DateUtils';
import {useTheme} from '@mui/material/styles';
import {Grid} from '@mui/material';
import {useContext} from 'react';
import {useHistory} from 'react-router-dom';

function SystemLogsPage() {
  const theme = useTheme();
  const {currentClient} = useContext(UserContext);

  const [selectedDate, setSelectedDate] = useState(null);
  const [logEntries, setLogEntries] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (!currentClient || !selectedDate) {
      return;
    }
    const dateRange = dateToRange(selectedDate, false);
    ApiClient.getSystemLogs(dateRange[0], dateRange[1], currentClient).then((entries) => {
      setLogEntries(entries);
    });
  }, [currentClient, selectedDate]);

  useEffect(() => {
    setSelectedDate(getDatePlusInterval());
  }, []);

  useEffect(() => {
    history.push((`/${currentClient || 'admin'}/system-logs`));
  }, [currentClient]);

  const handleChangeDate = (date) => {
    setSelectedDate(new Date(date));
  };

  const downloadLog = async (logEntry) => {
    const url = await ApiClient.getSystemLogDownloadUrl(logEntry._id);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'image.jpg';
    link.click();
  };

  return (<>
    <CenterWrapper>
      <Card>
        <CardContent>
          <form noValidate>
            <DatePickerComponent
              id="date"
              label="Date"
              changeDate={handleChangeDate}
              startingDate={selectedDate}
            />
          </form>
        </CardContent>
      </Card>

      <Card style={{'marginTop': theme.spacing(2)}}>
        <CenteredCardContent>
          <TopText>Download your logs:</TopText>
        </CenteredCardContent>
      </Card>
      <Grid container justifyContent="center" style={{marginTop: theme.spacing(2)}}>
        {logEntries.map((logEntry) =>
          <Grid item key={logEntry._id}>
            <Card
              style={{
                marginBottom: theme.spacing(1),
                marginLeft: theme.spacing(1),
                position: 'relative',
                textAlign: 'left',
                width: 400,
              }}>
              <CardContent>
                <h2>{logEntry.name}</h2>
                <Typography>{logEntry.createdAt}</Typography>
                <Typography>{logEntry.message}</Typography>
                <Typography>{logEntry.client}</Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="primary" onClick={() => downloadLog(logEntry)}>Download</Button>
              </CardActions>
            </Card>
          </Grid>,
        )}
      </Grid>
    </CenterWrapper>
  </>);
}

export default SystemLogsPage;
