import React from 'react';
import randomColor from 'randomcolor';
import './batchStyles.css';
const TrayBatch = ({trayBatches, trayStats}) => {
  if (!trayBatches || trayBatches.length === 0) {
    return null;
  };
  const cellCount = trayStats.cell_count;
  const rows = trayStats.rows;
  const columns = trayStats.columns;
  const cellStyle = {
    width: `${(100 / columns)}%`,
    height: `${(100 / rows)}%`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const trayStyle = {
    width: `98%`,
    height: `97%`,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '10px',
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
  };
  const circleStyle = {
    width: '60%',
    paddingTop: '60%',
    borderRadius: '50%',
    backgroundColor: 'brown',
    position: 'relative',
    margin: 'auto',
  };
  return (
    <div style={{width: 600,
      height: 300, backgroundColor: 'white', display: 'flex',
      alignItems: 'center', justifyContent: 'center', borderRadius: 5, margin: 20}}>
      <div style={trayStyle}>
        {Array.from({length: cellCount}).map((_, index) => (
          <div key={index} style={cellStyle}>
            <div style={circleStyle}></div>
          </div>
        ))}
        <div style={{display: 'flex', flexDirection: 'row', width: '100%',
          height: '100%', position: 'absolute', left: 0, top: '0'}}>
          {
            trayBatches.map((batch, index) => {
              return (
                <div key={index} className='tray-tooltip'
                  style={{backgroundColor: randomColor(), flex: 1, opacity: 0.5, borderRadius: 5}}>
                  <span className='tooltiptext'>
                Batch ID: {batch.batchId}
                    <br/>
                Planting Date: {batch.batchData.planting_date}
                    <br/>
                Tray Plant Amount: {batch.batchData.tray_plant_amount}
                    <br/>
                  </span>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>

  );
};

export default TrayBatch;
