export function checkVersionSafely(data) {
  if (data.data == null) {
    return false;
  }
  if (data.data.hasOwnProperty('analysis_data')) {
    if (data.data.analysis_data.hasOwnProperty('version') &&
        data.data.analysis_data.hasOwnProperty('version_date')) {
      return true;
    }
  } else {
    return false;
  }
}
