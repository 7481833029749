import React, {useState, useEffect} from 'react';
import {createUseStyles} from 'react-jss';
import Typography from '@material-ui/core/Typography';
import {ApiClient} from '../../Utils/ApiClient';
import {Select, MenuItem, InputLabel, TextField, Card, FormControl} from '@material-ui/core';
import {DatePickerComponent} from '../../Utils/DatePickers';
import clientConfig from '../../Config/clients.json';
import Button from '@material-ui/core/Button';
import {SplitCard, UserInputs, TopText, SubmitButton, CenteredCardContent} from '../StyledComponents/BasicStyles';
import InBedPosition from '../../Utils/InBedPosition';
import JobInput from './JobInput';
import {getDatePlusInterval} from '../../Utils/DateUtils';
import {useContext} from 'react';
import {UserContext} from '../../contexts/userContext';

const useStyles = createUseStyles(
    {
      onlyMarginBottom: {
        marginBottom: 10,
      },
    },
);

function RerunPage() {
  const classes = useStyles();
  const {getUserConfig, currentClient, setCurrentClient} = useContext(UserContext);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(3);
  const [highlightDates, setHighlightDates] = useState(new Set());
  const [selectedDate, setSelectedDate] = useState(getDatePlusInterval().toISOString());
  const [secondDate, setSecondDate] = useState('');
  const [currentJobs, setCurrentJobs] = useState([]);
  const [jobErrorMessage, setJobErrorMessage] = useState('');
  const [jobIsSuccessful, setJobIsSuccessful] = useState(false);
  const [jobName, setJobName] = useState('');
  const [customProject, setCustomProject] = useState(null);
  const [jobType, setJobType] = useState('Individual Job');

  const [lane, setLane] = useState('');
  const [subLane, setSubLane] = useState('');
  const [hook, setHook] = useState();
  const [distance, setDistance] = useState();

  const clients = Object.keys(clientConfig.names).map(function(key) {
    return clientConfig.names[key];
  });

  const statuses = [
    'any',
    'created',
    'analyzed',
    'failed',
    'discarded',
    'incomplete',
  ];

  const priorities = [1, 2, 3, 10];

  useEffect(() => {
    updateQueue();

    const interval = setInterval(() => {
      updateQueue();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const daysWithUploads = (client, status) => {
    ApiClient.findDaysWithUploads(client, status).then((days) => {
      setHighlightDates(days);
    });
  };

  const handleChangeClient = (event) => {
    setCurrentClient(event.target.value);

    daysWithUploads(event.target.value, selectedStatus);
  };

  const handleChangeStatus = (event) => {
    setSelectedStatus(event.target.value);

    daysWithUploads(currentClient, event.target.value);
  };

  const updateQueue = () => {
    async function currentJobs() {
      ApiClient.findCurrentJobs().then((jobs) => {
        jobs = jobs.reduce(function(r, a) {
          r[a.name] = r[a.name] || [];
          r[a.name].push(a);
          return r;
        }, Object.create(null));
        if (typeof jobs !== 'undefined') {
          setCurrentJobs(jobs);
        } else {
          setCurrentJobs([]);
        }
      });
    }

    currentJobs();
  };

  const handleChangeDate = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(0, 0, 0, 0);
    setSelectedDate(dateObject.toISOString());
  };

  const handleChangeSecondDate = (e) => {
    const dateObject = new Date(e);
    dateObject.setHours(23, 59, 59, 0);
    setSecondDate(dateObject.toISOString());
  };

  const handleChangeName = (e) => {
    setJobName(e.target.value);
  };

  const getCurrentJobInput = () => {
    const inBedPosition = new InBedPosition(lane, subLane, hook, distance);
    return new JobInput(currentClient, selectedDate, selectedStatus, jobName,
        selectedPriority, inBedPosition, secondDate, customProject);
  };

  const handleSubmitJob = () => {
    setJobIsSuccessful(false);
    ApiClient.uploadJob(getCurrentJobInput()).then((errorMessage) => {
      if (errorMessage) {
        setJobErrorMessage(errorMessage);
      } else {
        setJobErrorMessage('');
        setJobIsSuccessful(true);
      }
    });
    updateQueue();
  };

  const handleCancelJob = (name) => {
    async function cancelJob() {
      ApiClient.cancelJob(name).then((status) => {
        console.log(status);
      });
    }

    cancelJob();
  };

  function showJobInfo(job) {
    let numberQueued = 0;
    for (let i = 0; i < job.length; i++) {
      if (job[i].status === 'Queued') {
        numberQueued += 1;
      }
    }
    if (numberQueued === 0) {
      return (
        <Typography>Job finished</Typography>
      );
    }
    return (
      <>
        <Typography>{numberQueued} snapshots in queue for this job</Typography>
        <Button size="small" color="primary" onClick={() => handleCancelJob(job[0].name)}>Cancel</Button>
      </>
    );
  }

  function isLaneClient(client) {
    if (client === '' || client === undefined) {
      return false;
    }

    const clientData = getUserConfig(true);
    const hasLane = clientData?.has_lane;

    return hasLane === true;
  }

  function changeJobType() {
    if (jobType === 'Individual Job') {
      setJobType('Date Range Job');
    } else if (jobType === 'Date Range Job') {
      setJobType('Individual Job');
    }
  }

  return (
    <>
      <UserInputs>
        <TopText variant="h6">Enter parameters for rerun job here</TopText>
        <Card>
          <CenteredCardContent maxWidth={400}>
            <TextField fullWidth className={classes.onlyMarginBottom}
              label="Name" type="text" value={jobName} onChange={handleChangeName} />
            <FormControl fullWidth className={classes.onlyMarginBottom}>
              <InputLabel id="client-label">Client</InputLabel>
              <Select
                labelId="client-picker"
                id="client-picker-select"
                value={currentClient}
                label="Client"
                onChange={handleChangeClient}>
                {clients.map((client) => <MenuItem key={client + '-client-picker'}
                  id={client} value={client}>{client}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-picker"
                id="status-picker-select"
                value={selectedStatus}
                label="Status"
                onChange={handleChangeStatus}>
                {statuses.map((status) => <MenuItem key={status + '-status-picker'}
                  id={status} value={status}>{status}</MenuItem>)}
              </Select>
            </FormControl>
            <TopText>The higest number gets scheduled first. Uploaded snapshots get priority 5.</TopText>
            <FormControl fullWidth className={classes.onlyMarginBottom}>
              <InputLabel id="priority-label">Priority</InputLabel>
              <Select
                labelId="priority-label"
                id="priority-picker-select"
                value={selectedPriority}
                label="Priority"
                onChange={(event) => setSelectedPriority(event.target.value)}>
                {priorities.map((priority) => <MenuItem key={priority + '-priority-picker'}
                  id={priority} value={priority}>{priority}</MenuItem>)}
              </Select>
            </FormControl>
            <FormControl fullWidth className={classes.onlyMarginBottom}>
              <TextField label="Custom project id" type="text" value={customProject}
                onChange={(e) => setCustomProject(e.target.value)}
                inputProps={{min: 0, style: {textAlign: 'center'}}} />
            </FormControl>
            <TopText className={classes.onlyMarginBottom}>Select Date(s):</TopText>
            <FormControl fullWidth className={classes.onlyMarginBottom}>
              <DatePickerComponent
                id="date"
                label="Date"
                changeDate={handleChangeDate}
                highlightDates={highlightDates}
              />
            </FormControl>
            <Button className={classes.onlyMarginBottom} size="small" color="primary" onClick={changeJobType}>
              <Typography>{jobType}</Typography>
            </Button>
            {jobType === 'Date Range Job' ?
                            <FormControl fullWidth className={classes.onlyMarginBottom}>
                              <DatePickerComponent
                                id="date"
                                label="Date"
                                changeDate={handleChangeSecondDate}
                                highlightDates={highlightDates}
                              />
                            </FormControl> : <></>}
            {isLaneClient(currentClient) ?
                            <FormControl fullWidth>
                              <TextField label="Lane" type="text" value={lane}
                                onChange={(e) => setLane(e.target.value)}
                                inputProps={{min: 0, style: {textAlign: 'center'}}} />
                              <TextField label="Sub-lane" type="text" value={subLane}
                                onChange={(e) => setSubLane(e.target.value)}
                                inputProps={{min: 0, style: {textAlign: 'center'}}} />
                              <TextField label="Hook" type="number" value={hook}
                                onChange={(e) => setHook(e.target.value)}
                                inputProps={{min: 0, style: {textAlign: 'center'}}} />
                              <TextField label="Distance" type="number" value={distance}
                                onChange={(e) => setDistance(e.target.value)}
                                inputProps={{min: 0, style: {textAlign: 'center'}}} />
                            </FormControl> : <></>}
            <FormControl fullWidth>
              <SubmitButton onClick={handleSubmitJob}>Submit job</SubmitButton>
            </FormControl>
            {jobErrorMessage ? <Typography>No matching snapshots</Typography> : ''}
            {jobIsSuccessful ? <Typography>Successfully submitted job</Typography> : <></>}
          </CenteredCardContent>
        </Card >
      </UserInputs >
      <SplitCard>
        <Typography variant="h6">Current Queue</Typography>
        {Object.entries(currentJobs).map(([key, value]) => {
          if (key !== 'undefined') {
            return (<div key={key + '-current-job'}>
              <p>{key}: {showJobInfo(value)}</p>
            </div>
            );
          }
        })}
      </SplitCard>
    </>
  );
}

export default RerunPage;
